const exportData = {
    base_url: 'https://www.pronoteweb.net',
    you_url: 'https://www.youtube.com/watch?v=',
    upload_url: '/upload/template/',
    upload_url_member: '/upload/member/',
    pro_img_path: '/upload/progress/',
    lasy: 500,
    finalLasy: 20000,
    timeList: [
        {sc_stime: '08:00', sc_etime: '08:50'},
        {sc_stime: '09:00', sc_etime: '09:50'},
        {sc_stime: '10:00', sc_etime: '10:50'},
        {sc_stime: '11:00', sc_etime: '11:50'},
        {sc_stime: '12:00', sc_etime: '12:50'},
        {sc_stime: '13:00', sc_etime: '13:50'},
        {sc_stime: '14:00', sc_etime: '14:50'},
        {sc_stime: '15:00', sc_etime: '15:50'},
        {sc_stime: '16:00', sc_etime: '16:50'},
        {sc_stime: '17:00', sc_etime: '17:50'},
        {sc_stime: '18:00', sc_etime: '18:50'},
        {sc_stime: '19:00', sc_etime: '19:50'},
        {sc_stime: '20:00', sc_etime: '20:50'},
        {sc_stime: '21:00', sc_etime: '21:50'},
        {sc_stime: '22:00', sc_etime: '22:50'},
    ],
    dayText: ['일', '월', '화', '수', '목', '금', '토'],
    dayText02: ['월', '화', '수', '목', '금', '토', '일'],
    dayNum: ['6', '0', '1', '2', '3', '4', '5'],
    // 1: 물리치료사, 2: 필라테스, 3: 트레이너
    specialType: ['', '물리치료사', '필라테스', '트레이너'], 
    // 0=>"월", 1=>"화", 2=>"수", 3=>"목", 4=>"금", 5=>"토", 6=>"일"
    kakaoRestApiKey: '138addd24536487d362f7e1a8ed546ac',
    naverApiKey: 'v_g7LCzRWaQ3e72cYk1q',
    googleApiKey: '680331214031-76otl0mpf79la0vmca01kgbb9jmf3ra7.apps.googleusercontent.com',

    authorization_chk_id: `/authorization.php?trace=chk_id`, // 회원가입시 이메일 체크
    authorization_chk_id_sns: `/authorization.php?trace=chk_id_sns`, // sns회원가입시 체크
    authorization_sign_up: `/authorization.php?trace=sign_up`, // 회원가입
    authorization_find_pwd: `/authorization.php?trace=find_pwd`, // 비밀번호 찾기
    authorization_change_pwd: `/authorization.php?trace=change_pwd`, // 비밀번호 변경
    authorization_login: `/authorization.php?trace=login`,
    authorization_login_sns: `/authorization.php?trace=login_sns`, // sns 로그인
    authorization_apple_sub: `/authorization.php?trace=apple_sub`,

    sc_matching_list: `/schedule.php?trace=matching_list&token=`, // 고객매칭 리스트
    sc_matching_accept: `/schedule.php?trace=matching_accept&token=`,
    sc_matching_refuse: `/schedule.php?trace=matching_refuse&token=`,
    sc_schedule_time_set: `/schedule.php?trace=schedule_time_set&token=`, // 스케줄 기본 세팅 시간
    sc_schedule_month: `/schedule.php?trace=schedule_month&token=`, // 스케줄 리스트(월)
    sc_schedule_day: `/schedule.php?trace=schedule_day&token=`, // 스케줄 리스트(일)
    sc_matching_invite: `/schedule.php?trace=matching_invite&token=`, // 고객초대
    sc_matching_del: `/schedule.php?trace=matching_del&token=`, // 초대삭제, 고객초대삭제
    sc_matching_r_invite: `/schedule.php?trace=matching_r_invite&token=`, // 고객초대 재매칭

    tem_register_view: `/template.php?trace=register_view&token=`, // 기록 명칭 뷰, 경과기록, 평가기록 보기
    tem_template_view: `/template.php?trace=template_view&token=`, // 기록 템플릿 뷰, 템플릿 보기 눌렀을 경우 호출
    tem_register_list: `/template.php?trace=register_list&token=`, // 기록 명칭 리스트
    tem_register_template_mem_save: `/template.php?trace=register_template_mem_save&token=`, // 기록 템플릿 사용하기(회원), 템플릿에서 저장하기 눌렀을 경우 사용, 경과랑 평가 둘 다 같이 사용
    tem_template_answer_del: `/template.php?trace=template_answer_del&token=`, // 기록 답변 삭제(전체), 평가랑 경과 둘 다 사용
    tem_register_template_mem_del: `/template.php?trace=register_template_mem_del&token=`, // 기록 템플릿 삭제
    tem_template_answer_now_p_list: `/template.php?trace=template_answer_now_p_list&token=`, // 경과 기록 진행 상황(회원), 등록돤 고객의 템플릿 주차 정보 가져오기
    tem_template_answer_p_view: `/template.php?trace=template_answer_p_view&token=`, // 경과기록 답변 뷰,
    tem_template_answer_p_save: `/template.php?trace=template_answer_p_save&token=`, // 경과기록 답변 저장/수정
    tem_template_answer_e_save: `/template.php?trace=template_answer_e_save&token=`, // 평기가록 답변 저장/수정
    tem_template_answer_e_view: `/template.php?trace=template_answer_e_view&token=`, // 평가기록 답변 뷰
    tem_statistics_graph: `/template.php?trace=statistics_graph&token=`, // 평가기록 통계 그래프
    tem_register_template_mem_list: `/template.php?trace=register_template_mem_list&token=`, // 기록 템플릿 리스트
    tem_template_answer_show: `/template.php?trace=template_answer_show&token=`, // 평가기록 통계 고객노출 설정
    

    // 회원 api
    sc_my_schedule_save: `/schedule.php?trace=my_schedule_save&token=`, // 내 스케줄 등록
    sc_my_schedule_edit: `/schedule.php?trace=my_schedule_edit&token=`, // 내 스케줄 수정
    sc_my_schedule_del: `/schedule.php?trace=my_schedule_del&token=`, // 내 스케줄 수정
    sc_schedule_view: `/schedule.php?trace=schedule_view&token=`, // 스케줄 리스트
    sc_my_schedule_view: `/schedule.php?trace=my_schedule_view&token=`, // 내 스케줄 리스트
    sc_matching_member_info: `/schedule.php?trace=matching_member_info&token=`, // 매칭고객기본정보

    // 마이 api
    my_member_basic: `/mypage.php?trace=member_basic&token=`, // 회원기본정보
    my_member_detail: `/mypage.php?trace=member_detail&token=`, // 프로필정보뷰
    my_member_detail_edit: `/mypage.php?trace=member_detail_edit&token=`, // 프로필정보수정
    my_member_edit: `/mypage.php?trace=member_edit&token=`, // 계정정보수정
    my_training_list: `/mypage.php?trace=training_list&token=`, // 홈트레이닝리스트
    my_health_view: `/mypage.php?trace=health_view&token=`, // 건강정보 뷰
    my_health_inst: `/mypage.php?trace=health_inst&token=`, // 건강정보 등록
    my_health_edit: `/mypage.php?trace=health_edit&token=`, // 건강정보 수정
    my_health_dele: `/mypage.php?trace=health_dele&token=`, // 건강정보 삭제
}

export default exportData;