import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Calendar from "react-calendar";
import moment from "moment";

import FooterBtn from "./FooterBtn";

export default function FooterInput(props) {
    const {
        label,
        value = "",
        setValue,
        placeholder,
        maxlength,
        type,
        iref,
        disable,
        onBlur,
        bgColor,
        viewPass,
        onKeyPress,
        setFunc,
        setClose,
        placeHolderTitle,
        regDateList,
    } = props;

    let popupRef = useRef();

    const [dateVal, setDateVal] = useState('');
    const [regDate, setRegDate] = useState('');

    // 회색 영역 클릭시 종료되게끔
    useEffect(()=>{
        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    const handleClickOutside = (event) => {
        if(event.target.id === 'container_part') setClose(false);
    }

    console.log(regDateList);

    return (
        <>
            <div className="footer_input_container" id="container_part">
                <div className="save_cal_box">
                    
                    <Calendar
                        onChange={setDateVal} // useState로 포커스 변경 시 현재 날짜 받아오기
                        formatDay={(locale, date) => moment(date).format("D")} // 날'일' 제외하고 숫자만 보이도록 설정
                        formatMonthYear={(locale, date) => moment(date).format("YYYY.M")}
                        value={dateVal}
                        calendarType="hebrew"
                        minDetail="month"
                        maxDetail="month"
                        nextLabel={
                            <img src={require("../assets/images/icons/next.svg").default} />
                        }
                        prevLabel={
                            <img src={require("../assets/images/icons/prev.svg").default} />
                        }
                        next2Label={null}
                        prev2Label={null}
                        navigationLabel={null}
                        className="mx-auto w-full text-sm border-b"
                        onClickDay={(day) => {
                            // console.log(day);
                            setRegDate(moment(day).format('YYYY.MM.DD'));
                        }}
                        tileContent={({ date, view }) => {
                            // console.log(date);

                            return (
                                <>  
                                    <div className="dot"></div>
                                    <div className="flex justify-center items-center absoluteDiv">
                                        {regDateList?.indexOf(moment(date).format('YYYY-MM-DD')) >= 0 &&
                                            <p style={{ fontSize: 10 }}
                                            >{moment(regDateList[regDateList?.indexOf(moment(date).format('YYYY-MM-DD'))]).format('MM.DD')} 기록</p>
                                        }
                                    </div>
                                </>
                            );
                        }}
                    />
                </div>
                <div className="footer_input_date">
                    {regDateList?.indexOf(moment(regDate).format('YYYY-MM-DD')) >= 0 ?
                        <p>{`${regDate} 일을 수정하시겠습니까?`}</p>
                        :
                        <p>{regDate ? `${regDate} 일로 저장하시겠습니까?` : '경과기록 일자를 선택하세요.'}</p>

                    }
                </div>
                <FooterBtn
                    greyChk={!regDate}
                    btnName={'저장하기'}
                    setFunc={() => { 
                        if(!regDate) return;
                        setFunc(regDate);
                    }}
                />
            </div>
        </>
    );
}
