import React, { useEffect, useState, useRef, forwardRef } from "react";
import moment from "moment";
import Resizer from "react-image-file-resizer";

export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 휴대폰번호
export const regEmail = /^[^\sㄱ-ㅎㅏ-ㅣ가-힣]*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일

export const patternNum = /[0-9]/; // 숫자
export const patternEng = /[a-zA-Z]/; // 영문
export const patternSpc = /[\"\'\[\].,;-=~!@#$%^&*()_+|<>?:{}/~`|•√π÷×¶∆£¢€¥^°=\s]/; // 특수문자

export const patternPrice = /[0-9,]/; // 금액
export const patternEngUpper = /[A-Z]/; // 영문 대문자

export const patternSpcId = /[`~!#$%^&*()|+\=?;:'",<>\{\}\[\]\\\/ ]/gim; // 특수문자 아이디용
export const patternSpcPw = /[|+\=?;:'",.<>\{\}\[\]\\\/ ]/gim; // 특수문자 비밀번호용
export const patternSpcEmail = /[`~!#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim; // 특수문자 이메일용
export const patternUrl = /^http[s]?:\/\/([\S]{3,})/i; // URL
export const patternSpcInstar = /[^0-9a-zA-Z._]+/g; // 인스타용 허용글자

export const patternKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크


export const numFormat = (num, cut = 0) => {
    if (num) {
        if (cut) num = Math.floor(num / cut) * cut; // 1000 = 백원단위 절삭
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return 0;
    }
};

export const htmlTagReplace = (str, hide) => {
    if (!str) return "";

    return str
        .replace(/<[^>]*>?/gm, "")
        .replace(/&nbsp;/gi, "")
        .replace(/\s/gi, "");
};

export const objectSum = (arr, key) => {
    return arr
        .map((item) => item[key])
        .reduce((prev, curr) => prev * 1 + curr * 1, 0);
};

export const findJson = (object, val) => {
    let title = "정보없음";

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one.title;
        }
    });
    return title;
};

export const findAgeType = (val) => {
    let title = "10대 이하";
    let today = new Date();
    let birthDate = new Date(val);
    let age = today.getFullYear() - birthDate.getFullYear() + 1;
    let stringAge = age.toString();
    // 0~3 초반 , 4~6 중반 , 그 외 후반
    let firstAge = stringAge[0];
    let lastAge = stringAge[stringAge.length - 1];

    if (lastAge >= 0 && lastAge <= 3) {
        title = firstAge + "0대 초반";
    } else if (lastAge >= 4 && lastAge <= 6) {
        title = firstAge + "0대 중반";
    } else if (lastAge >= 7 && lastAge <= 9) {
        title = firstAge + "0대 후반";
    }

    return title;
};

export const findJsonCl = (object, val) => {
    let title = "";

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one.cl;
        }
    });
    return title;
};

export const findJsonNum = (object, val) => {
    let title = 0;

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one.num;
        }
    });
    return title;
};

export const findArrInclues = (object, val, keyText) => {
    let chk = false;

    object.forEach((one, index) => {
        // console.log(one);
        // console.log(val);
        if (!keyText) {
            if (one * 1 === val * 1) {
                chk = true;
                return;
            }
        } else {
            if (one[keyText] * 1 === val * 1) {
                chk = true;
                return;
            }
        }
    });
    return chk;
};

export const getFileName = (url) => {
    let urls = url.split("/");
    return urls[urls.length - 1];
};

export const goLink = (navigate, route, state) => {
    // console.log(state);
    navigate(route, { state: state });
};

export const clickImg = (imgsrc) => {
    var imageWin = new Image();
    imageWin = window.open("", "", "width=600px, height=600px");
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write(
        "<img src='" + imgsrc + "' border=0 style='width: 100%'>"
    );
    imageWin.document.write("</body><html>");
};

export const clickDown = (url, mime = "", name = "") => {
    if (mime === "base") {
        var link = document.createElement("a");
        link.download = name;
        link.href = url;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    } else {
        window.open(url, "", "");
    }
};

export const allSetValue = (i, e, val, list) => {
    // console.log(i);
    // console.log(val);
    // console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = val;

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: val,
        };
    });

    return newFormValues;
};
export const allSetValueAppend = (i, e, val, list) => {
    // console.log(i);
    // console.log(val);
    // console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = [...newFormValues[i][e.target.name], ...val];

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: [...item[e.target.name], ...val],
        };
    });

    return newFormValues;
};

export const allSetValueMultiAppend = (i, e, ee, val, val2, list) => {
    // console.log(i);
    // console.log(val);
    // console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = [...newFormValues[i][e.target.name], ...val];

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: [...item[e.target.name], ...val],
            [ee.target.name]: [...item[ee.target.name], ...val2],
        };
    });

    return newFormValues;
};

export const allSetValueMulti = (i, e, ee, val, val2, list) => {
    // console.log(i);
    // console.log(val);
    // console.log(e);

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: val,
            [ee.target.name]: val2,
        };
    });

    // let newFormValues = [...caseAll];
    // newFormValues[i][e.target.name] = val;
    return newFormValues;
};

export const allSetDeletePhoto = (i, e, val, list) => {
    // console.log(i);
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = val;

    return newFormValues;
};

export const changeArrayOrder = (list, targetIdx, moveValue) => {
    // 배열값이 없는 경우 나가기

    //    console.log(targetIdx, moveValue);
    if (list.length < 0) return list;

    // 이동할 index 값을 변수에 선언
    const newPosition = targetIdx + moveValue;

    // 이동할 값이 0보다 작거나 최대값을 벗어나는 경우 종료
    if (newPosition < 0 || newPosition >= list.length) return list;

    // 임의의 변수를 하나 만들고 배열 값 저장
    const tempList = JSON.parse(JSON.stringify(list));

    // 옮길 대상을 target 변수에 저장하기
    const target = tempList.splice(targetIdx, 1)[0];

    // 새로운 위치에 옮길 대상을 추가하기
    tempList.splice(newPosition, 0, target);
    return tempList;
};

export const logsView = (logs) => {
    return Object.entries(JSON.parse(logs)).map(([key, value]) => {
        return key + ": " + (value ? value.replaceAll("[@]", ", ") : "") + "\n";
    });
};

export const repayInterest = (price, fee, diffDay) => {
    return price * (fee * 0.01) * ((12 / 365) * diffDay);
};

export const regBirth = (pw) => {
    return pw.length === 8 && patternNum.test(pw);
};

export const calcBirth = (birth) => {
    const today = new Date();
    const birthDate = new Date(
        birth.substring(0, 4),
        birth.substring(4, 6),
        birth.substring(6, 8)
    );

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

// 비밀번호 체크
export const regPassword = (pw) => {
    return (
        pw.length > 7 &&
        pw.length < 13 &&
        patternNum.test(pw) &&
        patternSpc.test(pw) &&
        patternEng.test(pw)
    );
};

// 이름 체크
export const regNameChk = (val) => {
    return (
        val.length > 1 &&
        val.length < 11 &&
        (patternKor.test(val) || patternEng.test(val) || patternEngUpper.test(val))
    );
};

// 휴대폰번호 체크
export const regPhoneChk = (val) => {
    return regPhone.test(val);
};

export const getFullDateFormat = (date, format = 1) => {
    if (!date) return "정보없음";

    const today = new Date();
    const timeValue = new Date(date);

    const betweenTime = Math.floor(
        (today.getTime() - timeValue.getTime()) / 1000 / 60
    );

    if (format === 2) return betweenTime < 60;

    if (betweenTime < 1) return "방금전";
    if (betweenTime < 60) {
        return `${betweenTime}분전`;
    }

    const betweenTimeHour = Math.floor(betweenTime / 60);
    if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
    if (betweenTimeDay < 32) {
        return `${betweenTimeDay}일전`;
    }

    return moment(date).format("YY.MM.DD");
};
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            const { current } = savedCallback;

            if (current !== undefined && current !== null) {
                current();
            }
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => { };
    }, [delay]);
};

export const resizeFile = (file, width = "", height = "") =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "png",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

export const patternNickFunc = (nick) => {
    /**
          1. 한글 최소 2글자 이상 최대 8글자 제한
          2. 영문 최소 2글자 이상 최대 15글자 제한
          3. 한글 + 영문 + 숫자 혼용 최대 15글자 제한
          4. 숫자만 사용 최대 8글자 제한
          5. 자음,모음,특수기호 허용 x
      */

    let regx = /[^0-9a-zA-Z가-힣]+/g; // 모음과 자음은 제외

    if (regx.test(nick) || nick.length < 2 || nick.length > 15) return true;

    let regxKor = /[가-힣]/; // 한글
    let regxEng = /[a-zA-Z]/; // 영어
    let regxNum = /[0-9]/; // 숫자

    let korCheck = regxKor.test(nick);
    let engCheck = regxEng.test(nick);
    let numCheck = regxNum.test(nick);

    // 영어만 작성한경우
    if (!korCheck && engCheck && !numCheck) {
        return nick.length > 15 ? true : false;
    }

    // 나머지
    return nick.length > 8 ? true : false;
};

export const encodeFileToBase64 = (image) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
    });
};

export const hpHypen = (str, hide) => {
    if (!str) return "";

    try {
        if (hide) {
            return str.replace(
                /^(\d{3})-?(\d{1,1})\d{3}-?(\d{1,1})\d{3}$/,
                "$1-$2***-$3***"
            );
        } else {
            return str.replace(
                /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                "$1-$2-$3"
            );
        }
    } catch (e) {
        return "";
    }
};

export const emailMasking = (email) => {
    if (!email) return "";

    try {
        let first = email.split("@")[0];
        let second = email.split("@")[1];
        let dm = second.split(".")[0];

        let len = email.split("@")[0].length - 3;

        let em = email.replace(new RegExp(".(?=.{0," + len + "}@)", "g"), "*");
        em = em.replace(dm, "*".repeat(dm.length));

        return em;
    } catch (e) {
        return "";
    }
};

export const getYoilText = (val) => {
    let yoilArr = ["일", "월", "화", "수", "목", "금", "토"];
    return yoilArr[val];
};
