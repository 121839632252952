import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { loadPaymentWidget, clearPaymentWidget, ANONYMOUS } from '@tosspayments/payment-widget-sdk'

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Popup01 from "../popup/Popup01";

import Header from "../parts/Header";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import { kakaoPostData } from "../../utils/service/APIS";

// import InputSelectChk from "../../components/InputSelectChk";
// import BoardCardFeed from "../../components/BoardCardFeed";
// import BoardCardShadow from "../../components/BoardCardShadow";
// import ListLoading from "../../components/ListLoading";

export default function MemberShip() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const paymentWidgetRef = useRef();

    const [membership, setMemberShip] = useState(false);
    const [payPopup, setPayPopup] = useState(false);

    const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm'
    const customerKey = 'MsxyB8cSDrnaZERrKiV1N'
    
    const payPopFunc = async () => {
        let price = 9900;
        const paymentWidget = await loadPaymentWidget(clientKey, customerKey) // 회원 결제
        paymentWidget.renderPaymentMethods("#payment-widget", price);
        paymentWidgetRef.current = paymentWidget;
    }

    const payFunc = async () => {
        const paymentWidget = paymentWidgetRef.current
        
        try {
            await paymentWidget?.requestPayment({
                orderId: 'nanoid', // 주문번호날날짜시간 + 고객의 회원 번호 넣으면 될듯
                orderName: "구독권테스트결제", // 고객 이름
                customerName: "김토스", // 고객 이름
                customerEmail: "customer123@gmail.com", // 고객 이메일
                successUrl: `${window.location.origin}/membershipsuc`,
                failUrl: `${window.location.origin}/membershipfail`,
            }) 
        } catch (err) {
            console.log(err)
        }
    }
   
    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => { navigate(routes.mypage) }}
                title={"멤버십"}
            />

            {!payPopup ?  
                <div style={{ padding: 20 }}>
                    <p className="membership_title">멤버십 가입하기</p>
                    {!membership ?
                        <div className="membership_card" style={{ height: 120 }}
                            onClick={() => { 
                                setPayPopup(true);
                                payPopFunc();
                            }}
                        >
                            <img src={require("../../assets/images/icons/membership_card.svg").default} />
                            <div className="membership_card_content">
                                <p className="title">구독권</p>
                                <p className="card_content">9,900원<span>(30일마다 자동 결제)</span></p>
                            </div>
                        </div>
                        :
                        <div className="membership_card" style={{ height: 140 }}>
                            <img src={require("../../assets/images/icons/membership_user.svg").default} />
                            <div className="membership_card_content">
                                <p className="title02">현재 회원님은</p>
                                <p className="title02">멤버십 회원 입니다.</p>
                                <p className="card_content"><span>멤버십 가입일: {'2023년 05월 24일'}</span></p>
                            </div>
                        </div>
                    }

                    <div className="member_ship_box">
                        <p className="title">구독 회원 제공 서비스</p>
                        <div className="member_ship_use">
                            <p style={{ marginLeft: 5, marginRight: 5 }}>•</p>
                            <p>운동기록 유료 템플릿</p>
                        </div>
                        <div className="member_ship_use">
                            <p style={{ marginLeft: 5, marginRight: 5 }}>•</p>
                            <p>평가기록 유료 템플릿</p>
                        </div>
                    </div>
                    <div className="member_ship_box">
                        <p className="title">구독 회원 제공 서비스</p>
                        <div className="member_ship_notifi">

                            <div className="member_ship_notifi_box">
                                <p style={{ marginRight: 5, color: '#666' }}>1.</p>
                                <div>
                                    <p style={{ color: '#666' }}>회사와 구매에 관한 계약을 체결한 회원은 아래와 같이 결제에 대한 취소 및 환불을 요구할 수 있습니다.</p>
                                </div>
                            </div>
                            <div className="member_ship_notifi_box">
                                <p style={{ marginRight: 10, color: '#666' }}>-</p>
                                <div>
                                    <p style={{ color: '#666' }}>이용계약의 신청후 회사로부터의 상담이 제공되지 않은 경우, 결제 취소가 가능합니다.</p>
                                </div>
                            </div>

                            <div className="member_ship_notifi_box" style={{ marginTop: 15 }}>
                                <p style={{ marginRight: 5, color: '#666' }}>a.</p>
                                <div>
                                    <p style={{ color: '#666' }}>정기결제회원: 환붙은 서비스를 이용한일수를 제외하고 일할 계산으로 진행됩니다.</p>
                                    <p style={{ color: '#666' }}>월 기준은 30일이고 월 이용로를 30으로 나눈 금액을 말합니다.</p>
                                    <p style={{ color: '#666' }}>결제시간으로부터 24시간 이후 ~ 15일까지는 남은 일 수에 대한일할 계산으로처리됩니다.</p>
                                </div>
                            </div>

                            <div className="member_ship_notifi_box" style={{ marginTop: 15 }}>
                                <p style={{ marginRight: 5, color: '#666' }}>b.</p>
                                <div>
                                    <p style={{ color: '#666' }}>연간결제회원 :연기준은 12개윌이고 잔여이용료는 전체연간결제이용료를 12로 나눈 하루일에대한 금액이 환불됩니다.</p>
                                </div>
                            </div>
                            <div className="member_ship_notifi_box">
                                <p style={{ marginRight: 10, color: '#666' }}>-</p>
                                <div>
                                    <p style={{ color: '#666' }}>회원이상담 후상대 프로필카드를 2회이상 수령한경우, 잔여횟수가남은 회원에한하여 이용 금액과위약금 10%를 제외한 부분 환불이 가능합니다.</p>
                                </div>
                            </div>

                            <div className="member_ship_notifi_box" style={{ marginTop: 15 }}>
                                <p style={{ marginRight: 5, color: '#666' }}>2.</p>
                                <div>
                                    <p style={{ color: '#666' }}>회사의 귀책사유로결제 오류가 발생한경우</p>
                                </div>
                            </div>

                            <div className="member_ship_notifi_box" style={{ marginTop: 15 }}>
                                <p style={{ marginRight: 5, color: '#666' }}>3.</p>
                                <div>
                                    <p style={{ color: '#666' }}>회사의 귀책사유로서비스가 중단되는 경우</p>
                                    <p style={{ color: '#666' }}>본 조의환불 금액 기준은 연간결제 회원이라하더라도 정기결제 금액으로 계산후 진행됩니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ height: 20 }}/>
                    {/* <p>멤버십 혜택 등 관리자에서 등록한 내용표기</p> */}
                    <p className="member_cancel_btn">멤버십 취소</p>

                </div>
                :
                <div>
                    <div className="payment_title">
                        <p></p>
                        <p className="title">프로노트 정기 결제</p>
                        <img src={require("../../assets/images/icons/x_btn.svg").default} 
                            onClick={() => {
                                // setPayPopup(false);
                                // closePopFunc();
                                // navigate(routes.membership);
                                window.location.reload();
                            }}
                        />
                    </div>

                    <div id="payment-widget" />

                    <div className="payment_bottom_btn">
                        <Button01
                            setFunc={() => { payFunc() }}
                            label={'결제하기'}
                        />
                    </div>
                </div>
            }

        </>
    );
}
