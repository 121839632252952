import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import routes from "../libs/routes";

export default function FooterBtn(props) {
    const { label, width, height, borderType, setFunc, greyChk, btnName } = props;

    return (
        <>
            <div style={{ height: "52px" }}></div>
            <button
                className="footer_button"
                style={{
                    width: isBrowser ? 390 : '100%',
                    height: height ? height : 52,
                    backgroundColor: greyChk ? "#bbb" : "#6D39FF",
                }}
                onClick={() => {
                    setFunc();
                }}
            >
                {btnName ? btnName : "다음"}
            </button>
        </>
    );
}
