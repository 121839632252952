import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import { getData, postData } from "../../utils/service/APIS";
import { calcBirth } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import InputProFileImage from "../../components/InputProFileImage";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";
import TabBar from "../../components/TabBar";
import Icon from "../../components/Icon";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";

const btns = [
    {
        name: "계정설정",
        url: routes.profileDefaultInfo,
    },
    {
        name: "이용약관",
        trace: "policy",
    },
    {
        name: "개인정보처리방침",
        trace: "private",
    },
];

const Mypage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;

    const [button, setButton] = useState(false);
    const [data, setData] = useState([]);
    const [termsOpen, setTermsOpen] = useState(false);
    const [termsTitle, setTermsTitle] = useState(false);
    const [popup, setPopup] = useState();
    const [pfImg, setPfImg] = useState("");
    const [viewDevice, setViewDevice] = useState('')

    if (!token) navigate(routes.login);

    let varUA = navigator.userAgent.toLowerCase(); // 아이폰 구분
    useEffect(() => {
        if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            //IOS
            setViewDevice('ios');
        }
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        
        const sender = {
            type: type,
        };
        
        dispatch(loadingStart());

        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {
            // console.log('마이페이지 ==> ', res);
            if (res.result === "success") {
                const { result, member_birth, marketing_yn, file_name, ...data } = res;
                setButton(marketing_yn === "Y" ? true : false);
                setPfImg(file_name);
                if (member_birth) {
                    data.member_birth = calcBirth(member_birth);
                }
                setData(data);
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });
    };

    const onRadioButtonClick = () => {
        setButton(!button);

        const sender = {
            type: type,
            marketing_yn: button ? "N" : "Y",
        };

        postData(`/mypage.php?trace=marketing_edit&token=${token}`, sender).then((res) => {
            // console.log(res);
            if (res.result === "success") {
                dispatch(open({
                    component: (
                        <Popup01
                            width={350}
                            text={button ? "마케팅 활용에 동의 취소 하였습니다." : "마케팅 활용에 동의 하였습니다."}
                            noneLoading={true}
                            button={'확인 '}
                        />
                    ),
                }));
            }
        }).catch((e) => {
            console.log(e);
        });
    };

    const goProfileEdit = () => {
        navigate(type === 'M' ? routes.profileEdit : routes.profileEditCus);
    };

    const termsFunc = (trace) => {
        getData(`/cs.php?trace=${trace}`).then((data) => {
            console.log(data);
            console.log(data.content);
            console.log(data.length);
            setPopup(data.content);
        });
    };

    const headerRightFunc = () => {
        if (termsOpen) {
            setTermsOpen(false);
        } else {
            navigate(routes.register);
        }
    };

    const logoutPopup = () => {
        dispatch(open({
            component: <Popup01 
                            width={'100%'}
                            text={'로그아웃 하시겠습니까?'}
                            buttonCancel={'취소'}
                            button={'확인'}
                            noneLoading={true}
                            setFunc={logoutFunc}
                        />,
        }));
    }

    const logoutFunc = () => {
        dispatch(logOut());
        setTimeout(() => {
            navigate(routes.login);
        }, 500);
    }


    return (
        <div>
            {!termsOpen ? (
                <>
                    <div>
                        <div className="mypage__title">마이</div>
                        <div className="mypage__userElement">
                            <div className="mypage__userInfoElement">
                                <InputProFileImage
                                    imgvalue={pfImg}
                                    setImgValue={setPfImg}
                                    type={"direct"}
                                />
                                <div className="mypage__userInfo">
                                    <div className="mypage__userName">
                                        <div>{data.member_name}</div>
                                        {type === "M" && <div className="mypage__icon">전문가</div>}
                                    </div>
                                    {type === "C" && (
                                        <div className="mypage_icons">
                                            <div className="mypage__icon">{data.member_birth}세</div>
                                            <div className="mypage__icon">
                                                {data.member_gender === "M" ? "남" : "여"}
                                            </div>
                                            <div className="mypage__icon">{data.member_weight}cm</div>
                                            <div className="mypage__icon">{data.member_height}kg</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mypage__btnContainer" style={{ justifyContent: viewDevice === 'ios' ? 'center' : 'space-between' }}>
                                <div
                                    className="mypage__profileBtn"
                                    onClick={() => goProfileEdit()}
                                >
                                    <Icon
                                        icon={"account-box-line.svg"}
                                        filter={
                                            "invert(17%) sepia(82%) saturate(3046%) hue-rotate(250deg) brightness(123%) contrast(104%)"
                                        }
                                    />
                                    <span>나의 프로필</span>
                                </div>
                                {viewDevice != 'ios' &&
                                    <div className="mypage__joinBtn"
                                        onClick={() => { navigate(routes.membership) }}
                                    >
                                        <Icon
                                            icon={"draft-line.svg"}
                                            filter={
                                                "invert(99%) sepia(100%) saturate(1%) hue-rotate(121deg) brightness(109%) contrast(101%)"
                                            }
                                        />
                                        <span>멤버십 가입</span>
                                    </div>
                                }
                                
                                {/* <div className="mypage__membershipBtn">
                                    <span>멤버십 회원</span>
                                    <span className="mypage__membershipDate">2023.09.10가입</span>
                                </div>  */}
                                
                            </div>
                        </div>
                        <div className="mypage__elements">
                            {btns?.map((v, i) => (
                                <div
                                    key={i}
                                    className="mypage__element"
                                    onClick={() => {
                                        if (v.url) {
                                            navigate(v.url);
                                        } else {
                                            termsFunc(v.trace);
                                            setTermsTitle(v.name);
                                            setTermsOpen(true);
                                        }
                                    }}
                                >
                                    <div className="mypage__elementName">{v.name}</div>
                                    <div className="nextIcon">
                                        <Icon icon={"next-btn.svg"} />
                                    </div>
                                </div>
                            ))}
                            <div className="mypage__radioElement">
                                <div className="mypage__radioElementName">마케팅활용동의</div>
                                <div
                                    className={
                                        "mypage__buttonRadioElement " + (button && "working")
                                    }
                                >
                                    <div className="mypage__buttonName">
                                        {button ? "동의함" : "동의안함"}
                                    </div>
                                    <div
                                        className={"mypage__buttonRadio"}
                                        onClick={onRadioButtonClick}
                                    >
                                        <div className="mypage__buttonRadio--circle"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="logout_btn_box">
                                <p className="logout_btn" onClick={() => {
                                    logoutPopup()
                                }}>로그아웃</p>
                            </div>
                        </div>
                    </div>
                    <TabBar />
                </>
            ) : (
                <>
                    <Header
                        title={termsTitle}
                        rightXBtn={true}
                        rightFunc={() => {
                            headerRightFunc();
                        }}
                    />
                    <TermsPopup content={popup} />
                </>
            )}
        </div>
    );
};

export default Mypage;
