import React, { useEffect, useState } from "react";

// import {
//     regPhone,
//     regEmail,
//     patternNum,
//     patternPrice,
//     patternEng,
//     patternEngUpper,
//     patternSpc,
//     patternSpcId,
//     patternSpcPw,
//     patternSpcEmail,
//     patternKor,
//     numFormat
// } from "../utils/utils";

export default function Input(props) {
    const {
        label,
        value = "",
        setValue,
        placeholder,
        maxlength,
        type,
        name,
        success,
        error,
        error02,
        msg,
        onKeyPress,
        iref,
        disable,
        width,
        height,
        timerChk,
        timerEndFunc,
        timerReStart,
        onBlur,
        onFocus,
        bgColor,
        onlyEng,
        className,
        valid,
        subLabel,
        setError = () => console.log(""),
        readOnly,
        icon,
        searchFunc,


        onChange,
        onChangeAuth,
        index,
        onSearch,
        withButton,
        withButtonPress,
        withButtonLong,
        withText,
        full,
        autoComplete = "true",
        max,
    } = props;

    const [f, setF] = useState(false);
    const [viewPass, setViewPass] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const onKeyPressFunc = (e) => {
        if (onKeyPress && e.key === "Enter") {
            onKeyPress();
        }
    };

    const passChange = () => {
        setViewPass(!viewPass);
    };

    /* 타이머 */
    const MINUTES_IN_MS = 3 * 600 * 100;
    const INTERVAL = 1000;
    const [timeLeft, setTimeLeft] = useState(MINUTES_IN_MS);
    const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(
        2,
        "0"
    );
    const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, "0");

    useEffect(() => {
        if (!timerReStart) setTimeLeft(MINUTES_IN_MS);
    }, [timerReStart]);

    useEffect(() => {
        if (!timerChk) return;

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - INTERVAL);
        }, INTERVAL);

        if (timeLeft <= 0) {
            clearInterval(timer);
            timerEndFunc();
            // console.log('타이머가 종료되었습니다.');
        }

        return () => {
            clearInterval(timer);
        };
    }, [timeLeft]);

    return (
        <>
            {label && (
                <p className="input_label" htmlFor={name} style={{ paddingBottom: 8 }}>
                    {label}
                    <span>*</span>
                </p>
            )}
            {subLabel && (
                <p className="input_label" htmlFor={name} style={{ paddingBottom: 8 }}>
                    {subLabel}
                </p>
            )}
            <div
                style={{
                    position: "relative",
                    width: width ? width : 310,
                    height: height ? height : 44,
                }}
            >
                <input
                    ref={iref}
                    type={
                        type ? (type === "password" && viewPass ? "text" : type) : "text"
                    }
                    className={"text_input_default"}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    maxLength={maxlength ? maxlength : "255"}
                    onKeyPress={onKeyPressFunc}
                    onInput={(e) => {
                        if (type === "number") {
                            if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }
                    }}
                    disabled={disable}
                    style={{ backgroundColor: bgColor ? bgColor : "" }}
                />

                <div className="input_icon_box">
                    {icon === 'search' && 
                        <img 
                            src={require("../assets/images/icons/search_icon.svg").default} 
                            onClick={() => {
                                if(searchFunc) searchFunc();
                            }}
                        />
                    }
                </div>

                {timerChk && (
                    <div className="input_timer">
                        <p>{minutes}</p>
                        <p>:</p>
                        <p>{second}</p>
                    </div>
                )}

                {type === "password" && viewPass ? (
                    <div className="pass_open" onClick={passChange} />
                ) : type === "password" && !viewPass ? (
                    <div className="pass_close" onClick={passChange} />
                ) : (
                    <></>
                )}
                {error02 && !f && <p className="input_error">{error02}</p>}
            </div>

            {error && !f && <p className="input_error">{error}</p>}
            {success && !f && <p className="input_success">{success}</p>}
            {msg && <p className="input_msg">{msg}</p>}
        </>
    );
}
