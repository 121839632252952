import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import Pagination from 'react-js-pagination'

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import SelectBox from "../../components/SelectBox";

export default function UserTemplateList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);
    const token = state?.accessToken;
    const member_id = location?.state?.member_id;
    const reg_type = location?.state?.reg_type;

    const [dataList, setDataList] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [searchTrue, setSearchTrue] = useState(true);
    const [selIndex, setSelIndex] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pagePer, setPagePer] = useState(5);

    useEffect(() => {
        loadData();
    }, [page]);

    const loadData = (sp_type='') => {
        if(!searchTrue) return;

        let sender = {
            type: reg_type, // P=경과, E=평가
            page: ((page-1) * pagePer),
            num_per_page: pagePer,
        };

        if(searchVal) sender.searchVal = searchVal;
        
        if(sp_type !== '') {
            sender.sp_type = sp_type;
        } else {
            sender.sp_type = selIndex;
        }

        let url = consts.tem_register_list;
        console.log(sender);

        dispatch(loadingStart());

        setSearchTrue(false); // 검색 결과를 가져오고 리스트를 제대로 불러오기 전까지는 다시 검색X

        postData(`${url}${token}`, sender).then((res) => {
            console.log('템플릿 리스트 =>> ', res);

            if (res.result === "success") {
                setTotal(res?.total);
                setDataList(res?.data?.filter((x, i) => x?.membership_type != '2')); // 일단 임시로 멤버십 가리기
            }

            // if (res.result === "error") {
            //     dispatch(
            //         open({
            //             component: (
            //                 <Popup01
            //                     width={'100%'}
            //                     text={res.message}
            //                     button={"확인"}
            //                     noneLoading={true}
            //                     func={"setFunc"}
            //                     setFunc={() => {
            //                         navigate(routes.memberList);
            //                     }}
            //                 />
            //             ),
            //         })
            //     );
            // };

            setTimeout(() => {
                setSearchTrue(true);
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                setSearchTrue(true);
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

    };



    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = (x) => {
        if(reg_type === 'P') {
            navigate(routes.userTemplateDetail, { state: { id: x?.idx, member_id: member_id }});
        } else {
            navigate(routes.userResultDetail, { state: { result_data: x, member_id: member_id, result_date: new Date() } });
        }
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={reg_type === 'P' ? "기록 템플릿" : '평가 템플릿'}
            />
            <div>
                <div className="date_select_box">
                    <Input
                        width={'100%'}
                        height={50}
                        placeholder={"제목을 입력해주세요."}
                        value={searchVal}
                        setValue={setSearchVal}
                        maxLength={255}
                        icon={'search'}
                        onKeyPress={loadData}
                        searchFunc={loadData}
                    />
                    <div className="search_type_sel_box" style={{ marginTop: 12, marginBottom: 20 }}>
                        <p className="date_select_box_title">
                            {mbData?.type === 'M' ?
                                '전문가영역선택'
                                :
                                '영역선택'
                            }
                        </p>
                        <SelectBox
                            Width={'100%'}
                            data={['전제', '물리치료사', ' 필라테스', '트레이너']}
                            index={selIndex}
                            setIndex={(i) => { 
                                setSelIndex(i);
                                loadData(i);
                            }}
                        />
                    </div>

                    <div>
                        {dataList?.length > 0 && dataList?.map((x, i) => {
                            return(
                                <div key={i} className="tem_content_box" onClick={() => { naviFunc(x) }}>
                                    <p className="tem_title">{x?.title}</p>
                                    {x?.membership_type === '2' && 
                                        <p className="mem_ship">멤버쉽</p>
                                    }
                                </div>
                            )
                        })}
                        {(!dataList || dataList?.length === 0) && 
                            <div className="not_list_box" style={{ paddingTop: 40 }}>
                                <img src={require("../../assets/images/icons/notResult.svg").default}/>
                                <p>데이터가 없습니다.</p>
                            </div>
                        }
                    </div>
                    
                </div>
                {dataList?.length > 0 &&
                    <Pagination
                        activePage={page} // 현재 활성화 페이지
                        itemsCountPerPage={pagePer} // 페이지당 항목수
                        pageRangeDisplayed={total} // 범위
                        totalItemsCount={total} // 항목의 총 갯수
                        prevPageText={<img src={require("../../assets/images/icons/chevron_left.svg").default} />}
                        nextPageText={<img src={require("../../assets/images/icons/chevron_right.svg").default} />}
                        firstPageText={<img src={require("../../assets/images/icons/first_page.svg").default} />}
                        lastPageText={<img src={require("../../assets/images/icons/last_page.svg").default} />}
                        onChange={(i) => {
                            setPage(i);
                        }}
                    />
                }
            </div>
        </>
    );
}
