import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';
import {
  numFormat,
  patternNickFunc,
  regEmail,
  regPassword,
  patternSpcInstar,
  regPhoneChk,
} from "../../utils/utils";

import {
  logIn,
  logOut,
  gpsUpdate,
  configData,
  setData,
  aroundData,
  menuClose,
  mainReset,
  searchClose,
  pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";

export default function SearchId(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [sucChk, setSucChk] = useState(false);
  const [regiType, setRegiType] = useState(false);
  const [snsName, setSnsName] = useState("네이버");
  const [snsNameEn, setSnsNameEn] = useState("naver");
  const [err01, setErr01] = useState("");

  useEffect(() => {
    setErr01("");
  }, [phone]);

  const headerLeftFunc = () => {
    navigate(-1);
  };

  const phoneChk = () => {
    if (!phone) {
      setErr01("휴대폰번호를 입력해주세요.");
      return;
    }
    if (!regPhoneChk(phone)) {
      setErr01("입력 양식에 맞지 않습니다.");
      return;
    }

    var sender = {
      usertel: phone,
    };

    dispatch(loadingStart());

    postData("/authorization.php?trace=find_id", sender)
      .then((data) => {
        console.log(data);
        if (data.result === "success") {
          dispatch(loadingEnd());
          setSucChk(true);
          setEmail(data.id);
          setRegiType(false);
        } else {
          dispatch(loadingEnd());
          setErr01(data.message);
          return;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(loadingEnd());
        }, 100);
      });
  };

  return (
    <>
      <Header
        title={"아이디 찾기"}
        leftBtn={true}
        leftFunc={() => {
          headerLeftFunc();
        }}
      />

      {!sucChk ? (
        <>
          <div className="search_box">
            <Input
              value={phone}
              setValue={setPhone}
              error={err01}
              subLabel={"핸드폰번호"}
              placeholder={"'-'없이 입력하세요."}
              bgColor={"#eee"}
            />

            <div style={{ height: 20 }} />

            <Button01
              label={"인증번호 받기"}
              setFunc={() => {
                phoneChk();
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="search_suc_box">
            {!regiType ? (
              <>
                <p className="title">회원님은 이메일로 가입하셨습니다.</p>
                <img
                  className="mail_icons"
                  src={
                    require("../../assets/images/icons/mail_icon.svg").default
                  }
                />
              </>
            ) : (
              <>
                <p className="title">회원님은 {snsName} 간편가입하셨습니다.</p>
                <p className="title">소셜 간편로그인해주세요.</p>
                <img
                  className="sns_icons"
                  src={require(`../../assets/images/icons/naver.svg`).default}
                />
              </>
            )}
            <p className="search_email">{email}</p>
            <Button01
              label={"로그인하기"}
              setFunc={() => {
                navigate(routes.login);
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
