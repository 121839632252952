import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import { postData, postImageData } from "../../utils/service/APIS";
import { resizeFile } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import DaumPostcode from "react-daum-postcode";

import Popup01 from "../popup/Popup01";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Icon from "../../components/Icon";
import ProfileInput from "../../components/ProfileInput";

const masterTypes = [
    {
        name: "물리치료사",
        value: "1",
    },
    {
        name: "필라테스",
        value: "2",
    },
    {
        name: "트레이너",
        value: "3",
    },
];

const btns = [
    {
        name: "학력",
        url: "profileEducation",
    },
    {
        name: "경력",
        url: "profileCareer",
        data: "career",
    },
    {
        name: "자격증",
        url: "profileCertificate",
    },
    {
        name: "소개",
        url: "profileIntroduction",
        data: "introduction",
    },
];

export default function ProfileEdit() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;

    const [value, setValue] = useState([{ career: null, introduction: null }]);
    const [masterType, setMasterType] = useState("1");
    const [gender, setGender] = useState("M");
    const [birth, setBirth] = useState();
    const [workName, setWorkName] = useState();
    const [post, setPost] = useState();
    const [address, setAddress] = useState();
    const [addressEtc, setAddressEtc] = useState();
    const [isPopup, setIsPopup] = useState(false);
    const [pfImg, setpfImg] = useState([]); // 이미지
    const [checkKey, setCheckKey] = useState(""); // 이미지 check key
    const [plusInfo, setPlusInfo] = useState(false);
    

    // useEffect(() => {
    //     console.log('pfImg ==> ', pfImg);
    // }, [pfImg])

    useEffect(() => {
        if(type != 'M') navigate(routes.login);
        loadData();
    }, []);

    useEffect(() => {
        workChkFunc(1);
    }, [birth]);

    useEffect(() => {
        workChkFunc(1);
    }, [workName]);

    useEffect(() => {
        workChkFunc(2);
    }, [address]);

    // useEffect(() => {
    //     const popup = document.querySelector(".popup.show");
    //     if (popup) popup.classList.add("popup_daum");
    // }, [isPopup]);

    const loadData = () => {
        
        let sender = {
            type: type,
        };
        
        dispatch(loadingStart());

        postData(`${consts.my_member_detail}${token}`, sender).then((res) => {

            // console.log('resul => ', res);
            
            if(res.result === "success") {
                setAddress(res.address);
                setAddressEtc(res.address_etc);
                setBirth(res.birth);
                setGender(res.gender ? res.gender : "M");
                setPost(res.post);
                setMasterType(res.special_type ? res.special_type : "1");
                setWorkName(res.work_name);
                setCheckKey(res.check_key);
                setValue({
                    career: res.career,
                    introduction: res.introduction,
                });
                if(res.special_type && res.gender && res.birth && res.work_name && res.address && res.address_etc) setPlusInfo(true);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });

        getProfileImgFunc();
    };

    const getProfileImgFunc = () => {

        postData(`/mypage.php?trace=profile_view&token=${token}`, {}).then((res) => {
            // console.log(res);
            if (res.result === "success" && res?.data?.length > 0) {
                let imageUrlLists = [];
                for (let i = 0; i < res?.data?.length; i++) {
                    imageUrlLists.push(res.data[i].file_name);
                }
                setpfImg(imageUrlLists);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        });

    }

    const goPreveiwFunc = () => {
        navigate(routes.profilePreview);
    };

    const headerLeftFunc = () => {
        navigate(routes.mypage);
    };

    const saveFunc = () => {

        if(!type || !masterType || !gender || !birth || !workName || !address || !addressEtc) return;
        
        const sender = {
            type: type, // M: 전문가, C: 고객 // 현재 페이지는 전문가용이기때문에 고객이 오지는 않는다.
            special_type: masterType, // 1: 물리치료사, 2: 필라테스, 3: 트레이너
            gender: gender, // M: 남자, F: 여자
            birth: birth,
            work_name: workName,
            address: address,
            address_etc: addressEtc,
            post: post,
            check_key: checkKey,
        };
        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`/mypage.php?trace=member_detail_edit&token=${token}`, sender).then((res) => {
            console.log(res);
            setPlusInfo(true);
            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={res?.message}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });
    };

    const workChkFunc = (type) => {
        if (type === 1 && (!workName || workName === "")) {
            return;
        }

        if (type === 2 && (!address || address === "")) {
            return;
        }

    };

    const handleChange = async (value) => {
        var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
        let ck = true;

        for (let i = 0; i < value.length; i++) {
            if (!value[i].name.toLowerCase().match(reg)) {
                ck = false;
            }
        }

        if(ck) {
            const imageLists = value;
            let imageUrlLists = [];

            for (let i = 0; i < imageLists.length; i++) {
                imageUrlLists.push(await resizeFile(value[i], 500, 500));
            }

            if (imageUrlLists.length > 4) {
                imageUrlLists = imageUrlLists.slice(0, 4);
            }

            // setpfImg(imageUrlLists);

            dispatch(loadingStart());

            const formData = new FormData();

            for (let i = 0; i < imageLists.length; i++) {
                formData.append("file_name", value[i]);
            }
            formData.append("type", type);
            formData.append("check_key", checkKey);

            postImageData(`/mypage.php?trace=profile_up&token=${token}`, formData).then((res) => {

                console.log('img_save => ', res);

                if (res.result === "success") {
                    setCheckKey(res.check_key);
                    setpfImg([...pfImg, "/upload/tmp/" + res?.file_name]);
                } else {
                    dispatch(open({
                        component: <Popup01 
                                        width={'100%'}
                                        text={'파일등록에 실패 하였습니다.'}
                                        button={'확인'}
                                        noneLoading={true}
                                    />,
                    }));
                }

                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.lasy);

            }).catch((e) => {
                console.log(e);
            });
        } else {
            console.log("이미지 파일만 업로드 가능합니다,");
        }
    };

    const handlePostCode = (data) => {
        // console.log('실행 ~~₩');
        // console.log(data);
        // dispatch(close());
        // return;
        let fullAddress = data.address;
        let zonecode = data.zonecode;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress +=
                    extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }

        setAddress(fullAddress);
        setPost(zonecode);
        dispatch(close());
    };

    // 팝업창 열기
    const openPostCode = () => {
        dispatch(
            open({component: (
                <>
                    <div 
                        className="popup_daumInput" 
                        style={{ width: isBrowser ? 350 : '100%' }}
                    >
                        <Header
                            leftBtn={true}
                            leftFunc={() => {
                                dispatch(close());
                            }}
                        />
                        <DaumPostcode onComplete={handlePostCode} />
                    </div>
                </>
            )})
        );
    };

    return (
        <div className="profile">
            <Header
                leftBtn={true}
                leftFunc={() => headerLeftFunc()}
                title={"프로필"}
                rightTextBtn={"미리보기"}
                rightFunc={() => {
                    goPreveiwFunc();
                }}
                leftWidth={'40%'}
                centerWidth={'30%'}
                rightWidth={'40%'}
            />

            <div className="profile__container">
                {!plusInfo &&
                    <div className="profile__alertElement">
                        <Icon icon={"alert-line.svg"} />
                        <p>서비스 이용을 위해 필수 등록사항입니다.</p>
                    </div>
                }

                <div>
                    <div className="profile__uploadImageContainer">
                        <div className="profile__uploadImageHeader">
                            <div>프로필 사진</div>
                            <label htmlFor="profile" className="profile__uploadImageBtn">
                                <Icon
                                    icon={"add-line.svg"}
                                    filter={
                                        "invert(99%) sepia(100%) saturate(1%) hue-rotate(121deg) brightness(109%) contrast(101%)"
                                    }
                                />
                                <div className="profile">
                                    <span>등록하기</span>
                                </div>
                                <input
                                    type="file"
                                    id={"profile"}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={(e) => {
                                        handleChange(e.target.files);
                                    }}
                                    accept=".jpg, .png, .jpeg, .gif, .svg"
                                />
                            </label>
                        </div>
                    </div>

                    <div className="profile_swiper_box">
                        {pfImg?.length > 0 && 
                            <Swiper
                                modules={[ Navigation, Pagination ]}
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation
                                loop={true}
                                loopedSlides={1}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {pfImg.map((v, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div className="profile_swiper">
                                                <img src={"https://www.pronoteweb.net" + v} onClick={() => { }} />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        }
                        
                        {pfImg?.length === 0 &&
                            <div className="profile_swiper">
                                <img src={require("../../assets/images/icons/preview-img.svg").default} />
                            </div>
                        }
                    </div>
                    
                    <div className="profile__masterContainer">
                        <div className="profile__masterTypeContaienr">
                            <div className="profile__elementTitle">
                                전문가 유형을 선택해 주세요.
                            </div>
                            <div className="profile__masterTypeBtns">
                                {masterTypes.map((v, i) => (
                                    <div
                                        key={i}
                                        className={
                                            "profile__masterTypeBtn " +
                                            (v.value === masterType && "working")
                                        }
                                        onClick={() => setMasterType(v.value)}
                                    >
                                        {v.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="profile__defaultInfoContainer">
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">성별</div>
                                <div className="profile__genderBtns">
                                    <div
                                        className={
                                            "profile__genderBtn " + (gender === "M" && "working")
                                        }
                                        onClick={() => setGender("M")}
                                    >
                                        남성
                                    </div>
                                    <div
                                        className={
                                            "profile__genderBtn " + (gender === "F" && "working")
                                        }
                                        onClick={() => setGender("F")}
                                    >
                                        여성
                                    </div>
                                </div>
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">생년월일</div>
                                <input
                                    type={'number'}
                                    placeholder="ex)19851205"
                                    value={birth}
                                    onInput={(e) => {
                                        if (e.target.value.length > 8)
                                            e.target.value = e.target.value.slice(0, 8);
                                    }}
                                    onChange={(e) => setBirth(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="profile__divide"></div>
                    <div className="profile__elementContainer">
                        <ProfileInput
                            value={workName}
                            setValue={setWorkName}
                            placeholder={"근무지명"}
                        />
                        <ProfileInput
                            value={address}
                            setValue={setAddress}
                            label={"근무지 주소"}
                            onClickFunc={() => openPostCode()}
                            openPostCode={openPostCode}
                        >
                            <ProfileInput
                                check={true}
                                checkValue={address}
                                value={addressEtc}
                                setValue={setAddressEtc}
                                placeholder={"상세주소"}
                                onClickFunc={() => {
                                    openPostCode();
                                }}
                            />
                        </ProfileInput>
                    </div>
                    <div className="profile__divide"></div>
                    <div className="profile__elementContainer">
                        <div className="profile__elementTitle">
                            추가 정보를 입력해주세요.
                        </div>
                        <div className="profile__etcInfoElements">
                            {btns.map((v, i) => (
                                <div
                                    key={i}
                                    className="profile__etcInfoElement"
                                    onClick={() => {
                                        if(!plusInfo) {
                                            dispatch(open({
                                                component: (
                                                    <Popup01
                                                        width={350}
                                                        text={'기본 정보를 입력후 저장해주세요.'}
                                                        button={"확인"}
                                                        noneLoading={true}
                                                    />
                                                ),
                                            }));
                                            return;
                                        }
                                        navigate(routes[v.url], { state: { data: value[v.data] } });
                                    }}
                                >
                                    <div className="profile__etcInfoElementName">{v.name}</div>
                                    <div className="nextIcon">
                                        <Icon icon={"next-btn.svg"} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            <FooterBtn
                greyChk={!birth && !workName && !post && !address && !addressEtc}
                setFunc={saveFunc}
                btnName="저장"
            />
        </div>
    );
};
