import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import { postData, postImageData } from "../../utils/service/APIS";
import { resizeFile } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import DaumPostcode from "react-daum-postcode";

import Popup01 from "../popup/Popup01";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Icon from "../../components/Icon";
import ProfileInput from "../../components/ProfileInput";

const masterTypes = [
    {
        name: "물리치료사",
        value: "1",
    },
    {
        name: "필라테스",
        value: "2",
    },
    {
        name: "트레이너",
        value: "3",
    },
];

const btns = [
    {
        name: "학력",
        url: "profileEducation",
    },
    {
        name: "경력",
        url: "profileCareer",
        data: "career",
    },
    {
        name: "자격증",
        url: "profileCertificate",
    },
    {
        name: "소개",
        url: "profileIntroduction",
        data: "introduction",
    },
];
export default function ProfileEditCus (){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;

    const [gender, setGender] = useState("M");
    const [birth, setBirth] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('')
    const [address, setAddress] = useState();
    const [addressEtc, setAddressEtc] = useState();
    const [post, setPost] = useState();
    const [isPopup, setIsPopup] = useState(false);


    useEffect(() => {
        if(type != 'C') navigate(routes.login);
        loadData();
    }, []);


    // useEffect(() => {
    //     const popup = document.querySelector(".popup.show");
    //     if (popup) popup.classList.add("popup_daum");
    // }, [isPopup]);

    const loadData = () => {
        
        let sender = {
            type: type,
        };

        dispatch(loadingStart());

        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {

            console.log('resul => ', res);
            
            if(res?.result === "success") {
                setGender(res?.member_gender);
                setBirth(res?.member_birth);
                setHeight(res?.member_height);
                setWeight(res?.member_weight);
                setAddress(res?.member_address);
                setAddressEtc(res?.member_address_etc);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

    };

    const headerLeftFunc = () => {
        navigate(routes.mypage);
    };

    const footerBtn = () => {
        if(!gender || !birth || !height || !weight || !address || !addressEtc) return;
        

        let sender = {
            type: type,
            gender: gender,
            birth: birth,
            height: height,
            weight: weight,
            address: address,
            address_etc: addressEtc,
            post: post,
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.my_member_detail_edit}${token}`, sender).then((res) => {
            // console.log(res);

            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={res?.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );

            navigate(routes.mypage)

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };


    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let zonecode = data.zonecode;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress +=
                    extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }

        setAddress(fullAddress);
        setPost(zonecode);
        dispatch(close());
    };

    // 팝업창 열기
    const openPostCode = () => {
        dispatch(
            open({
                component: (
                    <>
                        <div 
                            className="popup_daumInput" 
                            style={{ width: isBrowser ? 350 : '100%' }}
                        >
                            <Header
                                leftBtn={true}
                                leftFunc={() => {
                                    dispatch(close());
                                }}
                            />
                            <DaumPostcode onComplete={handlePostCode} />
                        </div>
                    </>
                ),
            })
        );
    };

    return (
        <div className="profile">
            <Header
                leftBtn={true}
                leftFunc={() => headerLeftFunc()}
                title={"프로필"}
            />
            <div className="profile__container">
                {(!birth || !height || !weight || !height || !address || !addressEtc) &&
                    <div className="profile__alertElement">
                        <Icon icon={"alert-line.svg"} />
                        <p>서비스 이용을 위해 필수 등록사항입니다.</p>
                    </div>
                }

                <div>
                    
                    <div className="profile__masterContainer">
                        <div className="profile__defaultInfoContainer">
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">성별</div>
                                <div className="profile__genderBtns">
                                    <div
                                        className={
                                            "profile__genderBtn " + (gender === "M" && "working")
                                        }
                                        onClick={() => setGender("M")}
                                    >
                                        남성
                                    </div>
                                    <div
                                        className={
                                            "profile__genderBtn " + (gender === "F" && "working")
                                        }
                                        onClick={() => setGender("F")}
                                    >
                                        여성
                                    </div>
                                </div>
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">생년월일</div>
                                <input
                                    type={'number'}
                                    placeholder="ex)19851205"
                                    value={birth}
                                    onInput={(e) => {
                                        if (e.target.value.length > 8)
                                            e.target.value = e.target.value.slice(0, 8);
                                    }}
                                    onChange={(e) => setBirth(e.target.value)}
                                />
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">신장</div>
                                <input
                                    type={'number'}
                                    style={{ width: 120, textAlign: 'right' }}
                                    placeholder="0"
                                    value={height}
                                    onInput={(e) => {
                                        if (e.target.value.length > 3)
                                            e.target.value = e.target.value.slice(0, 3);
                                    }}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                                <p style={{ fontWeight: 400, fontSize: 12, marginLeft: 8 }}>cm</p>
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">체중</div>
                                <input
                                    type={'number'}
                                    style={{ width: 120, textAlign: 'right' }}
                                    placeholder="0"
                                    value={weight}
                                    onInput={(e) => {
                                        if (e.target.value.length > 3)
                                            e.target.value = e.target.value.slice(0, 3);
                                    }}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                                <p style={{ fontWeight: 400, fontSize: 12, marginLeft: 8 }}>kg</p>
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle">주소</div>
                                <input
                                    placeholder=""
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    readOnly={true}
                                    onFocus={openPostCode}
                                />
                            </div>
                            <div className="profile__defaultInfoElement">
                                <div className="profile__defaultInfoTitle"></div>
                                <input
                                    placeholder="상세주소"
                                    value={addressEtc}
                                    onChange={(e) => setAddressEtc(e.target.value)}
                                />
                            </div>
                                
                        </div>
                    </div>
                    
                </div>
            </div>
            <FooterBtn
                greyChk={!birth || !height || !weight || !address || !addressEtc}
                setFunc={footerBtn}
                btnName="저장"
            />
        </div>
    );
};
