import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserResultDetailEx() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);

    const token = state?.accessToken;
    const result_data = location?.state?.result_data;
    const result_date = location?.state?.result_date;
    const exTem = location?.state?.exTem; // 템플릿 보기로 눌렀을때만 사용
    const member_id = location?.state?.member_id;
    const delete_chk = location?.state?.delete_chk;
    const chkUse = location?.state?.chkUse; // 회원은 전문가가 작성한 평가 항목 수정이 불가능하게 끔 하기 위해 받아오는 데이터

    const [taContent, setTaContent] = useState('');
    const [totalScore, setTotalScore] = useState(0);
    const [dataList, setDataList] = useState([]);

    const [senderData, setSenderData] = useState(
        {
            type: mbData?.type,
            register_idx: result_data?.register_idx,
            register_template_idx: result_data?.idx ? result_data?.idx : result_data?.register_template_idx,
            member_id: member_id,
            reg_date_chk: moment(result_date).format('YYYY-MM-DD'),
        }
    )

    // console.log('result_data => ', result_data);
    // console.log('member_id => ', member_id);

    useEffect(() => {
        if(exTem) {
            loadDataEx();
        } else {
            loadData();
        }
    }, []);

    useEffect(() => {
        // console.log('변화 감지 ===> ', dataList);
        let score = 0;
        dataList?.map((x, i) => {
            if((x.answer_score && x.answer_score != 'NA') || (x.score && x.score != 'NA')) {
                score += (x.score*1 ? x.score*1 : x.answer_score*1);
            }
            x?.depth?.map((v, n) => {
                if((v.answer_score && v.answer_score != 'NA') || (v.score && v.score != 'NA')) {
                    score += (v.score*1 ? v.score*1 : v.answer_score*1);
                }
                return v;
            })
            return x;
        });
        setTotalScore(score);
    }, [dataList]);

    const loadDataEx = () => {
        dispatch(loadingStart());
        
        const sender = {
            type: "E", // P:경과 E:평가
            register_idx: result_data?.idx ? result_data?.idx : result_data?.register_idx,
            week: ''
        };

        postData(`${consts.tem_template_view}${token}`, sender).then((res) => {
            setDataList(res?.data);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const loadData = () => {
        
        let sender = {
            type: mbData?.type,
            register_idx: result_data?.register_idx,
            register_template_idx: result_data?.idx ? result_data?.idx : result_data?.register_template_idx,
            member_id: member_id ? member_id : mbData?.userid,
            result_date: result_date
        };

        console.log('send => ', sender);
        
        dispatch(loadingStart());
        
        postData(`${consts.tem_template_answer_e_view}${token}`, sender).then((res) => {
            console.log('resres => ', res);
            setDataList(
                res?.data?.map((x, i) => {
                    if(!x.score) x.score = x.answer_score;
                    x?.depth?.map((v, n) => {
                        if(!v.score) v.score = v.answer_score;
                        return v;
                    })
                    return x;
                })
            );

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }
    

    const headerLeftFunc = () => {
        // navigate(routes.userDetail, { state: { cus_email: member_id }});
        navigate(-1);
    };


    // 본질문 데이터 넣는 함수
    const chkFunc = (val, index, chk, score) => {
        if(chkUse || exTem) return;
        // index: 본질문 번호, chk: 선택한 질문 번호, score: 점수
        setDataList(
            dataList?.map((x, i) => {
                if(i === index) {
                    if(score) {
                        x.answer = (chk+1).toString();
                        x.score = score;
                    } else {
                        x.answer = chk;
                        x.score = "";
                    }
                }
                return x;
            })
        )
    }


    // 서브질문 데이터 넣는 함수
    const chkFuncSub = (index, isub, num, score) => {
        if(chkUse || exTem) return;
        // index: 본질문 번호, i: 뎁스질문 번호, num: 선택한 번호, score: 점수
        setDataList(
            dataList?.map((x, i) => {
                if(i === index) {
                    x?.depth?.length > 0 && x?.depth.map((v, n) => {
                        if(n === isub) {
                            if(score) {
                                v.answer = (num+1).toString();
                                v.score = score;
                            } else {
                                v.answer = num;
                                v.score = "";
                            }
                        }
                        return v;
                    })
                }
                return x;
            })
        )
    }

    // 1:점수척도, 2:점수+옵션명, 3: 서술형
    // 점수척도
    const optionBoxType_1 = (val, index) => {
        // console.log('????????? => ', val?.answer);
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{(index + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div className="result_detail_content_box">
                    {val?.options?.length > 0 && val?.options?.map((x, i) => {
                        return (
                            <p 
                                key={i} 
                                className={(i+1) == val?.answer ? "active_result_num" : ""} 
                                onClick={() => { 
                                    chkFunc(val, index, i, x?.score)
                                }}
                            >
                                {x?.score}
                            </p>
                        )
                    })}
                </div>
                {val?.depth?.length > 0 && <div style={{ height: 20 }} />}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log(x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 점수척도 서브
    const optionBoxType_1_sub = (val, i, index) => {
        // console.log(val);
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{index + 1}-{(i + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div className="result_detail_content_box">
                    {val?.options?.length > 0 && val?.options?.map((x, n) => {
                        return (
                            <p 
                                key={n} 
                                className={(n+1) == val?.answer ? "active_que_num" : ""} 
                                onClick={() => { chkFuncSub(index, i, n, x?.score) }}
                            >{x?.score}</p>
                        )
                    })}
                </div>
            </div>
        )
    }
    
    // 점수+옵션명
    const optionBoxType_2 = (val, index) => {
        // console.log(val);
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{(index + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div>
                    {val?.options?.length > 0 && val?.options?.map((x, i) => {
                        return(
                            <div key={i} className="result_detail_content_box02">
                                <p 
                                    className={(i+1) == val?.answer ? "active_result_num" : ""} 
                                    onClick={() => { chkFunc(val, index, i, x?.score) }}
                                >
                                    ({x?.score}점) {x?.title}
                                </p>
                            </div>
                        )
                    })}
                </div>
                {val?.depth?.length > 0 && <div style={{ height: 20 }} />}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log(x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 점수+옵션명 서브
    const optionBoxType_2_sub = (val, i, index) => {
        // console.log('sub ==> ', val?.answer);
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{index + 1}-{(i + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div>
                    {val?.options?.length > 0 && val?.options?.map((x, n) => {
                        return(
                            <div key={n} className="result_detail_content_box02">
                                <p 
                                    className={(n+1) == val?.answer ? "active_que_num" : ""} 
                                    onClick={() => { chkFuncSub(index, i, n, x?.score) }}
                                >
                                    ({x?.score}점) {x?.title}
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    
    // 서술형
    const optionBoxType_3 = (val, index) => {
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{(index + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div className="result_detail_textarea" >
                    <TextAreaEdit
                        Height={200}
                        placeholder={"내용을 입력해 주세요."}
                        value={val?.answer}
                        setValue={(text) => { 
                            chkFunc(val, index, text, '');
                        }}
                        Disabled={exTem ? exTem : ''}
                    />
                </div>
                {val?.depth?.length > 0 && <div style={{ height: 20 }} />}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log(x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 서술형 서브
    const optionBoxType_3_sub = (val, i, index) => {
        return (
            <div style={{ marginBottom: 30 }}>
                <div className="result_detail_content_title">
                    <p className="number">{index + 1}-{(i + 1)}.</p>
                    <p>{val?.title}</p>
                </div>
                <div className="result_detail_textarea" >
                    <TextAreaEdit
                        Height={200}
                        placeholder={"내용을 입력해 주세요."}
                        value={val?.answer}
                        setValue={(text) => { chkFuncSub(index, i, text, '') }}
                        Disabled={exTem ? exTem : ''}
                    />
                </div>
            </div>
        )
    }


    const saveFunc = () => {
        let sender = senderData;
        sender.data = dataList;
        
        console.log('sender => ', sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_e_save}${token}`, sender).then((res) => {
            console.log('result => ', res);
            // dispatch(loadingEnd());
            // return;

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res?.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            setTimeout(() => {
                // if(mbData?.type === 'M') {
                //     goDetailFunc();
                // } else {
                //     navigate(-1);
                // }
                dispatch(loadingEnd());
                navigate(-1);
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }
    

    const headerRightFunc = () => {
        if(chkUse) return;
        dispatch(open({
            component: <Popup01 
                            width={380}
                            text={'삭제 하시겠습니까?'}
                            buttonCancel={'아니요'}
                            button={'확인'}
                            setFunc={() => { deleteFunc() }}
                            noneLoading={true}
                        />,
        }));
    }
    
    const deleteFunc = () => {
        const sender = {
            type: mbData?.type, // M:전문가, C:고객
            reg_type: "E", // P:경과, E:평가
            register_idx: result_data?.register_idx,
            register_template_idx: result_data?.idx ? result_data?.idx : result_data?.register_template_idx,
            member_id: member_id,
            result_date: result_date
        };

        // console.log('ㅇ디 =>? ', sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_del}${token}`, sender).then((res) => {
            console.log('result =>> ', res);

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            setTimeout(() => {
                navigate(-1);
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }


    // const goDetailFunc = () => {
    //     navigate(routes.userDetail, { state: { cus_email: member_id } });
    // }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={`${result_data?.title}`}
                rightTextBtn={(delete_chk !== 'N' && !exTem && !chkUse) ? '삭제' : ''}
                rightFunc={() => {
                    if(delete_chk !== 'N') headerRightFunc();
                }}
            />
            
            <div className="result_detail_box temple_week_detail">
                {true ? 
                    <>
                        <div className="top_score_box">
                            <p className="score_title">{result_data?.title}</p>
                            <p className="score_date">{moment(result_date).format('YYYY-MM-DD')}</p>
                            <p className="score_data">{totalScore}점</p>
                        </div>
                    </>
                    :
                    <p className="result_detail_box_title">{location?.state?.id}평가</p>
                }

                <div>

                    {dataList?.length > 0 && dataList?.map((x, i) => {
                        return (
                            <div key={i}>
                                {x?.type === '1' && optionBoxType_1(x, i)}
                                {x?.type === '2' && optionBoxType_2(x, i)}
                                {x?.type === '3' && optionBoxType_3(x, i)}
                            </div>
                        )
                    })}

                </div>
            </div>
            
            {(!exTem  && !chkUse) &&
                <FooterBtn
                    btnName={'저장'}
                    setFunc={saveFunc}
                />
            }
        </>
    );
}
