import { render } from "@testing-library/react";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { open, close } from "../redux/popupSlice";
import { getFileName, clickImg, resizeFile } from "../utils/utils";

import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import { postData, postImageData } from "../utils/service/APIS";
import { loadingEnd, loadingStart } from "../redux/loadingSlice";

export default function InputProFileImage(props) {
    const dispatch = useDispatch();

    const {
        className,
        placeholder,
        type,
        name,
        maxlength,
        value,
        setValue,
        imgvalue,
        setImgValue,
        valid,
        label,
        error,
        setError = () => console.log(""),
        success,
        readOnly,
        onBlur,
        withButton,
        withButtonPress,
        deleteFunc,
        full,
        limitWidth,
        limitHeight,
        regType
    } = props;

    let state = useSelector((state) => {
        return state.usersReducer;
    });
    var token = state.accessToken;
    var user_type = state.mbData.type;

    useEffect(() => { }, []);

    const handleChange = async (value) => {
        var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
        var msg = "이미지";
        var maxSize = 10 * 1024 * 1024;

        let imgVal = "";
        if (value.name.toLowerCase().match(reg)) {
            imgVal = await resizeFile(value, 500, 500);
            setImgValue(imgVal);

            console.log(imgVal);

            dispatch(loadingStart());

            uploadImage(value);
        } else {
            console.log("이미지 파일만 업로드 가능합니다,");
        }
    };

    const uploadImage = (value) => {
        const formData = new FormData();
        formData.append("file_name", value);
        console.log(formData);
        for (const [key, value] of formData.entries()) {
            console.log(key, value);
        }
        if (type === "direct") {
            formData.append("type", user_type);
            postImageData(`/mypage.php?trace=profile_edit&token=${token}`, formData)
                .then((res) => {
                    console.log(res);
                    setTimeout(() => {
                        dispatch(loadingEnd());
                    }, 100);
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            postImageData(`/authorization.php?trace=profile_up`, formData)
                .then((res) => {
                    console.log(res);
                    if (res.result === "success") {
                        setValue(res.check_key);
                    }
                    setTimeout(() => {
                        dispatch(loadingEnd());
                    }, 100);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    return (
        <div className={full ? "input_box input_box_full" : "input_box"}>
            <label htmlFor={"profile"}>
                {imgvalue && imgvalue !== "/upload/member/" ? (
                    <div className="register_avatar">
                        <img src={imgvalue} onClick={() => { }} />
                        <div className="register_avatar_camera">
                            <img
                                src={require("../assets/images/icons/camera_icon.svg").default}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="register_avatar">
                        <img
                            src={require("../assets/images/icons/avatar.svg").default}
                            onClick={() => { }}
                        />
                        <div className="register_avatar_camera">
                            <img
                                src={require("../assets/images/icons/camera_icon.svg").default}
                            />
                        </div>
                    </div>
                )}
            </label>
            <input
                type="file"
                id={"profile"}
                style={{ display: "none" }}
                onChange={(e) => {
                    handleChange(e.target.files[0]);
                }}
                accept=".jpg, .png, .jpeg, .gif, .svg"
            />

            {error && <p className="input_error">{error}</p>}
            {success && <p className="input_success">{success}</p>}
        </div>
    );
}
