import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import routes from '../libs/routes';

export default function Button01(props) {

    const { label, width, height, borderType, setFunc } = props;
    
    return (
        <button 
            className={borderType ? "button01_defalut_border" : "button01_defalut"}
            style={{ 
                width: width ? width : 310,
                height: height ? height : 52,
            }}
            onClick={setFunc}
        >
            {label}
        </button>
    );

}
