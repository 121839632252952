import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { getData } from "../../utils/service/APIS";

export default function RegisterTerms(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const user_type = location?.state?.user_type;
    const reg_type = location?.state?.reg_type; // 가입 타입
    const sns_user_email = location?.state?.sns_user_email; // sns 로그인 이메일
    const applename = location?.state?.applename; // 애플 전용 로그인 했을때 가지고 가는 이름

    const [allchk, setAllchk] = useState(false);
    const [chk01, setChk01] = useState(false);
    const [chk02, setChk02] = useState(false);
    const [chk03, setChk03] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false); // 약관 팝업 오픈 유무
    const [termsTitle, setTermsTitle] = useState('');
    const [popup, setPopup] = useState();
    // "가치를 때까지 트고, 것이다. 힘차게 있는 것은 노년에게서 약동하다. 인생을 이는 별과 하는 찬미를 인류의 뜨고, 운다. 하여도 싹이 보내는 낙원을 맺어, 가는 주며, 것이다.보라, 이상이 때문이다. 크고 청춘 긴지라 광야에서 무엇을 내려온 날카로우나 노년에게서 교향악이다. 만천하의 끝까지 어디 힘차게 그들에게 생명을 약동하다. 옷을 듣기만 얼음과 이상이 이상, 것이다. 노년에게서 인간의 보는 살았으며, 때까지 있다. 곳으로 피가 어디 아름답고 뭇 따뜻한 바이며, 지혜는 것이다."

    useEffect(() => {
        //전체 체크사항 체크했을때
        if (chk01 && chk02 && chk03) {
            setAllchk(true);
        } else {
            setAllchk(false);
        }
    }, [chk01, chk02, chk03]);


    const naviFunc = () => {
        // 전문가인지, 일반인지 데이터가 없으면 다음으로 안보냄
        if (!user_type) return;
        // navigate(routes.register);

        if ((reg_type === 'S' && chk01 && chk02 && !sns_user_email)){
            navigate(routes.registerEmail, {
                state: {
                    user_type: user_type,
                    reg_type: reg_type,
                    marketing_yn: chk03 ? "Y" : "N",
                },
            });
        } else if((reg_type === 'K' || reg_type === 'N' || reg_type === 'G') && chk01 && chk02 && sns_user_email) {
            navigate(routes.registerInput, {
                state: {
                    email: sns_user_email,
                    user_type: user_type,
                    reg_type: reg_type,
                    marketing_yn: chk03 ? "Y" : "N",
                },
            });
        } else if(reg_type === 'A') {
            navigate(routes.registerInput, {
                state: {
                    user_type: user_type,
                    reg_type: reg_type,
                    marketing_yn: chk03 ? "Y" : "N",
                    apple_sub: reg_type === 'A' ? sns_user_email : '',
                    applename: applename
                },
            });
        }
    };

    const termsFunc = (trace) => {
        if(trace === 'policy') setTermsTitle('서비스 이용약관');
        if(trace === 'private') setTermsTitle('개인정보 수집 및 이용 동의');
        if(trace === 'event_policy') setTermsTitle('이벤트 및 마케팅 활용 동의');
        
        getData(`/cs.php?trace=${trace}`).then((data) => {
            // console.log(data);
            // console.log(data.content);
            // console.log(data.length);
            setPopup(data.content);
        });
        setTermsOpen(!termsOpen);
    };

    const headerRightFunc = () => {
        if (termsOpen) {
            setTermsOpen(false);
        } else {
            // navigate(routes.register);
            navigate(-1);
        }
    };

    return (
        <>
            <Header
                title={termsOpen ? termsTitle : "서비스 이용 동의"}
                rightXBtn={true}
                rightFunc={() => {
                    headerRightFunc();
                }}
            />

            {!termsOpen ? (
                <div className="register_box">
                    <p className="register_title">서비스 약관에 동의해주세요.</p>
                    {/* <p>{reg_type}  // {sns_user_email}</p> */}

                    <div className="terms_all_box">
                        <Check01 value={allchk} 
                            setFunc={() => {
                                if(allchk) {
                                    setAllchk(false);
                                    setChk01(false);
                                    setChk02(false);
                                    setChk03(false);
                                } else {
                                    setAllchk(true);
                                    setChk01(true);
                                    setChk02(true);
                                    setChk03(true);
                                }
                            }}
                        />

                        <div className="terms_all_text">
                            <p className="title">모두 동의합니다.</p>
                            <p className="content">
                                전체동의는 필수 및 선택정보에 대한 동의도 포함되어
                                있으며,개별적으로도 동의를 선택하실 수 있습니다. 선택항목에
                                대한동의를 거부하시는 경우에도 서비스 이용이 가능합니다.
                            </p>
                        </div>
                    </div>

                    <div className="terms_content_box">
                        <div className="terms_content" style={{ marginBottom: 10 }}>
                            <div className="terms_sub_content01">
                                <Check01
                                    value={chk01}
                                    setFunc={() => {
                                        setChk01(!chk01);
                                    }}
                                />
                                <p className={"chk_default chk_true"}>필수</p>
                            </div>

                            <div
                                className="terms_sub_content02"
                                onClick={() => termsFunc("policy")}
                            >
                                <p className="terms_content_title">서비스 이용약관</p>
                                <img
                                    className="right"
                                    src={require("../../assets/images/icons/right_btn_01.svg").default}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>

                        <div className="terms_content" style={{ marginBottom: 10 }}>
                            <div className="terms_sub_content01">
                                <Check01
                                    value={chk02}
                                    setFunc={() => {
                                        setChk02(!chk02);
                                    }}
                                />
                                <p className={"chk_default chk_true"}>필수</p>
                            </div>

                            <div
                                className="terms_sub_content02"
                                onClick={() => termsFunc("private")}
                            >
                                <p className="terms_content_title">
                                    개인정보 수집 및 이용 동의
                                </p>
                                <img
                                    className="right"
                                    src={require("../../assets/images/icons/right_btn_01.svg").default}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>

                        <div className="terms_content" style={{ marginBottom: 10 }}>
                            <div className="terms_sub_content01">
                                <Check01
                                    value={chk03}
                                    setFunc={() => {
                                        setChk03(!chk03);
                                    }}
                                />
                                <p className={"chk_default"}>선택</p>
                            </div>

                            <div
                                className="terms_sub_content02"
                                onClick={() => termsFunc("event_policy")}
                            >
                                <p className="terms_content_title">
                                    이벤트 및 마케팅 활용 동의
                                </p>
                                <img
                                    className="right"
                                    src={require("../../assets/images/icons/right_btn_01.svg").default}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </div>

                    <FooterBtn greyChk={!chk01 || (!chk02 && true)} setFunc={naviFunc} />
                </div>
            ) : (
                <TermsPopup content={popup} />
            )}
        </>
    );
}
