import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router';
import moment from 'moment';

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from '../../libs/routes';
// import consts from '../../libs/consts';
import { numFormat, patternNickFunc, regEmail, regPassword, patternSpcInstar, regPhone } from "../../utils/utils";

import { logIn, logOut, gpsUpdate, configData, setData, aroundData, menuClose, mainReset, searchClose, pickerClose } from '../../redux/usersSlice';
import { open, close } from '../../redux/popupSlice';
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from '../../redux/loadingSlice';

import Header from "../parts/Header";
import Popup01 from '../popup/Popup01';
import TermsPopup from '../popup/TermsPopup';

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";



export default function RegisterChkId(props) {

    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    console.log(state?.email);

    // 전문가인지, 일반인지 데이터가 없으면 보내버림
    // if(!state?.user_type) navigate(routes.register);

    
    useEffect(() => {
        
    }, []);
    
    const goLoginFunc = () => {
        navigate(routes.login);
    }


    return (
        <>
            <Header 
                rightXBtn={true}
                rightFunc={() => {
                    goLoginFunc();
                }}
            />
            <>
                <div className="flex_container">
                    <div className="register_box_title" style={{ marginBottom: 40 }}>
                        <p>이미 ProNote에</p>
                        <p>가입한 계정이 있습니다.</p>
                    </div>

                    <div className="register_email02" style={{ marginBottom: 20 }}>
                        {state.reg_type === 'K' &&
                            <img 
                                src={require("../../assets/images/icons/kakao.svg").default} 
                            />
                        }
                        {state.reg_type === 'N' &&
                            <img 
                                src={require("../../assets/images/icons/naver.svg").default} 
                            />
                        }
                        {state.reg_type === 'G' &&
                            <img 
                                src={require("../../assets/images/icons/google.svg").default} 
                            />
                        }
                        {state.reg_type === 'A' &&
                            <img 
                                src={require("../../assets/images/icons/apple.svg").default} 
                            />
                        }
                        <p style={{ marginTop: 12 }}>{state?.email}</p>
                    </div>
                        
                    <div className="register_box02" >
                        <div className="chk_email_num" style={{ gap: 4 }}>
                            
                            
                            <Button01 
                                label={'로그인 하러 가기'}
                                setFunc={() => {
                                    goLoginFunc();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}
