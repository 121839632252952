import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { loadPaymentWidget, clearPaymentWidget, ANONYMOUS } from '@tosspayments/payment-widget-sdk'

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Popup01 from "../popup/Popup01";

import Header from "../parts/Header";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import { kakaoPostData } from "../../utils/service/APIS";

// import InputSelectChk from "../../components/InputSelectChk";
// import BoardCardFeed from "../../components/BoardCardFeed";
// import BoardCardShadow from "../../components/BoardCardShadow";
// import ListLoading from "../../components/ListLoading";

export default function MemberShipSuc() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const code = new URL(window.location.href).searchParams.get("code"); // 에러 코드입니다. SDK 에러가 돌아옵니다. failUrl로 전달되는 에러, 공통 SDK 에러, 결제위젯 SDK 에러가 모두 포함됩니다.
    const message = new URL(window.location.href).searchParams.get("message"); // 에러 메시지입니다.
    const orderId = new URL(window.location.href).searchParams.get("orderId"); // 주문 ID입니다. 결제 요청에 담아 보낸 값입니다.
   
    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => { navigate(routes.mypage) }}
                title={"멤버십"}
            />

            <div className="suc_container">
                <img
                    src={require("../../assets/images/icons/pro_note_n_icon.svg").default}
                    style={{ width: 125, marginBottom: 40 }}
                />

                <p className="suc_title02">결제에 실패하였습니다.</p>
                <Button01
                    label={"마이페이지로 이동"}
                    setFunc={() => {
                        navigate(routes.mypage);
                    }}
                />
            </div>

        </>
    );
}
