import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import "moment/locale/ko";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";


export default function UserResultList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const token = state.accessToken;
    const tr_date = location?.state?.tr_date;
    const member_id = location?.state?.member_id;

    const [date, setDate] = useState(tr_date ? tr_date : new Date());
    const [list, setList] = useState([{}, {}]);

    // const userid = location.state ? location.state.user_id : "hi";

    useEffect(() => {
        loadData();
        console.log('=========> ',date);
    }, [date]);

    const loadData = () => {

        dispatch(loadingStart());

        const sender = {
            type: "E", // P=경과, E=평가
        };

        postData(`${consts.tem_register_list}${token}`, sender).then((res) => {
            // console.log('????1111 =>> ', res);

            if (res.result === "success") setList(res?.data);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const deleteFunc = (i, stime, etime) => {
        
    };

    const prevFunc = () => {
        setDate(moment(date).subtract(1, "days"));
    };

    const nextFunc = () => {
        setDate(moment(date).add(1, "days"));
    };

    const naviFunc = (data) => {
        navigate(routes.userResultDetail, { state: { result_data: data, member_id: member_id, result_date: new Date(date) } });
    };

    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"평가기록 등록"}
            />
            <div className="home_training_edit_box">
                <div className="home_training_date" style={{ marginBottom: 40 }}>
                    <img
                        src={require("../../assets/images/icons/prev_icon.svg").default}
                        onClick={() => {
                            prevFunc();
                        }}
                    />
                    <p>{moment(date).format("M.D(dd)")}</p>
                    <img
                        src={require("../../assets/images/icons/next_icon.svg").default}
                        onClick={() => {
                            nextFunc();
                        }}
                    />
                </div>

                <div className="result_list_box">
                    <p className="result_list_box_title">작성할 기록지를 선택하세요.</p>
                    <div>
                        <div>
                            {list?.length > 0 && list?.map((x, i) => {
                                // console.log(x);
                                // 1:무료(일반) // 2:유료(멤버십)
                                return(
                                    <div key={i} className="tem_content_box" onClick={() => { naviFunc(x) }}>
                                        <p className="tem_title">{x?.title}({x?.week}주)</p>
                                        {x?.membership_type === '2' && <p className="mem_ship">멤버쉽</p>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}
