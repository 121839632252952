import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import * as APIS from "../../utils/service";
import {
    numFormat,
    regEmail,
    regPassword,
    getYoilText,
    calcBirth,
} from "../../utils/utils";
import routes from "../../libs/routes";
import consts from "../../libs/consts";

import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { myScType, myPageListType } from '../../redux/mypageSlice';
import { postData } from "../../utils/service/APIS";
import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import SelectBox from "../../components/SelectBox";
import ChartLine from "../../components/ChartLine";
import ChartBar from "../../components/ChartBar";


export default function UserDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { mbData } = useSelector((s) => s.usersReducer);
    const { listType } = useSelector(s => s.mypageReducer);

    let state = useSelector((state) => 
        state.usersReducer
    );

    // console.log(location);

    const token = state.accessToken;
    const cus_email = location?.state?.cus_email; // 고객정보
    const view_type = location?.state?.view_type; // 통계에서 넘어온 경우
    const sp_data = location?.state?.sp_data; // 회원이 캘린더에서 전문가를 눌러서 넘어올때 가져오는 데이터

    const [content, setContent] = useState(listType ? listType : 1);
    const [data, setData] = useState({});
    const [scList, setScList] = useState([]); // 스케줄 리스트
    const [ingList, setIngList] = useState([]); // 경과기록 리스트
    const [trList, setTrList] = useState([]); // 홈트레이닝 리스트
    const [resultList, setResultList] = useState([]); // 평가기록 리스트
    const [selIndex, setSelIndex] = useState(0);
    const [purpose, setPurpose] = useState('');
    const [chartType, setChartType] = useState(1); // 1=선, 2=막대
    const [userViewChk, setUserViewChk] = useState(); // 고객노출여부 체크
    const [chartData, setChartData] = useState([]);
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        dispatch(close());
        loadData();
    }, []);

    useEffect(() => {
        if (content === 1) { // 일정
            getDataFunc(1);
        } else if (content === 2) { // 기록
            getDataFunc(2);
        } else if (content === 3) { // 평가
            getDataFunc(3);
        } else if (content === 4) { // 숙제
            getDataFunc(4);
        }
    }, [content]);

    useEffect(() => {
        if(view_type === 'chart') getChartData();
    }, [resultList, selIndex]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    const loadData = () => {

        let sender = {
            type: mbData?.type,
            member_id: mbData?.type === 'C' ? sp_data?.special_id : cus_email,
        };

        dispatch(loadingStart());

        postData(`${consts.sc_matching_member_info}${token}`, sender).then((res) => {
            // console.log('????1111 =>> ', res);
            // return;
            if (res.result === "success") setData(res);
            if (res.result === "error") {
                dispatch(
                    open({
                        component: (
                            <Popup01
                                width={'100%'}
                                text={res.message}
                                button={"확인"}
                                noneLoading={true}
                            />
                        ),
                    })
                );
                navigate(routes.memberList);
            };

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });


        postData(`${consts.my_health_view}${token}`, sender).then((res) => {
            setPurpose(res?.purpose);
        }).catch((e) => {
            console.log(e);
        });
        
    };

    const getDataFunc = (valType) => {

        let url = `/schedule.php?trace=schedule_view&token=${token}`; // 스케줄 리스트
        if (valType === 2 || valType === 3 || view_type === 'chart') url = `${consts.tem_register_template_mem_list}${token}`; // 기록 템플릿 리스트
        if (valType === 4) url = `${consts.my_training_list}${token}`; // 홈트레이닝리스트

        let sender = {
            type: mbData?.type, // M:전문가, C:고객
            member_id: mbData?.type === 'C' ? sp_data?.special_id : cus_email,
        };

        if(valType === 2 || valType === 3 || view_type === 'chart') {
            sender.page = "1" // 현재 페이지수
            sender.num_per_page = "10" // 한페이지 리스트 갯수
        }

        // P:경과, E:평가
        if(valType === 2) sender.reg_type = "P";
        if(valType === 3 || view_type === 'chart') sender.reg_type = "E" ;

        // console.log('sen ==> ', sender);

        postData(url, sender).then((res) => {
            console.log('?? ==> ', res);

            if(res.result === "success" && res?.data?.length > 0) {
                if(valType === 1) setScList(res.data);
                if(valType === 2) setIngList(res.data);
                if(valType === 3 || view_type === 'chart') setResultList(res.data);
                if(valType === 4) setTrList(res.data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            if (valType === 1) setScList([]);
            if (valType === 2) setIngList([]);
            if (valType === 3) setTrList([]);
            if (valType === 4) setResultList([]);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }

    const getChartData = () => {

        // console.log('selIndex=> ', selIndex);
        // console.log('가져올 그래프 데이터 ==> ', resultList[selIndex]);

        let sender = {
            register_idx: resultList[selIndex]?.register_idx,
            register_template_idx: resultList[selIndex]?.register_template_idx,
            type: mbData?.type, // M:전문가 C:고객
            member_id: mbData?.type === 'M' ? cus_email : sp_data?.special_id
        }

        console.log('111111111111111111', sender);
        
        dispatch(loadingStart());

        postData(consts.tem_statistics_graph + token, sender).then((res) => {
            console.log('그래프 데이터 ==> ', res.chk_data);

            if(res?.result === "success") {
                setChartData(res?.data);
                setUserViewChk(res?.chk_data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {

        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

    }

    const chartViewFunc = () => {
        let sender = {
            register_idx: resultList[selIndex]?.register_idx,
            register_template_idx: resultList[selIndex]?.register_template_idx,
            member_id: cus_email,
            show_yn: userViewChk === 'Y' ? 'N' : 'Y' // Y: '노출', N: '미노출'
        }

        // setUserViewChk(!userViewChk);
        console.log(sender);

        dispatch(loadingStart());

        postData(consts.tem_template_answer_show + token, sender).then((res) => {
            // console.log('그래프 데이터 ==> ', res);
            if(res?.result === "success") setUserViewChk(res?.chk_data);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {

        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

        
    }

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = () => {
        if (content === 1) {
            navigate(routes.userSchedule, { state: { cus_email: cus_email } });
        } else if (content === 2) {
            navigate(routes.userTemplateList, { state: { member_id: cus_email, reg_type: 'P' } }); // 경과
        } else if (content === 3) {
            navigate(routes.userTemplateList, { state: { member_id: cus_email, reg_type: 'E' } }); // 평가
        } else if (content === 4) {
            navigate(routes.userTraining, { state: { member_id: cus_email } });
        }
    };

    const returnFunc = (title) => {
        return (
            <div className="not_list_box">
                <img
                    src={require("../../assets/images/icons/notResult.svg").default}
                />
                <p>{title}</p>
            </div>
        )
    }


    const resultDeletePop = (val) => {
        dispatch(open({
            component: <Popup01 
                            width={380}
                            text={'평가를 삭제하면 답변 기록도 삭제됩니다\n삭제 하시겠습니까?'}
                            buttonCancel={'아니요'}
                            button={'확인'}
                            setFunc={() => { resultDelete(val) }}
                            noneLoading={true}
                        />,
        }));

    }

    const resultDelete = (val) => {
        let sender = {
            type: mbData?.type, // M:전문가, C:고객
            idx: val?.register_template_idx,
            member_id: cus_email
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_register_template_mem_del}${token}`, sender).then((res) => {
            // console.log('tetetetetresult =>> ', res);

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res?.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            if(mbData?.type === 'M') {
                getDataFunc(3);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
        });
    }


    return (
        <>
            {data?.member_name ?
                <>
                    <Header
                        leftBtn={true}
                        leftFunc={() => {
                            headerLeftFunc();
                        }}
                        title={"고객정보"}
                        backgorundColor={"#EDE7FF"}
                    />
                    <div style={{ paddingBottom: 144 }}>
                        <div className="user_detail_info_box">
                            {data?.file_name?.replace(consts.upload_url_member, "") ?
                                <img 
                                    src={consts.base_url + data?.file_name} 
                                    style={{ width: 62, height: 62, marginRight: 20, borderRadius: '50%', objectFit: 'cover' }}
                                    onClick={() => {
                                        {mbData?.type === 'M' ? 
                                            navigate(routes.userProfile, { state: { userid: data?.member_id }})
                                            :
                                            navigate(routes.profilePreview, { state: { userid: data?.member_id }});
                                        }
                                    }}
                                />
                                :
                                <img 
                                    src={require("../../assets/images/icons/avatar.svg").default} 
                                    style={{ width: 62, height: 62, marginRight: 20, borderRadius: '50%', objectFit: 'cover' }}
                                    onClick={() => {
                                        {mbData?.type === 'M' ? 
                                            navigate(routes.userProfile, { state: { userid: data?.member_id }})
                                            :
                                            navigate(routes.profilePreview, { state: { userid: data?.member_id }});
                                        }
                                    }}
                                />
                            }
                            <div className="user_detail_info">
                                <p className="name">{data.member_name}</p>
                                {mbData?.type === 'M' ? 
                                    <div className="user_detail_sub_info">
                                        <p className={'border_use'}>{data.member_birth ? calcBirth(data.member_birth) : data.birth}세</p>
                                        <p className={'border_use'} style={{ paddingLeft: 8 }}>{data.member_gender === "M" ? "남" : "여"}</p>
                                        <p className={'border_use'} style={{ paddingLeft: 8 }}>{data.member_height}cm</p>
                                        <p style={{ paddingLeft: 8 }}>{data.member_weight}kg</p>
                                    </div>
                                    :
                                    <div className="user_detail_sub_info">
                                        <p className={'border_use'}>전문가</p>
                                        <p style={{ paddingLeft: 8 }}>{data.special_type}</p>
                                    </div>
                                }
                                <p className="user_detail_info_target">{purpose}</p>
                            </div>
                        </div>
                        
                        {view_type === 'chart' ?
                            <>
                                <div className="user_detail_info_box02">
                                    <div className="user_content_box">

                                        <SelectBox
                                            data={
                                                resultList?.map((x, i) => {
                                                    return x.title;
                                                })
                                            }
                                            index={selIndex}
                                            setIndex={(index) => { 
                                                console.log(index);
                                                setSelIndex(index);
                                            }}
                                            defaultText={'데이터가 없습니다.'}
                                        />
                                        

                                        <div className="chart_container">
                                            <p className="chart_container_title">{resultList[selIndex]?.title}</p>
                                            <div className="chart_type_box">
                                                <p onClick={() => { setChartType(1) }} className={chartType === 1 && "active_chart_type"}>선</p>
                                                <p onClick={() => { setChartType(2) }} className={chartType === 2 && "active_chart_type"}>막대</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ backgroundColor: '#fff' }}>
                                        {chartType === 1 ? 
                                            <ChartLine 
                                                Width={'100%'}
                                                Height={300}
                                                chartData={chartData}
                                            />
                                            :
                                            <ChartBar 
                                                Width={'100%'}
                                                Height={300}
                                                chartData={chartData}
                                            />
                                        }
                                    </div>
                                </div>

                                {(chartData?.length > 0 && mbData?.type == 'M') &&
                                    <div className="user_view_chk_box">
                                        <p>고객노출여부</p>
                                        <div 
                                            className="toogle_btn_box" 
                                            onClick={() => {
                                                chartViewFunc();
                                            }}
                                        >
                                            <div className={userViewChk === 'Y' ? 'toggle_round_box_active' : 'toggle_round_box'}/>
                                            <div className={`toggle_circle ${userViewChk === 'Y' ? "toggle_circle_checked" : ''}`}/>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <div className="user_content_container">
                                    <div className="user_content_box">
                                        <div className="user_content_list">
                                            <p
                                                className={content === 1 && "active"}
                                                onClick={() => {
                                                    setContent(1);
                                                    dispatch(myPageListType(1));
                                                }}
                                            >
                                                일정
                                            </p>
                                            <p
                                                className={content === 2 && "active"}
                                                onClick={() => {
                                                    setContent(2);
                                                    dispatch(myPageListType(2));
                                                }}
                                            >
                                                기록
                                            </p>
                                            <p
                                                className={content === 3 && "active"}
                                                onClick={() => {
                                                    setContent(3);
                                                    dispatch(myPageListType(3));
                                                }}
                                            >
                                                평가
                                            </p>
                                            <p
                                                className={content === 4 && "active"}
                                                onClick={() => {
                                                    setContent(4);
                                                    dispatch(myPageListType(4));
                                                }}
                                            >
                                                숙제
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="user_detail_info_box02">
                                    {content === 1 && ( // 스케쥴
                                        <div className="day_line_content_list" style={{ height: isBrowser ? 550 : '100%' }}>
                                            {scList && scList?.length > 0 && scList?.map((x, i) => {
                                                return (
                                                    <div key={i}>
                                                        {x?.sc_stime && x?.sc_etime &&
                                                            <div className="day_content_box">
                                                                <p className="yoil">{x?.week_day}</p>
                                                                <p className="time">{x?.sc_stime} ~ {x?.sc_etime}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                )

                                            })}

                                            {(!scList || scList?.length === 0) && returnFunc('스케줄이 없습니다.')}
                                        </div>
                                    )}

                                    {content === 2 && ( // 경과기록
                                        <div className="day_line_content_list" style={{ height: isBrowser ? 550 : '100%' }}>
                                            {ingList?.map((x, i) => {
                                                // console.log('경과 => ', x);
                                                return (
                                                    <div 
                                                        key={i}
                                                        className="ing_record_box" 
                                                        
                                                    >
                                                        <p className="record_title">{x?.title}({x?.week}주)</p>
                                                        <p className="record_week_title">{x?.now_week ? x?.now_week : 1}주차 진행중</p>
                                                        <div className="record_status_btn_box">
                                                            <div 
                                                                className="record_status_box"
                                                                style={{ 
                                                                    width: mbData?.type === 'M' ? '50%' : '100%',
                                                                }}
                                                                onClick={() => { 
                                                                    navigate(routes.userTemplateWeek, { 
                                                                        state: { 
                                                                            tem_data: x, 
                                                                            member_id: cus_email,  
                                                                        } 
                                                                    });
                                                                }}
                                                            >
                                                                <p>{screenWidth > 340 ? '경과기록 보기' : '기록보기'}</p>
                                                            </div>
                                                            {mbData?.type === 'M' && 
                                                                <div 
                                                                    className="record_status_box"
                                                                    style={{ 
                                                                        width: mbData?.type === 'M' ? '50%' : '100%',
                                                                    }}
                                                                    onClick={() => { 
                                                                        navigate(routes.userTemplateWeekDetail, { 
                                                                            state: { 
                                                                                tem_data: x,
                                                                                member_id: cus_email,
                                                                                reg_type: 'new'
                                                                            } 
                                                                        });
                                                                    }}
                                                                >
                                                                    <img src={require("../../assets/images/icons/plus_symbols_add.svg").default} /> 
                                                                    <p>{screenWidth > 340 ? '경과기록 추가' : '기록추가'}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {(!ingList || ingList?.length === 0) && returnFunc('경과기록이 없습니다.')}
                                        </div>
                                    )}

                                    {content === 3 && ( // 평가기록
                                        <>
                                            <div className="day_line_content_list" style={{ height: isBrowser ? 550 : '100%' }}>
                                                {resultList?.map((x, i) => {
                                                    return (
                                                        <div key={i} className="ing_record_box">
                                                            <div className="record_title_box">
                                                                <p className="title">{x?.title}</p>
                                                                {mbData?.type === 'M' && 
                                                                    <p className="del_btn"
                                                                        onClick={() => { resultDeletePop(x) }}
                                                                    >삭제</p>
                                                                }
                                                            </div>

                                                            {
                                                                (x?.reg_date_list && x?.reg_date_list?.length > 0) && 
                                                                x?.reg_date_list?.map((v, n) => {
                                                                    return(
                                                                        <div className="record_list_box" style={{ marginBottom: 15 }}>
                                                                            <p className="record_week_title02">
                                                                                {x?.reg_date_list?.length-n}차 {`(${moment(v).format('YYYY.MM.DD')})`}
                                                                            </p>
                                                                            <p className="record_click_btn"
                                                                                onClick={() => {
                                                                                    navigate(routes.userResultDetailEx,  { 
                                                                                        state: { 
                                                                                            result_data: x, 
                                                                                            result_date: v, 
                                                                                            member_id: cus_email,
                                                                                            chkUse: mbData.type === 'C' ? true : false
                                                                                        } 
                                                                                    });
                                                                                }}
                                                                            >
                                                                                보기
                                                                                {mbData?.type === 'M' && `(수정)`}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {mbData?.type === 'M' && 
                                                                <div 
                                                                    className="record_status_box"
                                                                    style={{ 
                                                                        width: '100%',
                                                                        marginTop: x?.reg_date_list?.length === 0 ? 25 : 20 
                                                                    }}
                                                                    onClick={() => { 
                                                                        navigate(routes.userResultDetailEx,  { 
                                                                            state: { 
                                                                                result_data: x, 
                                                                                result_date: moment().format('YYYY-MM-DD'), 
                                                                                member_id: cus_email,
                                                                            } 
                                                                        });
                                                                    }}
                                                                >
                                                                    <img src={require("../../assets/images/icons/plus_symbols_add.svg").default} /> 
                                                                    <p>평가 추가</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}

                                                {(!resultList || resultList?.length === 0) && returnFunc('평가가 없습니다.')}
                                            </div>
                                        </>
                                    )}

                                    {content === 4 && ( // 홈트레이닝
                                        <div style={{ height: isBrowser ? 550 : '100%' }}>
                                            {trList && trList?.length > 0 && trList.map((x, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        className="day_line_content_list"
                                                        onClick={() => {
                                                            navigate(routes.userTraining, {
                                                                state: { 
                                                                    tr_date: x?.ht_date, 
                                                                    member_id: mbData?.type === 'M' ? cus_email : mbData?.userid
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <div className="home_training_title">
                                                            <img
                                                                src={require("../../assets/images/icons/calendar_icon02.svg").default}
                                                                style={{ width: 24 }}
                                                            />
                                                            <p>{moment(x?.ht_date).format("M.D(dd)")}{ }</p>
                                                        </div>
                                                        <div className="home_training_content" style={{ whiteSpace: 'pre-line' }}>
                                                            <p>{x.ht_content}</p>
                                                        </div>
                                                        <div className="border_bottom_solid" />
                                                    </div>
                                                );
                                            })}

                                            {(!trList || trList?.length === 0) &&
                                                <div style={{ paddingTop: 20, backgroundColor: '#fff' }}>
                                                    {returnFunc('숙제가 없습니다.')}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>


                                {mbData?.type === 'M' && 
                                    <div className="display_flex_relative">
                                        <Button02
                                            iconType={content === 1 ? "edit" : content === 2 || content === 3 ? "plus" : "plus"}
                                            label={
                                                content === 1
                                                    ? "일정 편집"
                                                    : content === 2
                                                        ? "경과기록 양식 등록"
                                                        : content === 3
                                                            ? "평가기록 등록"
                                                            : "숙제 등록"
                                            }
                                            setFunc={naviFunc}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </>
                :
                <></>
            }
        </>
    );
}
