import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import moment from "moment";

import { ResponsiveLine } from '@nivo/line'


export default function ChartLine(props) {

    const {
        Width,
        Height,
        chartData
    } = props;

    const [listData, setListData] = useState([]);

    useEffect(() => {
        let list = [
            {
                id: "text",
                color: "#AA8CFF",
                data: []
            }
        ]

        if(chartData?.length > 0 ) {
            list[0].data = chartData?.map((x, i) => {
                return {x: x?.cnt+'회', y: x?.tot}
            })
        }

        list[0]?.data?.unshift({ x: '0회', y: 0 });

        setListData(list);
    }, [chartData]);

    // useEffect(() => {
    //     console.log('result -=> ', listData);
    // }, [listData]);
    // console.log(chartData);


    return (
        <div className="chart_line_box" style={{ width: Width, height: Height }}>
            {chartData?.length > 0 ?
                <ResponsiveLine
                    data={listData}
                    margin={{ top: 10, right: 30, bottom: 50, left: 50 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false
                    }}
                    colors={['#AA8CFF']}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    pointSize={10}
                    pointColor={'#AA8CFF'}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    axisBottom={{
                        legendOffset: 40
                    }}
                    axisLeft={{
                        format: e => Math.floor(e) === e ? e : ''
                    }}
                />
                :
                <div className="not_list_box" style={{ paddingTop: 40 }}>
                    <img src={require("../assets/images/icons/notResult.svg").default}/>
                    <p>데이터가 없습니다.</p>
                </div>
            }
        </div>
    );
}
