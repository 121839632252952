import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import "../../assets/css/calendar.css";

import routes from "../libs/routes";
import consts from "../libs/consts";

import Hammer from "hammerjs";

import "moment/locale/ko";
import moment, { months } from "moment";
import Calendar from "react-calendar";

import { getData, postData } from "../utils/service/APIS";
import { loadingStart, loadingEnd } from "../redux/loadingSlice";
import { myScType, myPageListType } from '../redux/mypageSlice';
import Icon from "./Icon";


const CalendarBox = (props) => {
    const { 
        value, 
        clickDate, 
        cusSchedule 
    } = props;
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {mbData, accessToken} = useSelector(s => s.usersReducer);

    const boxRef = useRef(null);

    const [scheduleData, setScheduleData] = useState([]);
    const [cusScheduleData, setCusScheduleData] = useState(cusSchedule ? cusSchedule : []);
    const [isListExpanded, setIsListExpanded] = useState(false);
    const [firstHeight, setFirstHeight] = useState();

    useEffect(() => {
        // console.log('=> ', boxRef.current.offsetHeight);
        setFirstHeight(boxRef?.current?.offsetHeight);
    }, []);

    useEffect(() => {
        swipeCol();
    }, []);

    useEffect(() => {
        
        if(clickDate) {
            loadScheduleData(moment(clickDate).format("YYYY-MM-DD"));
            if(mbData.type === 'C'){
                let day = ''
                if(moment(clickDate).day() === 1) day = '0'
                if(moment(clickDate).day() === 2) day = '1'
                if(moment(clickDate).day() === 3) day = '2'
                if(moment(clickDate).day() === 4) day = '3'
                if(moment(clickDate).day() === 5) day = '4'
                if(moment(clickDate).day() === 6) day = '5'
                if(moment(clickDate).day() === 0) day = '6'
                
                setCusScheduleData(
                    cusSchedule?.filter((x, i) => 
                        (!x?.week_day && x?.sc_date == clickDate) 
                        ||
                        (x?.week_day && x?.week_day === day)
                    )
                );
            }
        }
    }, [clickDate, cusSchedule]);

    const loadScheduleData = (sc_dateYmd) => {
        let sender = {
            type: mbData?.type,
            sc_dateYmd: sc_dateYmd,
        };

        // console.log(sender);
        
        postData(`${consts.sc_schedule_day}${accessToken}`, sender).then((res) => {
            console.log('=======> ', res);
            // console.log(clickDate);
            if (res?.result === "success") {
                setScheduleData(res?.data?.filter((x, i) => x.sc_stime != '' && x.sc_etime && x.sc_date <= clickDate));
            }
        }).catch((e) => {
            console.log(e)
        });
    };

    const swipeCol = () => {
        const button = document.querySelector(".swipe-button_box");
        const manager = new Hammer.Manager(button);
        const Swipe = new Hammer.Swipe();

        manager.add(Swipe);

        manager.on("swipeup", function (e) {
            setIsListExpanded(true);
        });

        manager.on("swipedown", function (e) {
            // if(boxRef?.current?.offsetHeight < ((firstHeight*1) + 50) && boxRef?.current?.offsetHeight > ((firstHeight*1) - 50)) setClickVal();
            setIsListExpanded(false);
        });
    };


    const cusBox = (x, type) => {
        // console.log(x);
        return (
            <div 
                className="cus_sc_box" 
                onClick={() => { 
                    if(type === 'sp') {
                        navigate(routes.userDetail, { state: { sp_data: x } }); 
                    }
                }}
            >
                <div className={type === 'sp' ? "cus_sc_box03" : "cus_sc_box02"}>
                    <img src={require("../assets/images/icons/time-line.svg").default} style={{ marginRight: 8 }} />
                    <p className="sc_time_text" style={{ marginRight: 8 }}>{x?.sc_stime ? x?.sc_stime : x?.sc_time}</p>
                    <p className="sc_time_text02">{x?.special_type ? consts?.specialType[(x?.special_type*1)] : x?.sc_content}</p>
                </div>
                {x?.name &&
                    <p className="sc_spe_name">{x?.name} 선생님</p>
                }
            </div>
        )
    }

    return (
        <div 
            className={"box " + (isListExpanded ? "swipe-box" : clickDate ? "" : "swipe-box02")} 
            ref={boxRef}
            style={{ width: isBrowser ? 390 : '100%', }}
        >
            <div className="swipe-button_box">
                <div className="swipe-button">
                    <div></div>
                </div>
                <div className="box-date">
                    <Icon icon={"calendar-check-line.svg"} />
                    <div id={value}>{moment(value).format("M.D(dd)")}</div>
                </div>
            </div>
            <div className="box-content">

                {(mbData?.type === 'C' &&  scheduleData?.length > 0) && 
                    <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 8 }}>전문가와의 스케줄</p>
                }

                {scheduleData?.length > 0 && scheduleData?.map((x, i) => {
                    return(
                        <div key={i}>
                            {mbData?.type === 'C' ? 
                                cusBox(x, 'sp')
                                :
                                x.sc_stime && x.sc_etime &&
                                <div className="box-schedule">
                                    <div className="box-time">{`${x.sc_stime} ~ ${x.sc_etime}`}</div>
                                    <div 
                                        className="box-member" 
                                        onClick={() => { 
                                            dispatch(myPageListType(1));
                                            navigate(routes.userDetail, { state: { cus_id: x?.sc_idx, cus_email: x?.member_id } }) 
                                        }}
                                    >
                                        {x?.name && x?.name}
                                        {x?.birth && '(' + x?.birth + '세,'}
                                        {x?.gender && x?.gender + ')'}
                                        {/* {`${x.name}(${x.birth}세, ${x.gender})`} */}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}

                {cusScheduleData?.length > 0 && 
                    <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 8, marginTop: scheduleData?.length > 0 ? 20 : 0 }}>내 스케줄</p>
                }

                {cusScheduleData?.length > 0 && cusScheduleData?.map((x, i) => {
                    // console.log('??????? => ', x);
                    return(
                        <div key={i} onClick={() => { navigate(routes.scheduleInput, { state: { scData: x } }) }}>
                            {cusBox(x)}
                        </div>
                    )
                })}

                {scheduleData?.length === 0 && cusScheduleData?.length === 0 &&
                    <div className="not-found-schedule">
                        <Icon icon={"not-found-schedule.svg"} />
                        <div>
                            {mbData?.type === "M"
                                ? "등록된 스케줄이 없습니다.\r\n고객과 매칭후 고객관리에서\r\n스케줄을 등록하실 수 있습니다."
                                : "등록된 스케줄이 없습니다."}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CalendarBox;
