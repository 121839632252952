import axios from "axios";
import consts from "../../libs/consts";

export async function getData(url = "") {
    // Default options are marked with *
    let ori_dm = 'https://api.pronoteweb.net/flutter';
    const response = await fetch(`${ori_dm}${url}`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: data?.data, // body data type must match "Content-Type" header
    });
    return response.ok ? response.json() : console.error(); // parses JSON response into native JavaScript objects
}


export async function postData(url = "", data = {}) {
    // console.log(JSON.stringify(data));
    // Default options are marked with *

    // let ori_dm = '/flutter';
    // let ori_dm = 'https://api.pronoteweb.net';
    let ori_dm = 'https://api.pronoteweb.net/flutter';

    const response = await fetch(`${ori_dm}${url}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    console.log('response ==> ', response?.url);
    return response?.ok ? response.json() : console.error(); // parses JSON response into native JavaScript objects
}

export const kakaoPostData = async (code) => {
    let restApiKey = consts.kakaoRestApiKey;
    let data = {
        grant_type: 'authorization_code',
        client_id: restApiKey,
        code
    }
    let header = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Authorization': 'Bearer '
    };

    let queryString = Object.keys(data).map(k=>encodeURIComponent(k)+'='+encodeURIComponent(data[k])).join('&')
    let kakaoToken = await axios.post("https://kauth.kakao.com/oauth/token", queryString, {headers: header});
    
    // 엑세스 토큰 헤더에 담기
    header.Authorization += kakaoToken?.data?.access_token
    
    // 카카오 사용자 정보 조회
    let getdata = await axios.get("https://kapi.kakao.com/v2/user/me", { headers: header })
    let result = getdata.data;
    // console.log(result);

    return result;
};


// export const googleData = async (accessToken) => {
//     let header = {
//         'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
//         'Authorization': 'Bearer '
//     };
//     let url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`;
//     let result = await axios.get(url, { headers: header });
//     // console.log(result?.data?.email);
//     if(result?.data?.email) return result?.data?.email;
// }




export async function postImageData(url = "", data = {}) {
    console.log(data);
    // Default options are marked with *
    const response = await fetch(`/flutter${url}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            // "Content-Type": "multipart/form-data",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data, // body data type must match "Content-Type" header
    });
    return response.ok ? response.json() : console.error(); // parses JSON response into native JavaScript objects
}
