import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserTemplateDetailEx() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);
    
    const [dataList, setDataList] = useState([]);

    const token = state?.accessToken;
    const tem_id = location?.state?.id
    const tem_title = location?.state?.tem_title;
    // const tem_data = location?.state?.tem_data;
    // const member_id = location?.state?.member_id;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(loadingStart());

        const sender = {
            type: "P",
            register_idx: tem_id,
            week: "1",
            cnt: "1"
        };
        
        postData(`${consts.tem_template_view}${token}`, sender).then((res) => {
            console.log('result =>> ', res?.data);
            setDataList(res?.data);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = () => {
        // navigate(routes.userTemplateDetailEx);
    }

    const optionBoxType_1 = (val, index) => {
        return (
            <div className="answer_box">
                <>
                    <p className="answer_box_title" style={{ marginBottom: 20 }}>{index + 1}. {val?.title}</p>

                    {val?.img?.replace(consts.upload_url, "") && 
                        <img className="answer_box_img" src={"https://www.pronoteweb.net" + val?.img } />
                    }
                    <div className="answer_box_content">
                        <div className="answer_content">
                            {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                                <>
                                    {val?.goal[0]?.type === '3' ?
                                        <>
                                            {(val?.answer_limit_title)?
                                                <p className="answer_content_goal">
                                                    목표치: {val?.answer_limit_title}회
                                                </p>
                                                :
                                                <p 
                                                    className="answer_goal_title" 
                                                    style={{ marginBottom: 12 }}
                                                >
                                                    목표치를 입력해주세요.
                                                </p>
                                            }
                                        </>
                                        :
                                        <>
                                            {(val?.goal[0]?.title)?
                                                <p className="answer_content_goal">
                                                    목표치: {val?.goal[0]?.title}회
                                                </p>
                                                :
                                                <p 
                                                    className="answer_goal_title" 
                                                    style={{ marginBottom: 12 }}
                                                >
                                                    목표치를 입력해주세요.
                                                </p>
                                            }
                                        </>
                                    }
                                </>
                            }
                            
                        </div>
                    </div>
                </>
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    const optionBoxType_1_sub = (val, num, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                <div className="answer_box_content">
                    <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                    <div className="answer_content">
                        <p className="answer_content_title" style={{ fontSize: 12 }}>{val?.title}</p>
                        {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                            <>
                                {val?.goal[0]?.type === '3' ?
                                    <>
                                        {(val?.answer_limit_title)?
                                            // <p className="answer_content_goal">
                                            //     목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                                            // </p>
                                            <p className="answer_content_goal">
                                                목표치: {val?.answer_limit_title}회
                                            </p>
                                            :
                                            <p 
                                                className="answer_goal_title" 
                                                style={{ marginBottom: 12 }}
                                            >
                                                목표치를 입력해주세요.
                                            </p>
                                        }
                                    </>
                                    :
                                    <>
                                        {(val?.goal[0]?.title)?
                                            // <p className="answer_content_goal">
                                            //     목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                                            // </p>
                                            <p className="answer_content_goal">
                                                목표치: {val?.goal[0]?.title}회
                                            </p>
                                            :
                                            <p 
                                                className="answer_goal_title" 
                                                style={{ marginBottom: 12 }}
                                            >
                                                목표치를 입력해주세요.
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const optionBoxType_2 = (val, index) => {
        
        return (
            <div className="answer_box">
                <>
                    <p className="answer_box_title">{index + 1}. {val?.title}</p>
                    {val?.img?.replace(consts.upload_url, "") && 
                        <img className="answer_box_img" src={"https://www.pronoteweb.net" + val?.img } />
                    }
                    <div className="answer_box_content">
                        <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                        <div className="answer_content">
                            <p className="answer_content_title">{val?.title}</p>
                            {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                                <>
                                    {(val?.goal[0]?.title || val?.limit_title)?
                                        <p className="answer_content_goal">
                                            목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                                        </p>
                                        :
                                        <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                            목표치를 입력해주세요.
                                        </p>
                                    }
                                </>
                            }
                            <div>
                                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                                    return (
                                        <div key={i} className="answer_content_option">
                                            {val?.answer === (i+1).toString() ? 
                                                <img src={require("../../assets/images/icons/radio_on.svg").default} style={{ marginRight: 8 }} />
                                                :
                                                <img src={require("../../assets/images/icons/radio_off.svg").default} style={{ marginRight: 8 }} />
                                            }
                                            <p>{x?.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    const optionBoxType_2_sub = (val, num, index) => {
        // console.log('====> ', val?.goal[0]?.title);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                <div className="answer_box_content">
                    <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                    <div className="answer_content">
                        <p className="answer_content_title" style={{ fontSize: 12 }}>{val?.title}</p>

                        {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                            <>
                                {val?.goal[0]?.title || val?.limit_title ?
                                    <p className="answer_content_goal">
                                        목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                                    </p>
                                    :
                                    <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                        목표치를 입력해주세요.
                                    </p>
                                }
                            </>
                        }
                        <div>
                            {val?.options?.length > 0 && val?.options?.map((x, i) => {
                                return (
                                    <div key={i} className="answer_content_option">
                                        {val?.answer === (i+1).toString() ? 
                                            <img src={require("../../assets/images/icons/radio_on.svg").default} style={{ marginRight: 8 }} />
                                            :
                                            <img src={require("../../assets/images/icons/radio_off.svg").default} style={{ marginRight: 8 }} />
                                        }
                                        <p>{x?.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // 체크박스
    const optionBoxType_3 = (val, index) => {
        // console.log(val);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.title || val?.limit_title ?
                            <p className="answer_content_goal">
                                목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                            </p>
                            :
                            <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                목표치를 입력해주세요.
                            </p>
                        }
                    </>
                }
                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                    return (
                        <div key={i} className="answer_content_option02">
                            {val?.answer?.includes((i+1).toString()) ? 
                                <img src={require("../../assets/images/icons/check_on.svg").default} style={{ marginRight: 8 }} />
                                :
                                <img src={require("../../assets/images/icons/check_off.svg").default} style={{ marginRight: 8 }} />
                            }
                            <p>{x?.title}</p>
                        </div>
                    )
                })}
                {val?.depth?.length > 0 && <div style={{ height: 20 }}/>}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 체크박스 서브
    const optionBoxType_3_sub = (val, num, index) => {
        // console.log(val);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.title || val?.limit_title ?
                            <p className="answer_content_goal">
                                목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                            </p>
                            :
                            <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                목표치를 입력해주세요.
                            </p>
                        }
                    </>
                }
                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                    return (
                        <div key={i} className="answer_content_option02">
                            {val?.answer?.includes((i+1).toString()) ? 
                                <img src={require("../../assets/images/icons/check_on.svg").default} style={{ marginRight: 8 }} />
                                :
                                <img src={require("../../assets/images/icons/check_off.svg").default} style={{ marginRight: 8 }} />
                            }
                            <p>{x?.title}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    // 서술형
    const optionBoxType_4 = (val, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.title || val?.limit_title ?
                            <p className="answer_content_goal">
                                목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                            </p>
                            :
                            <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                목표치를 입력해주세요.
                            </p>
                        }
                    </>
                }
                <TextAreaEdit
                    Height={100}
                    placeholder={"내용을 입력해 주세요."}
                    value={val?.answer ? val?.answer : ''}
                    setValue={(text) => {}}
                    Disabled={true}
                />
                {val?.depth?.length > 0 && <div style={{ height: 20 }}/>}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 서술형 서브
    const optionBoxType_4_sub = (val, num, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.title || val?.limit_title ?
                            <p className="answer_content_goal">
                                목표치: {val?.limit_title ? val?.limit_title : val?.goal[0]?.title}회
                            </p>
                            :
                            <p className="answer_goal_title" style={{ marginBottom: 12 }}>
                                목표치를 입력해주세요.
                            </p>
                        }
                    </>
                }
                <TextAreaEdit
                    Height={100}
                    placeholder={"내용을 입력해 주세요."}
                    value={val?.answer ? val?.answer : ''}
                    setValue={(text) => {}}
                    Disabled={true}
                />
            </div>
        )
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={tem_title}
                centerWidth={'100%'}
                rightWidth={'17%'}
            />
            <div style={{ padding: 20 }}>
                {/* <div className="tem_detail_box">
                    <div className="tem_detail_content">
                        <p className="title">{temData?.title}</p>
                        {temData?.options?.map((x, i) => {
                            return (
                                <p key={i}>{x.title}</p>
                            )
                        })}
                    </div>
                </div> */}
                <div>

                    {dataList.length > 0 && dataList?.map((x, i) => {
                        console.log(x);
                        return (
                            <div key={i}>
                                {x?.type === '1' && optionBoxType_1(x, i)}
                                {x?.type === '2' && optionBoxType_2(x, i)}
                                {x?.type === '3' && optionBoxType_3(x, i)}
                                {x?.type === '4' && optionBoxType_4(x, i)}
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </>
    );
}
