import React, { useEffect, useState } from 'react';

// import { 
//     regPhone, 
//     regEmail, 
//     patternNum, 
//     patternPrice, 
//     patternEng, 
//     patternEngUpper, 
//     patternSpc, 
//     patternSpcId, 
//     patternSpcPw, 
//     patternSpcEmail, 
//     patternKor, 
//     numFormat 
// } from "../utils/utils";

export default function TextAreaEdit(props) {

    const { 
        label,
        value="", 
        setValue,
        placeholder, 
        maxlength,
        Height,
        error,
        Disabled,
    } = props;


    const handleChange = (e) => {
        setValue(e.target.value)
    };


    return (
        <>  
            <div className="edit_textArea_default_box">
                <p>{label}</p>
                <textarea 
                    value={value}
                    onChange={handleChange}
                    className="edit_textArea_default"
                    style={{ height: Height ? Height : 124 }}
                    placeholder={placeholder ? placeholder : '내용을 입력해 주세요.'}
                    maxLength={maxlength ? maxlength : 1000}
                    disabled={Disabled ? true : false}
                />
            </div>

            {error  &&
                <p className="input_error">{error}</p>
            }
        </>
    )    
}