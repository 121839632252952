import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import { logIn, logOut } from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

export default function TermsPopup(props) {
    const { content } = props;

    const dispatch = useDispatch();

    return (
        <>
            <div className="terms_popup_box" style={{ width: 390 }}>
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
        </>
    );
}
