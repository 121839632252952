import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import { postData } from "../../utils/service/APIS";
import { calcBirth } from "../../utils/utils";

export default function MemberSearch() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;

    const { banner, boardCate, searchList, mbData, recommnedList, issueList } =
        useSelector((s) => s.usersReducer);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [showResult, setShowResult] = useState(true);
    const [resultList, setResultList] = useState([]);
    const [error1, seterror1] = useState("");
    const [error2, seterror2] = useState("");


    const searchUserFunc = () => {
        seterror1("");
        seterror2("");

        if (!name) {
            seterror1("이름을 입력해 주세요.");
            return;
        }

        if (!phone) {
            seterror2("전화번호를 입력해 주세요.");
            return;
        }

        dispatch(loadingStart());

        const sender = {
            username: name,
            usertel: phone,
        };

        // console.log(sender);

        postData(`/schedule.php?trace=matching_search&token=${token}`, sender).then((res) => {

            console.log('res', res);

            if (res.result === "success" && res?.data?.length > 0) {
                // 검색 결과가 있으면
                setResultList(res.data);
                setShowResult(true);
            } else {
                // 없을시
                setShowResult(false);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const inviteUserFunc = (id) => {

        dispatch(loadingStart());

        const sender = {
            member_id: id,
        };

        postData(`${consts.sc_matching_invite}${token}`, sender).then((res) => {
            console.log(res);
            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={res.message}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });

        if (true) {
            // 초대 이벤트가 종료된 후
        }
    };

    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"고객 찾기"}
            />
            <div className="search_user_box">
                <div style={{ width: "100%", marginBottom: 8 }}>
                    <Input
                        width={"100%"}
                        placeholder={"이름"}
                        value={name}
                        setValue={setName}
                        maxLength={11}
                        error={error1}
                        onKeyPress={searchUserFunc}
                        subLabel={"등록하실 고객 정보를 입력하세요."}
                    />
                </div>

                <div style={{ width: "100%", marginBottom: 8 }}>
                    <Input
                        type={"number"}
                        width={"100%"}
                        placeholder={"전화번호"}
                        value={phone}
                        setValue={setPhone}
                        maxLength={11}
                        error={error2}
                        onKeyPress={searchUserFunc}
                    />
                </div>

                <Button01
                    width={"100%"}
                    label={"검색"}
                    setFunc={() => searchUserFunc()}
                />
            </div>

            {!showResult && (
                <div className="not_search_list">
                    <img
                        src={require("../../assets/images/icons/notResult.svg").default}
                    />
                    <p>일치하는 고객이 없습니다.</p>
                    <p>이름과 전화번호를 다시 확인해 주세요.</p>
                </div>
            )}

            {showResult && resultList.length > 0 && (
                <div className="search_result_list">
                    {resultList.map((v, i) => {
                        console.log(v?.file_name);
                        return (
                            <div key={i} className="search_result_user_box">
                                <div className="search_result_user_info">
                                    <img 
                                        src={
                                            !v?.file_name?.replace(consts.upload_url_member, "") ?
                                            require("../../assets/images/icons/avatar.svg").default
                                            :
                                            "https://www.pronoteweb.net" + v?.file_name
                                        }
                                        style={{ width: 48, height: 48, marginRight: 12, borderRadius: '50%' }}
                                    />
                                    <div className="search_result_user_sub_box">
                                        <p className="name">{v?.name}</p>
                                        <div className="sub_box">
                                            {v?.birth  && <p>{v?.birth}세</p>}
                                            {v?.gender && <p>{v?.gender}</p>}
                                        </div>
                                    </div>
                                </div>
                                <Button01
                                    width={100}
                                    label={"초대하기"}
                                    borderType={true}
                                    setFunc={() => {
                                        inviteUserFunc(v?.id);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
