import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'users',
    initialState: {
        isLoggedIn: false,
        autoLogin: '',
        mbData: '',
        accessToken: '',
        fav: '',
        showKeyboard: false,
        showBottomNav: true,
        menuOpen: false,
        loading: false,
        search: false,
        boardCate: [],
        banner: '',
        terms: '',
        event: false,
        eventDate: '',
        eventData: '',
        config: '',
        deepLinkState: false,
        backgroundDate: '',
        activeDate: '',
        searchWordList: [],
        bellCheck: false,
        // pickerOpen: false,
        // pickerType: 1,
        // pickerList: [''],
        // pickerState: 0,
        // pickerSetState: '',
        // pickerTitle: '',
        // pickerSubTitle: '',
        // pickerSubmit: '',
        // signData: '',
        // reload: false,
        // searchStx: '',
        // searchList: [],
        // recommnedList: [],
        // issueList: [],
    },
    reducers: {
        logIn(state, action) {
            state.isLoggedIn = true;
            state.mbData = action.payload.mbData;
            state.accessToken = action.payload.accessToken;
            state.autoLogin = action.payload.autoLogin;
        },
        logOut(state, action) {
            state.isLoggedIn = false;
            state.autoLogin = '';
            state.mbData = '';
            state.accessToken = '';
            state.fav = '';
            state.bellCheck = false;
        },
        setData(state, action) {
            state[action.payload.key] = action.payload.value;
        },
        configData(state, action) {
            state.boardCate = action.payload.boardCate;
            state.banner = action.payload.banner;
            state.eventData = action.payload.event;
            state.config = action.payload.config;
            state.terms = action.payload.terms;
        },
        setSearchWordList(state, action) {
            // console.log(state);
            // console.log('action.payload.searchWord => ', action.payload.searchWord);
            state.searchWordList.push(action.payload.searchWord);
        },
        setSearchWordDelete(state, action) {
            state.searchWordList = (action.payload.searchWord);
        },
        setSearchWordDeleteAll(state, action) {
            state.searchWordList = [];
        }
    },
});

export const {
    logIn,
    logOut,
    setData,
    configData,
    setSearchWordList,
    setSearchWordDelete,
    setSearchWordDeleteAll,
} = userSlice.actions;
export default userSlice.reducer;
