import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import FooterInput from "../../components/FooterInput";
import FooterCalBtn from "../../components/FooterCalBtn";

export default function UserTemplateWeekDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const usertype = state?.mbData?.type;
    const token = state?.accessToken;
    const tem_data = location?.state?.tem_data;
    const member_id = location?.state?.member_id;
    const user_plus = location?.state?.user_plus;
    const reg_type = location?.state?.reg_type;
    const tem_title = location?.state?.tem_title; // 리스트에서 가져온 타이틀 추가를 할땐 tem_data에서 가져오지만 기록에서 가져올땐 없기에 따로 받아옴
    
    // const week_num = location?.state?.week_num;
    // const cnt_num = location?.state?.cnt_num;

    const footerRef = useRef(null);

    // console.log('=====> ', tem_data?.register_date);
    
    const [temData, setTemData] = useState(
        {	
            "type": usertype,     //M:전문가 C:고객    
            "register_idx": tem_data?.register_idx ? tem_data?.register_idx : "",
            "register_template_idx": tem_data?.register_template_idx ? tem_data?.register_template_idx : "",   //템플릿 사용 idx
            "member_id": member_id ? member_id : "",
            "week": "1",
            "cnt": "1",
            "data": ''
        }    
    )
    const [dataList, setDataList] = useState([]);
    const [inputView, setInputView] = useState(false);
    const [calView, setCalView] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputNum, setInputNum] = useState('');
    const [inputIndex, setInputIndex] = useState('');
    const [regDateList, setRegDateList] = useState([]);

    useEffect(() => {
        loadData();
        getDateFunc();
    }, []);

    // useEffect(() => {
    //     console.log('result => ', dataList);
    // }, [dataList]);
    
    const loadData = () => {
        
        const sender = {
            type: usertype,
            register_idx: tem_data?.register_idx,
            register_template_idx: tem_data?.register_template_idx,
            member_id: member_id ? member_id : mbData?.userid,
            reg_date_chk: tem_data?.register_date,
            week: '1',
            cnt: '1'
        };
        
        // console.log(sender);
        
        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_p_view}${token}`, sender).then((res) => {
            console.log(res.data);

            if(reg_type === 'new') {
                setDataList(
                    res?.data?.map((x, i) => {
                        x.answer = '';
                        x?.depth?.map((v, n) => {
                            v.answer = '';
                            return v;
                        })
                        return x;
                    })
                )
            } else {
                setDataList(res?.data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const getDateFunc = () => { // 이미 예약중인 날짜가 있는지 파악하기 위해
        let sender = {
            type: mbData?.type,
            register_idx: tem_data?.register_idx,
            register_template_idx: tem_data?.register_template_idx,
            member_id: member_id ? member_id : mbData?.userid
        };
        
        // console.log(sender);

        postData(`${consts.tem_template_answer_now_p_list}${token}`, sender).then((res) => {
            // console.log('result 111111111 =>> ', res);
            if(res?.data?.length > 0){
                setRegDateList(
                    res?.data?.map((x, i) => {
                        return x.register_date;
                    })
                )
            }

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
        });
    }

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = () => {
        navigate(routes)
    }

    const chkFunc = (val, chkVal, answer='', type='') => {
        // console.log('1 ==> ',  chkVal, answer);

        if(usertype === 'C' && user_plus != 'Y') return;

        setDataList(dataList.map((x, i) => {
            if(x?.idx === val?.idx) {
                if(type === 'check') {

                    if(!answer) {
                        x.answer = chkVal?.toString();
                    } else {
                        let ansarr = x?.answer?.split(',');
                        // console.log('2 ==> ', ansarr);
                        if(!ansarr.includes(chkVal?.toString())) {
                            x.answer = x.answer + "," + chkVal?.toString();
                        } else {
                            x.answer = ansarr.map((v, n) => {
                                if(v != chkVal) return v
                            }).filter((v, n) => v !== '' && v !== undefined).join();
                        }
                    }
                        
                } else {
                    if(x.answer && x.answer == chkVal) {
                        x.answer = '';
                    } else {
                        x.answer = chkVal?.toString();
                    }
                }
            }
            return x;
        }));
    }

    const chkFuncSub = (val, num, index, chkVal, answer, type) => {

        if(usertype === 'C' && user_plus != 'Y') return;

        setDataList(dataList.map((x, i) => {
            if(i === index) {
                if(type === 'check') {
                    x?.depth?.map((v, n) => {

                        console.log('11 => ', v);
                        console.log('22 => ', answer);

                        if(n === num) {
                            if(!answer) {
                                v.answer = chkVal?.toString();
                            } else {

                                let ansarr = v?.answer?.split(',');
                                // console.log('2 ==> ', ansarr);
                                if(!ansarr.includes(chkVal?.toString())) {
                                    v.answer = v.answer + "," + chkVal?.toString();
                                } else {
                                    v.answer = ansarr.map((v, n) => {
                                        if(v != chkVal) return v
                                    }).filter((v, n) => v !== '' && v !== undefined).join();
                                }

                            }
                        }
                        return v
                    });

                } else {
                    x?.depth?.map((v, n) => {
                        if(n === num) {
                            if(v.answer === chkVal?.toString()) {
                                v.answer = '';
                            } else {
                                v.answer = chkVal.toString();
                            }
                        }
                        return v
                    });
                }
            }
            return x
        }));
    }


    const inputFunc = (index, num) => {

        console.log(index, num);

        setInputView(!inputView);

        setInputIndex(index); 
        setInputNum(num);

        setTimeout(() => {
            footerRef?.current?.focus();
        }, 300);
    }

    const inputSaveFunc = () => {
        setInputView(!inputView);

        // console.log('1 => ', inputValue);
        // console.log('2 => ', inputIndex);
        // console.log('3 => ', inputNum);
        // console.log(
        //     dataList?.map((x, i) => {
        //         if(i === inputIndex) {
        //             console.log('5 => ', x);
        //             // x?.depth?.map((v, n) => {
        //             //     if(n === inputNum){
        //             //         console.log('5 => ', v);
        //             //         // v.goal[0].title = inputValue;
        //             //     }
        //             // })
        //         }
        //     })
        // );

        if(inputNum === 0 || inputNum) {
            setDataList(
                dataList?.map((x, i) => {
                    if(i === inputIndex) {
                        x?.depth?.map((v, n) => {
                            if(n === inputNum){
                                v.limit_title = inputValue;
                            }
                            return v;
                        })
                    }
                    return x;
                })
            );
        } else {
            setDataList(
                dataList?.map((x, i) => {
                    if(i === inputIndex) {
                        x.limit_title = inputValue;
                    }
                    return x;
                })
            );
        }

        dispatch(loadingStart());

        setInputValue('');
        setInputIndex('');
        setInputNum('');

        setTimeout(() => {
           dispatch(loadingEnd());
        }, consts.lasy);
    }


    const optionBoxType_1 = (val, index) => {
        // console.log(val?.depth);
        return (
            <div className="answer_box">
                <>
                    <p className="answer_box_title">{index + 1}. {val?.title}</p>

                    {val?.img?.replace(consts.upload_url, "") && 
                        <img className="answer_box_img" src={"https://www.pronoteweb.net" + val?.img } />
                    }
                    <div className="answer_box_content">
                        <div className="answer_content">
                            {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                                <>
                                    {val?.goal[0]?.type === '2' ? // 관리자 상태값
                                        <>
                                            {(val?.goal[0]?.title) &&
                                                <p className="answer_content_goal">
                                                    목표치: {val?.goal[0]?.title}회
                                                </p>
                                            }
                                        </>
                                        :
                                        <>
                                            {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                                <p className="answer_goal_title"
                                                    onClick={() => { inputFunc(index); }}
                                                >
                                                    목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                                </p>
                                                :
                                                <p 
                                                    className="answer_goal_title" 
                                                    style={{ marginBottom: 12 }}
                                                    onClick={() => { inputFunc(index); }}
                                                >
                                                    목표치를 입력해주세요.
                                                </p>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </>
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    const optionBoxType_1_sub = (val, num, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                <div className="answer_box_content">
                    <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                    <div className="answer_content">
                        <p className="answer_content_title" style={{ fontSize: 12 }}>{val?.title}</p>
                        {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                            <>
                                {val?.goal[0]?.type === '2' ? // 관리자 상태값
                                    <>
                                        {(val?.goal[0]?.title) &&
                                            <p className="answer_content_goal">
                                                목표치: {val?.goal[0]?.title}회
                                            </p>
                                        }
                                    </>
                                    :
                                    <>
                                        {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                            <p className="answer_goal_title"
                                                onClick={() => { inputFunc(index, num); }}
                                            >
                                                목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                            </p>
                                            :
                                            <p 
                                                className="answer_goal_title" 
                                                style={{ marginBottom: 12 }}
                                                onClick={() => { inputFunc(index, num); }}
                                            >
                                                목표치를 입력해주세요.
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const optionBoxType_2 = (val, index) => {
        
        // console.log('????? => ', val?.goal);

        return (
            <div className="answer_box">
                <>
                    <p className="answer_box_title">{index + 1}. {val?.title}</p>

                    {val?.img?.replace(consts.upload_url, "") && 
                        <img className="answer_box_img" src={"https://www.pronoteweb.net" + val?.img } />
                    }
                    <div className="answer_box_content">
                        <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                        <div className="answer_content">
                            <p className="answer_content_title">{val?.title}</p>
                            {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                                <>
                                    {val?.goal[0]?.type === '2' ? // 관리자 상태값
                                        <>
                                            {(val?.goal[0]?.title) &&
                                                <p className="answer_content_goal">
                                                    목표치: {val?.goal[0]?.title}회
                                                </p>
                                            }
                                        </>
                                        :
                                        <>
                                            {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                                <p className="answer_goal_title"
                                                    onClick={() => { inputFunc(index); }}
                                                >
                                                    목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                                </p>
                                                :
                                                <p 
                                                    className="answer_goal_title" 
                                                    style={{ marginBottom: 12 }}
                                                    onClick={() => { inputFunc(index); }}
                                                >
                                                    목표치를 입력해주세요.
                                                </p>
                                            }
                                        </>
                                    }
                                </>
                            }
                            <div>
                                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                                    return (
                                        <div key={i} className="answer_content_option" onClick={() => { chkFunc(val, (i+1), val?.answer) }}>
                                            {val?.answer === (i+1).toString() ? 
                                                <img src={require("../../assets/images/icons/radio_on.svg").default} style={{ marginRight: 8 }} />
                                                :
                                                <img src={require("../../assets/images/icons/radio_off.svg").default} style={{ marginRight: 8 }} />
                                            }
                                            <p>{x?.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </>
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    const optionBoxType_2_sub = (val, num, index) => {
        console.log('====> ', val);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                <div className="answer_box_content">
                    <img src={require("../../assets/images/icons/re_answer_icon.svg").default} style={{ paddingRight: 8 }} />
                    <div className="answer_content">
                        <p className="answer_content_title" style={{ fontSize: 12 }}>{val?.title}</p>
                        {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                            <>
                                {val?.goal[0]?.type === '2' ? // 관리자 상태값
                                    <>
                                        {(val?.goal[0]?.title) &&
                                            <p className="answer_content_goal">
                                                목표치: {val?.goal[0]?.title}회
                                            </p>
                                        }
                                    </>
                                    :
                                    <>
                                        {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                            <p className="answer_goal_title"
                                                onClick={() => { inputFunc(index, num); }}
                                            >
                                                목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                            </p>
                                            :
                                            <p 
                                                className="answer_goal_title" 
                                                style={{ marginBottom: 12 }}
                                                onClick={() => { inputFunc(index, num); }}
                                            >
                                                목표치를 입력해주세요.
                                            </p>
                                        }
                                    </>
                                }
                            </>
                        }
                        <div>
                            {val?.options?.length > 0 && val?.options?.map((x, i) => {
                                return (
                                    <div key={i} className="answer_content_option" onClick={() => { chkFuncSub(val, num, index, (i+1), val?.answer) }}>
                                        {val?.answer === (i+1).toString() ? 
                                            <img src={require("../../assets/images/icons/radio_on.svg").default} style={{ marginRight: 8 }} />
                                            :
                                            <img src={require("../../assets/images/icons/radio_off.svg").default} style={{ marginRight: 8 }} />
                                        }
                                        <p>{x?.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // 체크박스
    const optionBoxType_3 = (val, index) => {
        // console.log(val);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.type === '2' ? // 관리자 상태값
                            <>
                                {(val?.goal[0]?.title) &&
                                    <p className="answer_content_goal">
                                        목표치: {val?.goal[0]?.title}회
                                    </p>
                                }
                            </>
                            :
                            <>
                                {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                    <p className="answer_goal_title"
                                        onClick={() => { inputFunc(index); }}
                                    >
                                        목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                    </p>
                                    :
                                    <p 
                                        className="answer_goal_title" 
                                        style={{ marginBottom: 12 }}
                                        onClick={() => { inputFunc(index); }}
                                    >
                                        목표치를 입력해주세요.
                                    </p>
                                }
                            </>
                        }
                    </>
                }
                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                    return (
                        <div key={i} className="answer_content_option02" onClick={() => { chkFunc(val, (i+1), val?.answer, 'check') }}>
                            {val?.answer?.includes((i+1).toString()) ? 
                                <img src={require("../../assets/images/icons/check_on.svg").default} style={{ marginRight: 8 }} />
                                :
                                <img src={require("../../assets/images/icons/check_off.svg").default} style={{ marginRight: 8 }} />
                            }
                            <p>{x?.title}</p>
                        </div>
                    )
                })}
                {val?.depth?.length > 0 && <div style={{ height: 20 }}/>}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 체크박스 서브
    const optionBoxType_3_sub = (val, num, index) => {
        // console.log(val);
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.type === '2' ? // 관리자 상태값
                            <>
                                {(val?.goal[0]?.title) &&
                                    <p className="answer_content_goal">
                                        목표치: {val?.goal[0]?.title}회
                                    </p>
                                }
                            </>
                            :
                            <>
                                {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                    <p className="answer_goal_title"
                                        onClick={() => { inputFunc(index, num); }}
                                    >
                                        목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                    </p>
                                    :
                                    <p 
                                        className="answer_goal_title" 
                                        style={{ marginBottom: 12 }}
                                        onClick={() => { inputFunc(index, num); }}
                                    >
                                        목표치를 입력해주세요.
                                    </p>
                                }
                            </>
                        }
                    </>
                }
                {val?.options?.length > 0 && val?.options?.map((x, i) => {
                    return (
                        <div key={i} className="answer_content_option02" onClick={() => { chkFuncSub(val, num, index, (i+1), val?.answer, 'check') }}>
                            {val?.answer?.includes((i+1).toString()) ? 
                                <img src={require("../../assets/images/icons/check_on.svg").default} style={{ marginRight: 8 }} />
                                :
                                <img src={require("../../assets/images/icons/check_off.svg").default} style={{ marginRight: 8 }} />
                            }
                            <p>{x?.title}</p>
                        </div>
                    )
                })}
            </div>
        )
    }

    // 서술형
    const optionBoxType_4 = (val, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.type === '2' ? // 관리자 상태값
                            <>
                                {(val?.goal[0]?.title) &&
                                    <p className="answer_content_goal">
                                        목표치: {val?.goal[0]?.title}회
                                    </p>
                                }
                            </>
                            :
                            <>
                                {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                    <p className="answer_goal_title"
                                        onClick={() => { inputFunc(index); }}
                                    >
                                        목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                    </p>
                                    :
                                    <p 
                                        className="answer_goal_title" 
                                        style={{ marginBottom: 12 }}
                                        onClick={() => { inputFunc(index); }}
                                    >
                                        목표치를 입력해주세요.
                                    </p>
                                }
                            </>
                        }
                    </>
                }
                <TextAreaEdit
                    Height={100}
                    placeholder={"내용을 입력해 주세요."}
                    value={val?.answer ? val?.answer : ''}
                    setValue={(text) => { 
                        if(usertype === 'C' && user_plus != 'Y') return;
                        setDataList(
                            dataList?.map((x, i) => {
                                if(x?.idx == val?.idx) x.answer = text;
                                return x;
                            })
                        );
                    }}
                />
                {val?.depth?.length > 0 && <div style={{ height: 20 }}/>}
                {val?.depth?.length > 0 && val?.depth?.map((x, i) => {
                    // console.log('dep => ', x);
                    return (
                        <div key={i}>
                            {x?.type === '1' && optionBoxType_1_sub(x, i, index)}
                            {x?.type === '2' && optionBoxType_2_sub(x, i, index)}
                            {x?.type === '3' && optionBoxType_3_sub(x, i, index)}
                            {x?.type === '4' && optionBoxType_4_sub(x, i, index)}
                        </div>
                    )
                })}
            </div>
        )
    }

    // 서술형 서브
    const optionBoxType_4_sub = (val, num, index) => {
        return (
            <div className="answer_box">
                <p className="answer_box_title">{index + 1}-{num+1}. {val?.title}</p>
                {(val?.goal?.length > 0 && val?.goal[0]?.type !== '1') && 
                    <>
                        {val?.goal[0]?.type === '2' ? // 관리자 상태값
                            <>
                                {(val?.goal[0]?.title) &&
                                    <p className="answer_content_goal">
                                        목표치: {val?.goal[0]?.title}회
                                    </p>
                                }
                            </>
                            :
                            <>
                                {(val?.limit_title || (val?.goal[0]?.type === '3' && val?.answer_limit_title)) ?
                                    <p className="answer_goal_title"
                                        onClick={() => { inputFunc(index, num); }}
                                    >
                                        목표치: {val?.limit_title ? val?.limit_title : val?.answer_limit_title}회
                                    </p>
                                    :
                                    <p 
                                        className="answer_goal_title" 
                                        style={{ marginBottom: 12 }}
                                        onClick={() => { inputFunc(index, num); }}
                                    >
                                        목표치를 입력해주세요.
                                    </p>
                                }
                            </>
                        }
                    </>
                }
                <TextAreaEdit
                    Height={100}
                    placeholder={"내용을 입력해 주세요."}
                    value={val?.answer ? val?.answer : ''}
                    setValue={(text) => {
                        if(usertype === 'C' && user_plus != 'Y') return;
                        setDataList(
                            dataList?.map((x, i) => {
                                if(i == index){
                                    console.log('??=>', x);
                                    x?.depth?.map((v, n) => {
                                        if(n == num) v.answer = text;
                                        return v;
                                    });
                                };
                                return x;
                            })
                        )
                    }}
                />
            </div>
        )
    }

    
    const saveFunc = (date) => {
        let sender = temData;
        sender.data = dataList;
        sender.reg_date_chk = moment(date).format('YYYY-MM-DD');
        
        console.log('sender => ', sender);
        // return;

        setCalView(false);

        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_p_save}${token}`, sender).then((res) => {
            // console.log('result => ', res);

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res?.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            setTimeout(() => {
                navigate(-1);
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={tem_title ? tem_title : `${tem_data?.title}`}
                centerWidth={'100%'}
                rightWidth={'17%'}
            />
            <div className="temple_week_detail" style={{ position: 'relative' }}>
                {/* 타입 2=객관식, 3=체크박스, 4=서술형 */}
                <div style={{ padding: 20 }}>
                    <div>
                        {dataList?.map((x, i) => {
                            return (
                                <div key={i}>
                                    {x?.type === '1' && optionBoxType_1(x, i)}
                                    {x?.type === '2' && optionBoxType_2(x, i)}
                                    {x?.type === '3' && optionBoxType_3(x, i)}
                                    {x?.type === '4' && optionBoxType_4(x, i)}
                                </div>
                            )
                        })}
                        
                    </div>
                </div>

                {inputView && 
                    <FooterInput 
                        iref={footerRef}
                        type={'number'}
                        placeholder={'목표치를 입력해 주세요.'}
                        value={inputValue}
                        setValue={setInputValue}
                        setFunc={inputSaveFunc}
                        setClose={() => setInputView(!inputView)}
                    />
                }

                {calView &&
                    <FooterCalBtn 
                        regDateList={regDateList}
                        setFunc={(date) => { saveFunc(date) }}
                        setClose={() => setCalView(!calView)}
                    />
                }

                {(usertype === 'M' || (usertype === 'C' && user_plus === 'Y')) &&
                    <>
                        <FooterBtn
                            btnName={'저장하기'}
                            setFunc={() => { 
                                if(reg_type === 'new') {
                                    setCalView(!calView) 
                                } else {
                                    saveFunc(tem_data?.register_date);
                                }
                            }}
                        />
                    </>
                }
                
            </div>
        </>
    );
}
