import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

// import * as APIS from "../utils/service";
// import { findBoardTypeConstApi } from "../utils/utils";

// import routes from "../libs/routes";
// import consts from '../libs/consts';

export default function Header(props) {
    const {
        leftBtn,
        leftFunc,
        title,
        rightCancel,
        rightXBtn,
        rightFunc,
        rightTextBtn,
        backgorundColor,
        rightText,
        leftWidth,
        centerWidth,
        rightWidth,
    } = props;

    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    
    return (
        <div
            className="header"
            style={{ backgroundColor: backgorundColor ? backgorundColor : "" }}
        >
            <div
                style={{ width: leftWidth ? leftWidth : "25%", display: "flex", justifyContent: "flex-start" }}
            >
                {leftBtn ? (
                    <img
                        src={require("../../assets/images/icons/back_btn.svg").default}
                        style={{ padding: 5 }}
                        onClick={() => {
                            leftFunc();
                        }}
                    />
                ) : (
                    <div style={{ width: 52, height: 52 }} />
                )}
            </div>
            <p
                className="center_text"
                style={{ width: centerWidth ? centerWidth : "75%", display: "flex", justifyContent: "center" }}
            >
                {title}
            </p>
            <div
                style={{ width: rightWidth ? rightWidth : "25%", display: "flex", justifyContent: "flex-end" }}
            >
                {rightCancel && (
                    <button
                        className="cancel_text"
                        onClick={() => {
                            rightFunc();
                        }}
                        style={{ padding: 10 }}
                    >
                        취소
                    </button>
                )}
                {rightXBtn && (
                    <img
                        src={require("../../assets/images/icons/x_btn.svg").default}
                        onClick={() => {
                            rightFunc();
                        }}
                        style={{ padding: 5 }}
                    />
                )}
                {rightTextBtn && (
                    <button
                        className="right_text"
                        onClick={() => {
                            rightFunc();
                        }}
                        style={{ padding: 10, color: "#666666" }}
                    >
                        {rightTextBtn}
                    </button>
                )}
                {rightText && (
                    <p
                        className="cancel_right_text"
                        onClick={() => {
                            rightFunc();
                        }}
                    >
                        {rightText}
                    </p>
                )}
                {!rightCancel && !rightXBtn && !rightText && !rightTextBtn && (
                    <div style={{ width: 40, height: 40 }} />
                )}
            </div>
        </div>
    );
}
