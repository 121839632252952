import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import routes from "./libs/routes";
import axios from "axios";

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// import * as APIS from "./utils/service";

/* ====== redux ====== */
import { logIn, logOut, configData } from "./redux/usersSlice";
/* =================== */

// import { loadingEnd } from "./redux/loadingSlice";

// import Header from "./components/Header";
// import Footer from "./components/Footer";

import Login from "./pages/main/Login";
import Register from "./pages/register/Register";
import RegisterTerms from "./pages/register/RegisterTerms";
import RegisterTermsStoreOnly from "./pages/register/RegisterTermsStoreOnly";

import RegisterEmail from "./pages/register/RegisterEmail";
import RegisterChkId from "./pages/register/RegisterChkId";
import RegisterInput from "./pages/register/RegisterInput";
import SearchInfo from "./pages/searchInfo/SearchInfo";
import SearchPass from "./pages/searchInfo/SearchPass";

import Calendar from "./pages/calendar/Calendar";
import ScheduleInput from "./pages/calendar/ScheduleInput";
import MySchedule from "./pages/calendar/MySchedule";


import MemberSearch from "./pages/userInfo/MemberSearch";
import MemberList from "./pages/userInfo/MemberList";

import UserDetail from "./pages/userInfo/UserDetail";
import UserProfile from "./pages/userInfo/UserProfile";
import UserSchedule from "./pages/userInfo/UserSchedule";
import UserTraining from "./pages/userInfo/UserTraining";
import UserTemplateList from "./pages/userInfo/UserTemplateList";
import UserTemplateDetail from "./pages/userInfo/UserTemplateDetail";
import UserTemplateDetailEx from "./pages/userInfo/UserTemplateDetailEx";
import UserTemplateWeek from "./pages/userInfo/UserTemplateWeek";
import UserTemplateWeekDetail from "./pages/userInfo/UserTemplateWeekDetail";

import UserResultList from "./pages/userInfo/UserResultList";
import UserResultDetail from "./pages/userInfo/UserResultDetail";
import UserResultDetailEx from "./pages/userInfo/UserResultDetailEx";
import StatisticsList from  "./pages/statistics/StatisticsList";

import Mypage from "./pages/mypage/Mypage";
import ProfileEdit from "./pages/mypage/ProfileEdit";
import ProfileEditCus from "./pages/mypage/ProfileEditCus";
import ProfilePreview from "./pages/mypage/ProfilePreview";
import ProfileEducation from "./pages/mypage/ProfileEducation";
import ProfileIntroduction from "./pages/mypage/ProfileIntroducion";
import ProfileCertificate from "./pages/mypage/ProfileCertificate";
import ProfileCareer from "./pages/mypage/ProfileCareer";
import ProfileDefaultInfo from "./pages/mypage/ProfileDefaultInfo";
import MemberShip from "./pages/mypage/MemberShip";
import MemberShipSuc from "./pages/mypage/MemberShipSuc";
import MemberShipFail from "./pages/mypage/MemberShipFail";


function Layout() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mbData, accessToken } = useSelector((s) => s.usersReducer);

    useEffect(() => { 
        
    }, []);

    return (
        <>
            <div>
                <Routes>
                    {
                        <>  
                            {/* 개인정보 약관 페이지 */}
                            <Route exact path={routes.registerPronoteTerms} element={<RegisterTermsStoreOnly />} />

                            <Route exact path={""} element={ mbData?.userid ? <Calendar /> : <Login /> } />
                            <Route exact path={routes.login} element={ <Login />} />
                            <Route exact path={routes.register} element={<Register />} />
                            <Route exact path={routes.registerTerms} element={<RegisterTerms />} />
                            <Route exact path={routes.registerEmail} element={<RegisterEmail />} />
                            <Route exact path={routes.registerChkId} element={<RegisterChkId />} />
                            <Route exact path={routes.registerInput} element={<RegisterInput />} />
                            <Route exact path={routes.SearchInfo} element={<SearchInfo />} />
                            <Route exact path={routes.searchPass} element={<SearchPass />} />

                            {/* 스케쥴 */}
                            <Route exact path={routes.calendar} element={<Calendar />} />
                            <Route exact path={routes.scheduleInput} element={<ScheduleInput />} />
                            <Route exact path={routes.mySchedule} element={<MySchedule />} />
                            

                            {/* 고객탭 */}
                            <Route exact path={routes.memberList} element={<MemberList />} />
                            <Route exact path={routes.memberSearch} element={<MemberSearch />} />
                            
                            {/* 유저 */}
                            <Route exact path={routes.userDetail} element={<UserDetail />} />
                            
                            <Route exact path={routes.userProfile} element={<UserProfile />} />
                            <Route exact path={routes.userSchedule} element={<UserSchedule />} />
                            <Route exact path={routes.userTraining} element={<UserTraining />} />
                            <Route exact path={routes.userTemplateList} element={<UserTemplateList />} />
                            <Route exact path={routes.userTemplateDetail} element={<UserTemplateDetail />} />
                            <Route exact path={routes.userTemplateDetailEx} element={<UserTemplateDetailEx />} />
                            <Route exact path={routes.userTemplateWeek} element={<UserTemplateWeek />} />
                            <Route exact path={routes.userTemplateWeekDetail} element={<UserTemplateWeekDetail />} />
                            
                            <Route exact path={routes.userResultList} element={<UserResultList />} />
                            <Route exact path={routes.userResultDetail} element={<UserResultDetail />} />
                            <Route exact path={routes.userResultDetailEx} element={<UserResultDetailEx />} />
                            <Route exact path={routes.statisticsList} element={<StatisticsList />} />
                            
                            
                            <Route exact path={routes.mypage} element={<Mypage />} />
                            <Route exact path={routes.profileEdit} element={<ProfileEdit />} />
                            <Route exact path={routes.profileEditCus} element={<ProfileEditCus />} /> {/*회원전용프로필편집*/}
                            <Route exact path={routes.profilePreview} element={<ProfilePreview />} />
                            <Route exact path={routes.profileEducation} element={<ProfileEducation />} />
                            <Route exact path={routes.profileCareer} element={<ProfileCareer />} />
                            <Route exact path={routes.profileCertificate} element={<ProfileCertificate />} />
                            <Route exact path={routes.profileIntroduction} element={<ProfileIntroduction />} />
                            <Route exact path={routes.profileDefaultInfo} element={<ProfileDefaultInfo />} />
                            <Route exact path={routes.membership} element={<MemberShip />} />
                            <Route exact path={routes.membershipsuc} element={<MemberShipSuc />} />
                            <Route exact path={routes.membershipfail} element={<MemberShipFail />} />


                            {/* <Route exact path={routes.main} element={<Main />} /> */}
                        </>
                    }
                </Routes>
                {/* <Footer /> */}
            </div>
        </>
    );
}

export default Layout;
