import React, { useEffect, useState } from "react";

export default function InputProfile(props) {
    const {
        data,
        setData,
        index,
        educationType,

        label,
        value = "",
        setValue,
        placeholder,
        maxlength,
        type,
        disabled,
        error,
        onKeyPress,
        onClickFunc,
        openPostCode,
        iref,
        onBlur,
        children,
        checkValue,
        check,
        searchBtn=true,
        width,
    } = props;

    useEffect(() => { }, []);

    const handleChange = (e) => {
        if (type === "education") handleEducationChange(e);
        else {
            if (openPostCode) {
                if (checkValue) setValue(e.target.value);
            } else {
                // console.log(check);
                if (check) {
                    if (
                        checkValue !== "" &&
                        checkValue !== undefined &&
                        checkValue !== null &&
                        checkValue
                    ) {
                        setValue(e.target.value);
                    }
                } else {
                    // console.log(11);
                    setValue(e.target.value);
                }
            }
        }
    };

    const handleClick = () => {
        if (onClickFunc) {
            if (check) {
                if (!checkValue) {
                    onClickFunc();
                }
            } else {
                onClickFunc();
            }
        }
    };

    const onKeyPressFunc = (e) => {
        if (onKeyPress && e.key === "Enter") {
            onKeyPress();
        }
    };

    const handleEducationChange = (e) => {
        let data1 = [...data];
        data1[index][educationType] = e.target.value;
        setData(data1);
    };

    return (
        <div className="profile__element">
            {label && <span>{label}</span>}
            {openPostCode ? (
                <div className="profile__workPlaceAddressElement">
                    <input
                        style={{ width: width ? width : ''}}
                        ref={iref}
                        placeholder={placeholder ? placeholder : ""}
                        type={"text"}
                        value={value}
                        disabled={disabled}
                        maxLength={maxlength ? maxlength : "255"}
                        onChange={handleChange}
                        // onFocus={() => {}}
                        onBlur={onBlur}
                        onKeyPress={onKeyPressFunc}
                        onClick={() => openPostCode()}
                    />
                    {searchBtn &&
                        <div onClick={() => openPostCode()}>찾기</div>
                    }
                </div>
            ) : (
                <input
                    ref={iref}
                    placeholder={placeholder ? placeholder : ""}
                    type={type === "password" ? type : "text"}
                    value={value}
                    disabled={disabled}
                    maxLength={maxlength ? maxlength : "255"}
                    onChange={handleChange}
                    onFocus={() => { }}
                    onBlur={onBlur}
                    onKeyPress={onKeyPressFunc}
                    onClick={handleClick}
                />
            )}

            {error && <p className="input_error02">{error}</p>}

            {children}
        </div>
    );
}
