import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import * as APIS from "../../utils/service";
import {
    numFormat,
    regEmail,
    regPassword,
    getYoilText,
    calcBirth,
} from "../../utils/utils";
import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { myScType, myPageListType } from '../../redux/mypageSlice';

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TabBar from "../../components/TabBar";

import { postData } from "../../utils/service/APIS";

export default function UserDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { mbData, accessToken } = useSelector((s) => s.usersReducer);
    const location = useLocation();

    const [dataList, setDataList] = useState([]);
    const [firstChk, setFirstChk] = useState(false);

    useEffect(() => {
        if(!firstChk) dispatch(loadingStart());
        dataFunc();
        setInterval(() => {
            dataFunc();
        }, 10000);
    }, []);


    const dataFunc = () => {
        let sender = {
            type: mbData?.type,
        };

        // dispatch(loadingStart());

        postData(`${consts.sc_matching_list}${accessToken}`, sender).then((res) => {
            // console.log('???=> ', res);
            
            if(mbData?.type === 'C') {
                setDataList(res?.data?.filter((x, i) => x.matching_yn != 'R'));
            } else {
                setDataList(res?.data);
            }

            setFirstChk(true);
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const plusMember = () => {
        navigate(routes.memberSearch);
    }

    const detailCustomer = (email, sp_email, matching_yn) => {
        // console.log(matching_yn);
        // return;

        if(matching_yn != 'Y') {
            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={'매칭 고객이 아닙니다.'}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));
            return;
        }

        dispatch(myPageListType(1));
        navigate(routes.userDetail, { 
            state: { 
                cus_email: mbData?.type === 'M' ? email : '', 
                sp_data: mbData?.type === 'M' ? '' : { special_id: sp_email }
            } 
        });
    }

    const cusMatchingFunc = (val, proEmail) => {
        let sender = {
            type: mbData?.type,
            member_id: proEmail
        };

        // console.log(sender)

        dispatch(loadingStart());
        
        let url = consts.sc_matching_accept;
        if(val === 'R') url = consts.sc_matching_refuse;

        postData(`${url}${accessToken}`, sender).then((res) => {
            console.log('??? 22222222222=> ', res);

            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={res?.message}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));

            dataFunc();

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }

    const reInvitePopup = (val) => {
        dispatch(open({
            component: (
                <Popup01
                    width={350}
                    text={'다시 초대하시겠습니까?'}
                    buttonCancel={'아니요'}
                    button={"확인"}
                    noneLoading={true}
                    setFunc={() => reInviteFunc('N', val)}
                    cancelFunc={() => reInviteFunc('D', val)}
                />
            ),
        }));
    }

    const reInviteFunc = (type, val) => {
        let sender = {
            idx: val?.idx,
            type: mbData?.type,
            member_id: val?.member_id
        };
        // console.log(sender);
        // return;

        dispatch(loadingStart());
        
        let url = consts.sc_matching_r_invite;
        if(type === 'D') url = consts.sc_matching_del;

        postData(`${url}${accessToken}`, sender).then((res) => {
            // console.log('???11111111111=> ', res);

            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={res?.message}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));

            dataFunc();

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }


    return (
        <>
            <div className="member_list_header">
                <p>{mbData?.type === 'M' ? '고객관리' : '내 전문가'}</p>
                {mbData?.type === 'M' && 
                    <div className="img_box" onClick={() => { plusMember() }}>
                        <img src={require("../../assets/images/icons/add_circle_btn.svg").default} />
                    </div>
                }
            </div>
            <div style={{ paddingBottom: 93 }}>
                {dataList && dataList?.length > 0 && dataList?.map((x, i) => {
                    // console.log(x);
                    return (
                        <div key={i} className="customer_profile">
                            <div className="customer_profile02" onClick={() => { detailCustomer(x?.member_id, x?.special_id, x?.matching_yn) }}>
                                {x?.file_name === "/upload/member/" ? 
                                    <img src={require("../../assets/images/icons/avatar.svg").default} />
                                    :
                                    <img src={consts.base_url + x?.file_name} />
                                }
                                <div className="customer_profile03">
                                    <p className="customer_name">{x?.name}</p>
                                    {mbData?.type === 'C' ? 
                                        <div className="special_title">
                                            <p>전문가</p>
                                        </div>
                                        :
                                        <div className="customer_profile04">
                                            {x?.birth && <p>{x?.birth}세</p>}
                                            {x?.gender && <p>{x?.gender}</p>}
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            {/* Y:수락  N: 신청  R:거절 */}
                            {mbData?.type === 'M' && x?.matching_yn === 'N' &&
                                <div className="customer_status01">
                                    <p>초대 수락 대기중</p>
                                </div>
                            }
                            {mbData?.type === 'M' && x?.matching_yn === 'R' &&
                                <div className="customer_status02" onClick={() => { reInvitePopup(x) }}>
                                    <p>초대 거부함</p>
                                </div>
                            }
                            {mbData?.type === 'M' && x?.matching_yn === 'Y' &&
                                <div className="customer_status03">
                                    <p>수락</p>
                                </div>
                            }

                            {mbData?.type === 'C' && x?.matching_yn === 'N' &&
                                <div className="member_list_cus_btn_box">
                                    <p className="suc_btn" onClick={() => cusMatchingFunc('R', x?.special_id)}>거부</p>
                                    <p className="suc_btn02" onClick={() => cusMatchingFunc('Y', x?.special_id)}>수락</p>
                                </div>
                            }
                        </div>
                    )
                })}
                {(!dataList || dataList?.length === 0) && 
                    <div className="not_list_box" style={{ paddingTop: '30%' }}>
                        <img
                            src={require("../../assets/images/icons/notResult.svg").default}
                        />
                        <p>회원이 없습니다.</p>
                    </div>
                }
            </div>


            <TabBar />
        </>
    );
}
