import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import "moment/locale/ko";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";


export default function UserTraining() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const token = state.accessToken;
    const tr_date = location?.state?.tr_date;
    const member_id = location?.state?.member_id;

    const [idx, setIdx] = useState("");
    const [trContent, setTrContent] = useState("");
    const [date, setDate] = useState(tr_date ? tr_date : new Date());

    // const userid = location.state ? location.state.user_id : "hi";
    // console.log(location?.state);

    useEffect(() => {
        loadData();
    }, [date]);

    const loadData = () => {
        if(member_id) {
            
            let sender = {
                member_id: member_id,
                dateYmd: moment(date).format("YYYY-M-D"),
            };

            dispatch(loadingStart());
    
            postData(`/mypage.php?trace=training_view&token=${token}`, sender).then((res) => {
                console.log('????????=>', res);
                if (res.result === "success") {
                    setTrContent(res.ht_content);
                    setIdx(res.idx);
                } else {
                    setTrContent("");
                    setIdx("");
                }

                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.lasy);

            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.finalLasy);
            });
        }
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const deleteFuncPopup = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={'삭제하시겠습니까?'}
                        buttonCancel={'취소'}
                        button={"확인"}
                        setFunc={deleteFunc}
                        noneLoading={true}
                    />
                ),
            })
        );
    }

    const deleteFunc = (i, stime, etime) => {
        let sender = {
            idx: idx,
        };
        
        dispatch(loadingStart());

        postData(`/mypage.php?trace=training_dele&token=${token}`, sender).then((res) => {
            // console.log(res?.message);
            // window.location.reload();

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res?.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            setTimeout(() => {
                dispatch(loadingEnd());
                navigate(-1);
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });
    };

    const prevFunc = () => {
        setDate(moment(date).subtract(1, "days"));
    };

    const nextFunc = () => {
        setDate(moment(date).add(1, "days"));
    };

    const saveFunc = () => {
        if(!trContent) {
            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={'내용을 입력해주세요.'}
                        button={"확인"}
                        noneLoading={true}
                    />
                ),
            }));
            return;
        }

        dispatch(loadingStart());

        if (idx) {
            let sender = {
                idx: idx,
                content: trContent,
            };

            postData(`/mypage.php?trace=training_edit&token=${token}`, sender).then((res) => {
                endFunc(res?.message);
            }).catch((e) => {
                console.log(e);
            });

        } else {

            let sender = {
                member_id: member_id,
                dateYmd: moment(date).format("YYYY-M-D"),
                content: trContent,
            };

            postData(`/mypage.php?trace=training_inst&token=${token}`, sender).then((res) => {
                endFunc(res?.message);
            }).catch((e) => {
                console.log(e);
            });
        }

        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.finalLasy);
    };

    const endFunc = (msg) => {

        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.lasy);

        dispatch(open({
            component: (
                <Popup01
                    width={350}
                    text={msg}
                    button={"확인"}
                    noneLoading={true}
                    setFunc={() => {
                        navigate(routes.userDetail, { state: { cus_email: member_id } }) 
                    }}
                />
            ),
        }));
    }

    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={mbData?.type === 'M' ? "숙제 등록" : "숙제 확인"}
            />
            <div className="home_training_edit_box">
                <div className="home_training_date">
                    <img
                        src={require("../../assets/images/icons/prev_icon.svg").default}
                        onClick={() => {
                            prevFunc();
                        }}
                    />
                    <p>{moment(date).format("M.D(dd)")}</p>
                    <img
                        src={require("../../assets/images/icons/next_icon.svg").default}
                        onClick={() => {
                            nextFunc();
                        }}
                    />
                </div>
                <TextAreaEdit
                    Height={400}
                    placeholder={"내용을 입력해 주세요."}
                    value={trContent}
                    setValue={(e) => {
                        if(mbData?.type != 'M') return;
                        setTrContent(e);
                    }}
                />
                <div style={{ marginTop: 20 }} />
                {mbData?.type === 'M' &&
                    <Button01
                        width={"100%"}
                        label={idx ? "저장" : "등록"}
                        setFunc={saveFunc}
                    />
                }
                {(idx && member_id && mbData?.type === 'M') && (
                    <div className="home_training_delete_btn">
                        <p
                            onClick={() => {
                                deleteFuncPopup();
                            }}
                        >
                            삭제하기
                        </p>
                    </div>
                )}
            </div>
        </>
    );
}
