import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import routes from '../libs/routes';

export default function Button02(props) {

    const { label, width, height, borderType, setFunc, iconType } = props;
    
    return (
        <button 
            className={borderType ? "button02_defalut_border" : "button02_defalut"}
            style={{ 
                width: width ? width : 310,
                height: height ? height : 64,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={setFunc}
        >
            {iconType === 'plus' && <img src={require("../assets/images/icons/plus_icon.svg").default} style={{ marginRight: 10 }}/>}
            {iconType === 'edit' && <img src={require("../assets/images/icons/edit_icon.svg").default} style={{ marginRight: 10 }}/>}
            <p style={{ paddingBottom: 2 }}>{label}</p>
        </button>
    );

}
