import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { open } from "../../redux/popupSlice";
import { logOut } from "../../redux/usersSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import { getData, postData } from "../../utils/service/APIS";
import { regNameChk, regPassword, regPhoneChk } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Popup01 from "../popup/Popup01";
import ProfileInput from "../../components/ProfileInput";
import TermsPopup from "../popup/TermsPopup";
import { Rotate } from "hammerjs";

const ProfileDefaultInfo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    var token = state.accessToken;

    const [data, setData] = useState([]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [pass, setPass] = useState("");
    const [passChk, setPassChk] = useState("");
    const [termsOpen, setTermsOpen] = useState(false);
    const [popup, setPopup] = useState();

    const [err01, setErr01] = useState("");
    const [err02, setErr02] = useState("");
    const [err03, setErr03] = useState("");
    const [err04, setErr04] = useState("");

    const [chk01, setChk01] = useState(false);
    const [chk02, setChk02] = useState(false);
    const [chk03, setChk03] = useState(false);

    if (!token) navigate(routes.login);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        
        const sender = {
            type: type,
        };

        dispatch(loadingStart());

        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {
            console.log(res);
            if (res.result === "success") {
                setData(res);
                setPhone(res.member_tel)
                setName(res?.member_name);
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });
    };

    const termsFunc = (trace) => {
        getData(`/cs.php?trace=${trace}`).then((data) => {
            console.log(data);
            setPopup(data.content);
        });
    };

    const headerLeftFunc = () => {
        if (termsOpen) {
            setTermsOpen(false);
        } else {
            navigate(routes.mypage);
        }
    };

    const passChkFunc = (type) => {
        if (!regPassword(pass)) {
            setErr01("비밀번호는 8~12자리 영문,숫자,특수문자 혼합");
            return;
        }
        setErr01("");

        if (pass !== passChk) {
            if (!regPassword(passChk)) {
                setErr01("비밀번호는 8~12자리 영문,숫자,특수문자 혼합");
                return;
            }
            setErr02("비밀번호가 일치하지 않습니다.");
            return;
        }
        setErr02("");

        setChk01(true);
    };

    const nameChkFunc = () => {
        if (!regNameChk(name)) {
            setErr03("2~10자 한글,영문으로 입력하세요.");
            return;
        }
        setErr03("");

        setChk02(true);
    };

    const phoneChkFunc = () => {
        if (!regPhoneChk(phone)) {
            setErr04("휴대폰 형식이 올바르지 않습니다.");
            return;
        }
        if (phone.length > 11) {
            setErr04("11자 숫자로 입력해주세요");
            return;
        }
        setErr04("");

        setChk03(true);
    };

    const saveBtn = () => {
        // const data1 = data.filter(
        //     (item) =>
        //         item.education_type !== null &&
        //         item.education_type !== undefined &&
        //         item.education_type !== "" &&
        //         item.education_type
        // );

        // setData(data1);

        const sender = {
            type: type,
            userpassword: pass,
            reuserpassword: passChk,
            username: name,
            usertel: phone,
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.my_member_edit}${token}`, sender).then((res) => {
            
            console.log(res);

            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={res?.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );

            setTimeout(() => {
                dispatch(loadingEnd());
                navigate(routes.mypage);
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerRightFunc = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={"탈퇴하시겠습니까?"}
                        buttonCancel={"탈퇴"}
                        button={"아니오"}
                        func={"setRightFunc"}
                        setFunc={() => {
                            console.log("탈퇴");
                            withDraw();
                        }}
                        noneLoading={true}
                    />
                ),
            })
        );
    };

    // 회원 탈퇴
    const withDraw = () => {

        dispatch(logOut());

        getData(`/authorization.php?trace=withdraw&token=${token}`).then((res) => {
            console.log('??=> ', res);
            if (res.result === "success") {
                navigate(routes.login);
            }
        }).catch((e) => {
            console.log(e);
        });
        navigate(routes.login);
    };

    return (
        <div className="profile">
            <Header
                leftBtn={!termsOpen && true}
                leftFunc={() => headerLeftFunc()}
                title={termsOpen ? "계정정보" : "계정정보"}
                rightTextBtn={termsOpen && "탈퇴하기"}
                rightFunc={() => {
                    headerRightFunc();
                }}
            />
            {!termsOpen ? (
                <>
                    <div className="profile__education">
                        <div className="profile__educationContainers">
                            <div className="profile__educationContainer">
                                <div className="profile__element">
                                    <span>이메일 ID</span>
                                    <div className="profile__email">
                                        {data.member_id}
                                    </div>
                                </div>
                                <ProfileInput
                                    type={"password"}
                                    value={pass}
                                    setValue={setPass}
                                    label={"비밀번호"}
                                    placeholder={"비밀번호(8~12자리 영문,숫자,특수문자 혼합)"}
                                    error={err01}
                                    maxlength={12}
                                    onBlur={() => {
                                        passChkFunc(1);
                                    }}
                                >
                                    <ProfileInput
                                        type={"password"}
                                        value={passChk}
                                        setValue={setPassChk}
                                        maxlength={12}
                                        placeholder={"비밀번호 재입력"}
                                        error={err02}
                                        onBlur={() => {
                                            passChkFunc(2);
                                        }}
                                    />
                                </ProfileInput>
                                <ProfileInput
                                    value={name}
                                    setValue={setName}
                                    label={"이름"}
                                    maxlength={10}
                                    placeholder={"이름 입력"}
                                    error={err03}
                                    onBlur={() => {
                                        nameChkFunc();
                                    }}
                                />
                                <ProfileInput
                                    value={phone}
                                    setValue={setPhone}
                                    label={"휴대폰번호"}
                                    maxlength={11}
                                    placeholder={"“-”없이 입력하세요."}
                                    error={err04}
                                    onBlur={() => {
                                        phoneChkFunc();
                                    }}
                                />
                                <div
                                    className="profile__withdrawBtn"
                                    onClick={() => {
                                        termsFunc("close_policy");
                                        setTermsOpen(true);
                                    }}
                                >
                                    회원탈퇴
                                </div>
                            </div>
                        </div>
                        <FooterBtn greyChk={!true} setFunc={saveBtn} btnName="저장" />
                    </div>
                </>
            ) : (
                <TermsPopup content={popup} />
            )}
        </div>
    );
};

export default ProfileDefaultInfo;
