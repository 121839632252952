import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { open, close } from "../../redux/popupSlice";

import { getData, postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Popup01 from "../popup/Popup01";
import SelectBoxMap from "../../components/SelectBoxMap";
import ProfileInput from "../../components/ProfileInput";

const ProfileEducation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let state = useSelector((state) => {
        return state.usersReducer;
    });

    var token = state.accessToken;

    const [data, setData] = useState([]);
    const [educationType, setEducationType] = useState([]);

    // if (!token) navigate(routes.login);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        
        dispatch(loadingStart());

        getData(`/mypage.php?trace=education_view&token=${token}`).then((res) => {
            console.log(res);
            if (res.result === "success" && res.data) {
                setData(res.data);
                setEducationType(res.data.map((v) => v.education_type));
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, 100);
        }).catch((e) => {
            console.log(e);
        });
        
    };

    const headerLeftFunc = () => {
        navigate(routes.profileEdit);
    };

    const footerBtn = () => {
        // console.log(data);
        const data1 = data.filter(
            (item) =>
                item.education_type !== null &&
                item.education_type !== undefined &&
                item.education_type !== "" &&
                item.education_type
        );

        setData(data1);

        postData(`/mypage.php?trace=education_edit&token=${token}`, data1.length ? data1 : [{}]).then((res) => {

            dispatch(open({
                component: (
                    <Popup01
                        width={350}
                        text={res.message}
                        button={"확인"}
                        noneLoading={true}
                    />
                )
            }));

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });
    };

    const handleDelete = (e, i) => {
        let data1 = [...data];
        data1.splice(i, 1);
        setData(data1);
    };

    const handleAdd = () => {
        if (data.length < 5) {
            let data1 = [...data];
            data1.push({
                education_type: null,
                education_nm: "",
                education_major: "",
                education_submajor: "",
            });
            setData(data1);
        } else {
            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={"최대 등록할수 있는 학력은 5개 입니다."}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );
        }
    };

    return (
        <div className="profile">
            <Header leftBtn={true} leftFunc={() => headerLeftFunc()} title={"학력"} />
            <div className="profile__education">
                <div className="profile__educationContainers">
                    {data !== undefined &&
                        data !== null &&
                        data &&
                        data.length !== 0 &&
                        data.map((v, i) => (
                            <div key={i}>
                                <div className="profile__educationContainer">
                                    <div className="profile__element">
                                        <div className="profile__educationTop">
                                            <span>학력 선택</span>
                                            {i !== 0 && (
                                                <div onClick={(e) => handleDelete(e, i)}>삭제하기</div>
                                            )}
                                        </div>
                                        <SelectBoxMap
                                            data={v}
                                            data1={data}
                                            index={i}
                                            setData1={setData}
                                            educationType={educationType}
                                            setEducationType={setEducationType}
                                        />
                                    </div>
                                    {v.education_type && (
                                        <ProfileInput
                                            type={"education"}
                                            data={data}
                                            setData={setData}
                                            index={i}
                                            educationType={"education_nm"}
                                            value={v.education_nm}
                                            placeholder={"학교명 입력"}
                                            label={"학교명"}
                                        />
                                    )}
                                    {v.education_type !== "1" && v.education_type && (
                                        <ProfileInput
                                            type={"education"}
                                            data={data}
                                            setData={setData}
                                            index={i}
                                            educationType={"education_major"}
                                            value={v.education_major}
                                            disabled={v.education_nm === "1"}
                                            placeholder={"전공 입력"}
                                            label={"전공"}
                                        >
                                            <ProfileInput
                                                type={"education"}
                                                data={data}
                                                setData={setData}
                                                index={i}
                                                educationType={"education_submajor"}
                                                value={v.education_submajor}
                                                disabled={v.education_nm === "1"}
                                                placeholder={"복수전공일 경우 입력하세요"}
                                            />
                                        </ProfileInput>
                                    )}
                                </div>

                                {i + 1 !== data.length && (
                                    <div className="profile__divide"></div>
                                )}
                            </div>
                        ))}
                </div>
                <div className="profile__educationBottom" onClick={() => handleAdd()}>
                    <img
                        src={require("../../assets/images/icons/add-line.svg").default}
                        style={{
                            filter:
                                "invert(19%) sepia(75%) saturate(3411%) hue-rotate(250deg) brightness(113%) contrast(104%)",
                        }}
                    />
                    <div>추가하기</div>
                </div>
            </div>
            <FooterBtn greyChk={!true} setFunc={() => footerBtn()} btnName="저장" />
        </div>
    );
};

export default ProfileEducation;
