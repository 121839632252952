import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    numFormat,
    patternNickFunc,
    regEmail,
    regPassword,
    patternSpcInstar,
    regPhone,
} from "../../utils/utils";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";
import { render } from "@testing-library/react";

export default function SearchPass(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const [email, setEmail] = useState("");
    const [num, setNum] = useState("");
    const [chkNum, setChkNum] = useState("");
    const [reNum, setReNum] = useState(false);
    const [error01, setError01] = useState("");
    const [error02, setError02] = useState("");

    const [searchStatus, setSearchStatus] = useState(1);
    const [pass, setPass] = useState("");
    const [passChk, setPassChk] = useState("");
    const [err01, setErr01] = useState("");
    const [err02, setErr02] = useState("");


    useEffect(() => { }, []);

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const emailChk = () => {
        if (regEmail.test(email)) {
            let sender = {
                userid: email,
            };

            postData(consts.authorization_find_pwd, sender).then((res) => {
                // console.log(res);

                // dispatch(open({
                //     component: <Popup01 
                //                     width={'100%'}
                //                     text={res?.message}
                //                     button={'확인'}
                //                     noneLoading={true}
                //                 />,
                // }));

                if (res?.result === "success") {
                    setSearchStatus(2);
                    setChkNum(res?.code);
                }

            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.finalLasy);
            });
        } else {
            setError01("이메일을 다시 입력해주세요.");
            return;
        }
    };

    const numChk = () => {
        if(!num) {
            setError02("인증번호를 입력해주세요.");
        } else if(num != chkNum){
            setError02("인증번호가 틀렸습니다.");
        } else if(num == chkNum){
            setError02('');
            setSearchStatus(3);
        }
    };

    const sendNum = () => {
        setReNum(false);
        setError02("");
        emailChk();
    };

    const resetPass = () => {
        if (!pass) {
            setErr01("비밀번호를 입력해주세요.");
            return;
        }

        if (!regPassword(pass)) {
            setErr01("비밀번호는 8~12자리 영문,숫자,특수문자 혼합");
            return;
        }
        setErr01("");

        if(pass !== passChk) {
            setErr02("비밀번호가 일치하지 않습니다.");
            return;
        }
        setErr02('');

        let sender = {
            userid: email,
            userpassword: pass,
            reuserpassword: passChk,
        };


        postData(consts.authorization_change_pwd, sender).then((res) => {
            // console.log(res);

            if (res?.result === "success") {
                navigate(routes.login);
            } else {
                setErr02(res.message);
            }
        });
    };


    return (
        <>
            <Header
                title={"비밀번호 찾기"}
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
            />
            {searchStatus === 1 ? (
                <>
                    <div className="register_box_title" style={{ marginBottom: 20 }}>
                        <p>비밀번호 찾기를 위해</p>
                        <p>이메일 인증을 진행해 주세요.</p>
                    </div>

                    <div className="register_box02">
                        <Input value={email} setValue={setEmail} error={error01} />

                        <div style={{ height: 20 }} />

                        <Button01
                            label={"인증번호 받기"}
                            setFunc={() => {
                                emailChk();
                            }}
                        />
                    </div>
                </>
            ) : (
                (searchStatus === 2 || searchStatus === 3) && (
                    <>
                        <div className="flex_container">
                            <div className="register_box_title" style={{ marginBottom: 20 }}>
                                <p>입력하신 메일로</p>
                                <p>인증번호를 발송했습니다.</p>
                            </div>

                            <div className="register_email" style={{ marginBottom: 20 }}>
                                <p>{email}</p>
                            </div>

                            <div className="register_box02">
                                <div className="chk_email_num" style={{ gap: 4 }}>
                                    <Input
                                        width={226}
                                        value={num}
                                        setValue={setNum}
                                        placeholder={"인증번호"}
                                        timerChk={true}
                                        timerEndFunc={() => {
                                            setReNum(true);
                                        }}
                                        timerReStart={reNum}
                                        error02={error02}
                                    />
                                    <Button01
                                        width={80}
                                        height={44}
                                        label={
                                            searchStatus === 2 ? reNum ? "재전송" : "확인" : "인증됨"
                                        }
                                        setFunc={() => {
                                            searchStatus !== 3 && reNum ? sendNum() : numChk();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {searchStatus === 3 && (
                            <div>
                                <div className="reset_pass_box">
                                    <p className="reset_pass_title">비밀번호를 설정해주세요</p>
                                    <div style={{ marginBottom: 10 }}>
                                        <Input
                                            type={"password"}
                                            value={pass}
                                            setValue={setPass}
                                            error={err01}
                                            placeholder={"비밀번호(8~12자리 영문,숫자,특수문자 혼합)"}
                                        />
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <Input
                                            type={"password"}
                                            value={passChk}
                                            setValue={setPassChk}
                                            error={err02}
                                            placeholder={"비밀번호 재입력"}
                                        />
                                    </div>
                                    <Button01
                                        label={"비밀번호 변경"}
                                        setFunc={() => {
                                            resetPass();
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )
            )}
        </>
    );
}
