import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import "../../assets/css/calendar.css";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Hammer from "hammerjs";

import "moment/locale/ko";
import moment, { months } from "moment";
import Calendar from "react-calendar";

import { getData, postData } from "../../utils/service/APIS";

import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import TabBar from "../../components/TabBar";
import CalendarBox from "../../components/Calendar";

export default function ReactCalender() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;

    const [value, onChange] = useState(new Date());
    const [scData, setScData] = useState([]);
    const [scheduleTimeSet, setScheduleTimeSet] = useState([]);
    const [clickDate, setClickDate] = useState(moment().format('YYYY-MM-DD'));
    const [clickDay, setClickDay] = useState(moment().day());
    const [cusSchedule, setCusSchedule] = useState([]);
    const [screenWidth, setScreenWidth] = useState(0);

    // const [mark, setMark] = useState([]);
    // const [month, setMonth] = useState(moment(new Date()).format("YYYY-M"));
    // const tileLength = document.querySelectorAll(".react-calendar__tile").length;

    useEffect(() => {
        dispatch(close());
        swipeRow();
        loadScheduleTimeSet();
        loadMonthData();
    }, []);

    useEffect(() => {
        if(type === 'C') getUserScData();
    }, [clickDate, clickDay]);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    const loadScheduleTimeSet = (sc_dateYmd) => {
        var sender = {
            sc_dateYmd: sc_dateYmd,
        };
        
        getData(`${consts.sc_schedule_time_set}${token}`, sender).then((res) => {
            // console.log(res);
            if (res?.result === "success") {
                setScheduleTimeSet(res.data);
            }
        }).catch((e) => {
            console.log(e)
        });
    };

    const loadMonthData = () => {
        
        let sender = {
            type: type,
            sc_dateYm: moment(new Date()).format("YYYY-MM"),
        };

        console.log(sender);

        dispatch(loadingStart());
        
        postData(`${consts.sc_schedule_month}${token}`, sender).then((res) => {

            // console.log('======>', res?.sc_data);

            if(res?.result === "success") {
                setScData(res.sc_data);
                // const newArr = data.sc_data.map(
                //     (row) => row.data.length && row.sc_day
                // );
                // setMark(newArr);
                // console.log('newArr 11111=> ', newArr);
            } else {
                setScData([]);
                // const newArr = [].map((row) => row.sc_date);
                // setMark(newArr);
                // console.log('newArr 22222=> ', newArr);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        });

        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.finalLasy);
    };

    
    const getUserScData = () => {
        let sender = {};
        // console.log('111111111111', sender);
        dispatch(loadingStart());
        
        postData(`/schedule.php?trace=my_schedule_view&token=${token}`, sender).then((res) => {

            setCusSchedule(
                res?.data?.map((x, i) => {
                    if(x?.sc_date == clickDate || (x?.week_day && x?.week_day === consts?.dayNum[clickDay] && x?.sc_date <= clickDate)) {
                        return x;
                    }
                }).filter((x, i) => x?.idx)
            );

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        });

        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.finalLasy);
    }

    const swipeRow = () => {
        const container = document.querySelector(".calendar");
        const manager1 = new Hammer.Manager(container);
        const Swipe1 = new Hammer.Swipe();

        manager1.add(Swipe1);

        manager1.on("swipeleft", function (e) {
            const btn = document.querySelector(
                ".react-calendar__navigation__next-button"
            );

            btn.click();
        });

        manager1.on("swiperight", function (e) {
            const btn = document.querySelector(
                ".react-calendar__navigation__prev-button"
            );

            btn.click();
        });
    };

    const scFunc = () => {
        navigate(routes.scheduleInput);
    }

    return (
        <div className="calendar">
            <div>
                {type === "C" && (
                    <div 
                        className="my_sc_btn"
                        onClick={() => {
                            navigate(routes.mySchedule);
                        }}
                        style={{ width: screenWidth > 370 ? 96 : 50 }}
                    >
                        <img src={require("../../assets/images/icons/plus_icon.svg").default} style={{ marginRight: screenWidth > 370 ? 4 : 0 }}/>
                        {screenWidth > 370 && <p>내스케줄</p>}
                    </div>
                )}
                {type === "C" && (
                    <img
                        className="add_circle_line_icon"
                        src={require("../../assets/images/icons/add-circle-line.svg").default}
                        onClick={() => {
                            scFunc();
                        }}
                    />
                )}
                <Calendar
                    onChange={onChange} // useState로 포커스 변경 시 현재 날짜 받아오기
                    formatDay={(locale, date) => moment(date).format("D")} // 날'일' 제외하고 숫자만 보이도록 설정
                    formatMonthYear={(locale, date) => moment(date).format("YYYY.M")}
                    value={clickDate ? value : ''}
                    calendarType="hebrew"
                    minDetail="month"
                    maxDetail="month"
                    nextLabel={
                        <img src={require("../../assets/images/icons/next.svg").default} />
                    }
                    prevLabel={
                        <img src={require("../../assets/images/icons/prev.svg").default} />
                    }
                    next2Label={null}
                    prev2Label={null}
                    navigationLabel={null}
                    className="mx-auto w-full text-sm border-b"
                    onClickDay={(day) => {
                        if(clickDate === moment(day).format('YYYY-MM-DD')) {
                            setClickDate('');
                            setClickDay('');
                        } else {
                            setClickDate(moment(day).format('YYYY-MM-DD'));
                            setClickDay(moment(day).day())
                        }
                    }}
                    tileContent={({ date, view }) => {
                        let html = [];
                        let day = '';
                        let htmlData = [];
                        
                        if(moment(date).day() === 1) day = '0'
                        if(moment(date).day() === 2) day = '1'
                        if(moment(date).day() === 3) day = '2'
                        if(moment(date).day() === 4) day = '3'
                        if(moment(date).day() === 5) day = '4'
                        if(moment(date).day() === 6) day = '5'
                        if(moment(date).day() === 0) day = '6'

                        htmlData = scData?.filter((x, i) => 
                            (
                                x?.week_day === day && 
                                x?.sc_stime != "" && 
                                x?.sc_etime != "" &&
                                x?.sc_date <= moment(date).format('YYYY-MM-DD') + " 23:59:59"
                            ) 
                            ||
                            (
                                type === 'C' &&
                                x?.type === 'C' &&
                                x?.sc_date <= moment(date).format('YYYY-MM-DD') + " 23:59:59" &&
                                ((x?.week_day && x?.week_day === day)
                                ||
                                (!x?.week_day && x?.sc_date == moment(date).format('YYYY-MM-DD')))
                            )
                        );
                        
                        // console.log(htmlData);
                        // console.log(moment(date).format('YYYY-MM-DD'));

                        {htmlData && htmlData?.length > 0 &&
                            html.push(
                                <div className="content">
                                    <>
                                        {/* <p>{htmlData[0]?.name}</p> */}
                                        <div className="cal_content_text_box">
                                            <p>{htmlData?.length}</p>
                                            {/* {htmlData?.length > 1 && 
                                                <p style={{ paddingRight: 1 }}>외</p>
                                            }  */}
                                            <p>건</p>
                                        </div>
                                        
                                    </>
                                </div>
                            )
                        };


                        return (
                            <>  
                                {clickDate &&
                                    <div className="dot"></div>
                                }
                                <div className="flex justify-center items-center absoluteDiv">
                                    {html}
                                </div>
                            </>
                        );
                    }}
                />
                
                <CalendarBox 
                    value={value} 
                    scheduleTimeSet={scheduleTimeSet} 
                    cusSchedule={cusSchedule}
                    clickDate={clickDate}    
                    setClickVal={() => { setClickDate('') }}
                />
                
            </div>
            <TabBar />
        </div>
    );
};
