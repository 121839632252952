import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./Router";

import "./assets/css/common.css";
import "./assets/css/layout.css";
// import "./assets/css/layout1.css";

function App() {
  useEffect(() => {}, []);

  // ReactGA.event({
  //     category: 'User',
  //     action: 'Created an Account'
  // });
  // ReactGA.exception({
  //     description: 'An error ocurred',
  //     fatal: true
  // });

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
