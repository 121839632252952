import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";


export default function UserProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const userid = location?.state?.userid;

    const { mbData } = useSelector((s) => s.usersReducer);

    const [data, setData] = useState([]);

    const [editView, setEditView] = useState(false);
    const [healthData, setHealthData] = useState({});
    const [idx, setIdx] = useState("");
    const [edit01, setEdit01] = useState("");
    const [edit02, setEdit02] = useState("");
    const [edit03, setEdit03] = useState("");
    const [edit04, setEdit04] = useState("");
    const type = state.mbData.type;
    const token = state.accessToken;

    useEffect(() => {
        if(mbData?.type === 'C') navigate(-1);
        loadData();
    }, [editView]);

    const loadData = () => {
        let sender = {
            type: mbData?.type,
            member_id: userid,
        };

        // console.log('======> ', sender);

        dispatch(loadingStart());

        postData(`${consts.sc_matching_member_info}${token}`, sender).then((res) => {
            // console.log('result => ', res);

            if (res.result === "success") setData(res);
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });


        postData(`${consts.my_health_view}${token}`, sender).then((res) => {
            console.log('info ========> ', res);

            if (res.result === "success") {
                setIdx(res.idx);
                setEdit01(res.now_disease);
                setEdit02(res.old_disease);
                setEdit03(res.purpose);
                setEdit04(res.etc);
            }

        }).catch((e) => {
            console.log(e);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const editUser = () => {
        setEditView(true);
    };

    const deleteUserPop = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={"해당 유저를 삭제하시겠습니까?"}
                        buttonCancel={"취소"}
                        button={"확인"}
                        noneLoading={true}
                        setFunc={deleteUser}
                    />
                ),
            })
        );
    };

    const deleteUser = () => {
        // 삭제할때 
        // matching, member_health, register_template, schedule, template_answer, template_question_sub 의 내용들을 삭제해야함
    };

    const saveFunc = () => {
        let sender = {
            member_id: userid,
            now_disease: edit01,
            old_disease: edit02,
            purpose: edit03,
            etc: edit04,
        };

        let url = consts.my_health_inst;
        if(idx) {
            url = consts.my_health_edit;
            sender.idx = idx;
        }

        // console.log('sender => ', sender);
        // return

        dispatch(loadingStart());

        postData(`${url}${token}`, sender).then((res) => {
            console.log(res);
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

        // setEditView(false);
    };

    return (
        <>
            {!editView ? (
                <>
                    <Header
                        leftBtn={true}
                        leftFunc={() => {
                            headerLeftFunc();
                        }}
                        title={"프로필 보기"}
                        rightText={"고객 삭제"}
                        rightFunc={() => {
                            deleteUserPop();
                        }}
                    />

                    <div className="profile_list">
                        <div className="profile_default" style={{ marginBottom: 12 }}>
                            <p className="title">기본정보</p>
                            <div className="profile_content_box">
                                <p className="title">이름</p>
                                <p className="content">{data?.member_name}</p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">생년월일</p>
                                <p className="content">{data?.member_birth?.substring(2, 8)}</p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">성별</p>
                                <p className="content">
                                    {data?.member_gender === "M" ? "남" : "여"}
                                </p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">신장</p>
                                <p className="content">
                                    {data?.member_height ? data?.member_height + "cm" : "-"}
                                </p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">체중</p>
                                <p className="content">
                                    {data?.member_weight ? data?.member_weight + "kg" : "-"}
                                </p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">휴대폰</p>
                                <p className="content">
                                    {data?.member_tel?.substring(0,3)}-{data?.member_tel?.substring(3, 7)}-{data?.member_tel?.substring(7, 11)}
                                </p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">주소</p>
                                <p className="content">{data?.member_address}</p>
                            </div>
                        </div>

                        <div className="profile_default">
                            <div className="profile_health_info">
                                <p className="title">건강정보</p>
                                <button className="profile_edit_button" onClick={editUser}>
                                    <img
                                        src={
                                            require("../../assets/images/icons/edit_icon_purple.svg")
                                                .default
                                        }
                                    />
                                    <p>편집</p>
                                </button>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">현재 병력</p>
                                <p className="content">{edit01}</p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">과거 병력</p>
                                <p className="content">{edit02}</p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">건강관리목적</p>
                                <p className="content">{edit03}</p>
                            </div>
                            <div className="profile_content_box">
                                <p className="title">특이사항</p>
                                <p className="content">{edit04}</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Header
                        leftBtn={true}
                        leftFunc={() => {
                            setEditView(false);
                        }}
                        title={"건강 기록"}
                    />
                    <div className="user_health_edit_box">
                        <TextAreaEdit
                            label={"현재 병력"}
                            value={edit01}
                            setValue={setEdit01}
                        />
                        <div style={{ height: 16 }} />
                        <TextAreaEdit
                            label={"과거 병력"}
                            value={edit02}
                            setValue={setEdit02}
                        />
                        <div style={{ height: 16 }} />
                        <TextAreaEdit
                            label={"건강관리목적"}
                            value={edit03}
                            setValue={setEdit03}
                        />
                        <div style={{ height: 16 }} />
                        <TextAreaEdit
                            label={"특이사항"}
                            value={edit04}
                            setValue={setEdit04}
                        />
                    </div>
                    <FooterBtn 
                        label={"저장"} 
                        setFunc={saveFunc} 
                    />
                </>
            )}
        </>
    );
}
