import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    numFormat,
    patternNickFunc,
    regEmail,
    regPassword,
    patternSpcInstar,
    regPhone,
} from "../../utils/utils";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";

export default function RegisterEmail(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    // 전문가인지, 일반인지 데이터가 없으면 보내버림
    if (!state?.user_type || !state?.marketing_yn) navigate(routes.register);

    const [email, setEmail] = useState("");
    const [goEmail, setGoEmail] = useState(false);
    const [num, setNum] = useState("");
    const [chkNum, setChkNum] = useState("");
    const [reNum, setReNum] = useState(false);
    const [error01, setError01] = useState("");
    const [error02, setError02] = useState("");

    useEffect(() => {

    }, []);

    const headerLeftFunc = () => {
        navigate(routes.registerTerms, {
            state: { user_type: state?.user_type, reg_type: state?.reg_type },
        });
    };

    const headerRightFunc = () => {
        navigate(routes.login);
    };

    const emailChk = () => {
        if(regEmail.test(email)) {
            let sender = {
                userid: email,
            };
            
            postData(consts.authorization_chk_id, sender).then((res) => {
                console.log('====>', res);
                // return;
                if(res?.result === "error") {
                    navigate(routes.registerChkId, { state: { email: email } });
                } else {
                    setChkNum(res?.code);
                    setGoEmail(true);
                }
            });
        } else {
            setError01("이메일을 다시 입력해주세요.");
            return;
        }
    };

    const numChk = () => {

        if(!num) {
            setError02("인증번호를 입력해주세요.");
        } else if(num != chkNum){
            setError02("인증번호가 틀렸습니다.");
        } else if(num == chkNum){
            navigate(routes.registerInput, {
                state: {
                    email: email,
                    user_type: state?.user_type,
                    reg_type: state?.reg_type,
                    marketing_yn: state?.marketing_yn,
                    apple_sub: state?.reg_type === 'A' ? state?.apple_sub : ''
                },
            });
        } else {
            setError02("오류가 발생 하였습니다.");
        }

    };

    const sendNum = () => {
        setReNum(false);
        setError02("");
        emailChk();
    };

    return (
        <>
            <Header
                title={"이메일 인증"}
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                rightTextBtn={"취소"}
                rightFunc={() => {
                    headerRightFunc();
                }}
            />
            {!goEmail ? (
                <>
                    <div className="register_box_title" style={{ marginBottom: 20 }}>
                        <p>계정 가입을 위해</p>
                        <p>이메일 인증을 진행해 주세요.</p>
                        {/* {state?.apple_sub} */}
                        {/* {state?.reg_type} */}
                    </div>

                    <div className="register_box02">
                        <Input 
                            width={'100%'}
                            value={email} 
                            setValue={setEmail} 
                            error={error01} 
                        />

                        <div style={{ height: 20 }} />

                        <Button01
                            width={'100%'}
                            label={"이메일 입력"}
                            setFunc={() => {
                                emailChk();
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="flex_container">
                        <div className="register_box_title" style={{ marginBottom: 20 }}>
                            <p>입력하신 메일로</p>
                            <p>인증번호를 발송했습니다.</p>
                        </div>

                        <div className="register_email" style={{ marginBottom: 20 }}>
                            <p>{email}</p>
                        </div>

                        <div className="register_box02">
                            <div className="chk_email_num" style={{ gap: 4 }}>
                                <Input
                                    width={226}
                                    value={num}
                                    setValue={setNum}
                                    placeholder={"인증번호"}
                                    timerChk={true}
                                    timerEndFunc={() => {
                                        setReNum(true);
                                    }}
                                    timerReStart={reNum}
                                    error02={error02}
                                />

                                <Button01
                                    width={80}
                                    height={44}
                                    label={reNum ? "재전송" : "확인"}
                                    setFunc={() => {
                                        reNum ? sendNum() : numChk();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
