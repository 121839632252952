import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { open, close } from "../redux/popupSlice";

export default function Popup({ }) {
    const dispatch = useDispatch();
    const {
        open,
        title,
        message,
        button,
        buttonCencle,
        onCancelPress,
        onPress,
        component,
    } = useSelector((state) => state.popupReducer);

    // 회색 영역 클릭시 종료되게끔
    useEffect(()=>{
        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    const handleClickOutside = (event) => {
        if(event.target.id === 'container_part') handleClose();
    }

    const handleClose = () => {
        dispatch(close());
    };

    return (
        <>
            <div className={open ? "popup show" : "popup"} id="container_part">
                {component ? (
                    <>{component}</>
                ) : (
                    <div className="popup_container">
                        <div className="popup_top">
                            <div className="popup_title_section">
                                <p className="popup_title">{title}</p>
                                <button
                                    type="button"
                                    className="exit_btn"
                                    onClick={() => {
                                        handleClose();
                                        onCancelPress && onCancelPress();
                                    }}
                                ></button>
                            </div>

                            <p className="popup_msg">{message}</p>
                        </div>

                        <div className="popup_btn">
                            {buttonCencle && (
                                <button
                                    type="button"
                                    className="popbtn cencle_btn"
                                    onClick={() => {
                                        handleClose();
                                        onCancelPress && onCancelPress();
                                    }}
                                >
                                    {buttonCencle}
                                </button>
                            )}
                            <button
                                type="button"
                                className="popbtn ok_btn"
                                onClick={() => {
                                    handleClose();
                                    onPress && onPress();
                                }}
                                style={{ width: buttonCencle ? "" : "100%" }}
                            >
                                {button}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
