import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import "moment/locale/ko";
import { getData, postData } from "../../utils/service/APIS";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import routes from "../../libs/routes";
import consts from "../../libs/consts";
import Header from "../parts/Header";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { calcBirth } from "../../utils/utils";

const options = [
    {
        name: "학력 선택",
    },
    {
        name: "고등학교 졸업",
        value: "1",
    },
    {
        name: "대학교졸업(2,3년)",
        value: "2",
    },
    {
        name: "대학교졸업(4년)",
        value: "3",
    },
    {
        name: "대학원 석사졸업",
        value: "4",
    },
    {
        name: "대학원 박사졸업",
        value: "5",
    },
];


const ProfilePreview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { mbData } = useSelector((s) => s.usersReducer);

    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    const token = state.accessToken;
    const userid = location?.state?.userid;

    const [data, setData] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [education, setEducation] = useState([]);
    const [pfImg, setpfImg] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let sender = {
            type: type,
            member_id: userid
        };
        console.log(sender);

        dispatch(loadingStart());

        postData(`/mypage.php?trace=member_detail&token=${token}`, sender).then((res) => { // 회원 상세
            console.log(res);
            if(res.result === "success") {
                const { result, birth, ...data } = res;
                data.birth = birth !== "" && birth ? calcBirth(birth) : birth;
                setData(data);
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, 100);
        }).catch((e) => {
            console.log(e);
        });

        getData(`/mypage.php?trace=certificate_view&token=${token}`).then((res) => { // 자격증
            // console.log('자격증 ==> ', res);
            if (res.result === "success") setCertificate(res.data);
            setTimeout(() => {
                dispatch(loadingEnd());
            }, 100);
        }).catch((e) => {
            console.log(e);
        });

        getData(`/mypage.php?trace=education_view&token=${token}`).then((res) => { // 학력정보
            console.log(res);
            if (res.result === "success") {
                setEducation(res.data);
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, 100);
        }).catch((e) => {
            console.log(e);
        });

        postData(`/mypage.php?trace=profile_view&token=${token}`, sender).then((res) => {
            console.log(res);
            if (res.result === "success" && res.data) {
                let imageUrlLists = [];

                for (let i = 0; i < res.data.length; i++) {
                    imageUrlLists.push(res.data[i].file_name);
                }

                setpfImg(imageUrlLists);
            }
            setTimeout(() => {
                dispatch(loadingEnd());
            }, 100);
        }).catch((e) => {
            console.log(e);
        });
    };

    const headerLeftFunc = () => {
        if(mbData?.type === 'M') {
            navigate(routes.profileEdit);
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="profile">
            <Header
                leftBtn={true}
                leftFunc={() => headerLeftFunc()}
                title={"프로필 미리보기"}
            />
            <div className="temple_week_detail">
                <div className="profile__previewElement profile__imgElement">

                    {data?.file_name?.replace(consts.upload_url_member, "") && 
                        <img 
                            src={
                                data?.file_name?.replace(consts.upload_url_member, "") ?
                                consts.base_url + data?.file_name
                                :
                                require("../../assets/images/icons/avatar.svg").default
                            } 
                            style={{ width: 62, borderRadius: '50%' }}
                            onClick={() => {
                                {mbData?.type === 'M' ? 
                                    navigate(routes.userProfile, { state: { userid: data?.member_id }})
                                    :
                                    navigate(routes.profilePreview, { state: { userid: data?.member_id }});
                                }
                            }}
                        />
                    }
                    
                    <div className="profile__defaultElement">
                        <div className="profile__defaultDetailInfoElement">
                            <div className="profile__nameElement">{data?.user_name}</div>
                            <div className="profile__defaultDetailElement">
                                ({data?.gender === "M" ? "남" : "여"}, {data?.birth}세)
                            </div>
                        </div>
                        <div>
                            <div className="profile__masterTypeElement">
                                {data.special_type === "1"
                                    ? "물리치료사"
                                    : data.special_type === "2"
                                        ? "필라테스"
                                        : "트레이너"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile__imageContainer">
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        loop={true}
                        loopedSlides={1}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                    >
                        {pfImg.length ? (
                            pfImg.map((v, i) => (
                                <SwiperSlide key={i}>
                                    <div className="profile_swiper">
                                        <img src={v} onClick={() => { }} />
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <SwiperSlide>
                                <img
                                    src={
                                        require("../../assets/images/icons/preview-img.svg").default
                                    }
                                    onClick={() => { }}
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className="profile__previewElement">
                    <div className="profile__title">근무지</div>
                    <div>
                        {data.work_name}
                        {data.address && "(" + data.address + ")"}
                    </div>
                </div>
                <div className="profile__divide"></div>
                <div className="profile__previewElement">
                    <div className="profile__title">학력</div>
                    <div>
                        {education &&
                            education.map((v, i) => (
                                <div key={i}>
                                    {v.education_nm}(
                                    {options.filter((x) => x.value === v.education_type)[0].name})
                                    {v.education_type !== "1" &&
                                        v.education_major &&
                                        ", " + v.education_major}
                                    {v.education_type !== "1" &&
                                        v.education_submajor &&
                                        "(" + v.education_submajor + ")"}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="profile__divide"></div>
                <div className="profile__previewElement">
                    <div className="profile__title">경력사항</div>
                    <div>{data.career}</div>
                </div>
                <div className="profile__divide"></div>
                <div className="profile__previewElement">
                    <div className="profile__title">자격증</div>
                    <div>
                        {certificate &&
                            certificate.map((v, i) => <div key={i}>{v.certificate_nm}</div>)}
                    </div>
                </div>
                <div className="profile__divide"></div>
                <div className="profile__previewElement">
                    <div className="profile__title">소개</div>
                    <div>{data.introduction}</div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePreview;
