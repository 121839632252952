import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import { logIn, logOut } from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

export default function Popup01(props) {
    const { 
        text, 
        width, 
        button, 
        buttonCancel, 
        func, 
        setFunc, 
        noneLoading, 
        top,
        cancelFunc,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!buttonCancel && !button && top) {
            const popup = document.querySelector(".popup.show");
            console.log(popup);
            if (popup) popup.classList.add("popup_top");
            const timeout = setTimeout(() => {
                dispatch(close());
            }, 1000);
            popup.addEventListener("click", () => {
                dispatch(close());
                clearTimeout(timeout);
            });
        }
    }, []);

    const setFunction = () => {
        if(setFunc) setFunc();
        dispatch(close());
    };

    return (
        <>
            <div className={"popup_default01"} style={{ width: isBrowser ? 350 : width, marginLeft: 10, marginRight: 10 }}>
                <div
                    className="popup_text_box"
                    style={!buttonCancel && !button ? { padding: "0" } : {}}
                >
                    <p>{text}</p>
                </div>
                <div className="popup_btn_box">
                    {buttonCancel && (
                        <button
                            className="button_cancel"
                            onClick={() => {
                                if (func === "setRightFunc") {
                                    setFunction();
                                } else {
                                    if(cancelFunc) cancelFunc();
                                    dispatch(close());
                                }
                            }}
                        >
                            {buttonCancel}
                        </button>
                    )}
                    {button && (
                        <button
                            className="button_success"
                            onClick={() => {
                                if (func === "setRightFunc") {
                                    dispatch(close());
                                } else {
                                    setFunction();
                                }
                            }}
                        >
                            {button}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
}
