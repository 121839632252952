import React, { useEffect, useState }  from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux'
import routes from "./libs/routes";
import axios from "axios";
import { BrowserView, MobileView } from 'react-device-detect';

import Layout from "./Layout"

/* ====== redux ====== */

/* =================== */

import Loading from './redux-components/Loading';
import Popup from './redux-components/Popup';

// import SubPopup from './redux-components/SubPopup';


function Router() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { banner, boardCate, searchList, mbData, recommnedList, issueList, accessToken } = useSelector(s => s.usersReducer);

    const [load, setLoad] = useState(false);

    axios.defaults.headers.common["Authorization"] = accessToken;

    useEffect(() => {
        // console.log("router !! => ", userData);
        setLoad(true);
    }, []);

    const naviFunc = (type) => {
        navigate(routes.registerPronoteTerms, { state: { main_type: type } });
    }
    
    return (
        <>  
            <BrowserView>
                <div className='pc_web_background'>
                    <div className='pc_web_text_box'>
                        <img src={require("./assets/images/icons/main_pc_logo.svg").default} />
                        <p><span>ProNote</span>는 건강관리 전문가(치료사, 운동트레이너)와</p>
                        <p>고객(환자, 일반인 등) 모두가 사용할 수 있는 개인 맞춤형 헬스케어 서비스 입니다.</p>
                        <p>치료(훈련) 스케쥴 설정, 수행기록 저장, 평가결과 저장, 저장 기록 통계 열람 등</p>
                        <p>모바일 재활 운동 프로그램을 운영 환경을 제공합니다.</p>
                    </div>
                      


                    <div className='pc_web_text_box02'>
                        <div className='company_info'>
                            <p className='company_info_title'>상호명</p>
                            <p>비에스바이오텍(BSBIOTEC)</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>대표자</p>
                            <p>황선홍</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>주소</p>
                            <p>경기도 화성시 동탄문화센터로 61, 아시아프라자 785호</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>전화</p>
                            <p>010-4828-0725</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>팩스</p>
                            <p>0504-445-8301</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>이메일</p>
                            <p>bsbiotec2023@gmail.com</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>사업자등록번호</p>
                            <p>674-20-01749</p>
                        </div>
                        <div className='company_info'>
                            <p className='company_info_title'>통신판매업신고번호</p>
                            <p>2024-화성동탄-0674</p>
                        </div>
                        <div className='main_pc_info_box'>
                            <p onClick={() => { naviFunc(1) }}>이용안내</p>
                            <p onClick={() => { naviFunc(2) }}>개인정보취급방침</p>
                            <p onClick={() => { naviFunc(3) }}>이용약관</p>
                        </div>
                    </div>
                    <div className='pc_web_background_box'>
                        <Routes>
                            <Route exact path="*" element={ <Layout /> } />
                        </Routes>
                        <Loading />
                        <Popup />
                    </div>
                </div>
                
            </BrowserView>

            <MobileView>
                <Routes>
                    <Route exact path="*" element={ <Layout /> } />
                </Routes>
                
                <Loading />
                <Popup />
            </MobileView>
            
        </>
    )
}

export default Router;