import { useEffect, useState } from "react";

const SelectBox = (props) => {

    const  {
        data,
        index,
        setValue,
        setIndex,
        Width,
        defaultText
    } = props;

    useEffect(() => {

    }, []);

    const setData = (v, i) => {

    };

    const [view, setView] = useState(false);

    return (
       <div className="select_container" style={{ width: Width ? Width : '' }}>
            <div className="select_box" onClick={() => { setView(!view) }}>
                {data?.filter((x, i) => i == index)[0] ? 
                    <p>{data?.filter((x, i) => i == index)[0]}</p>
                    :    
                    <p>{defaultText ? defaultText : '선택하세요.'}</p>
                }
                {data?.length > 1 ?
                    <img src={require("../assets/images/icons/down_arrow_24.svg").default} />
                    :
                    <div style={{ height: 24 }}/>
                }
            </div>

            {(data?.length > 1 && view) && (
                <div className="sel_option_box">
                    {data?.length > 0 && data?.map((x, i) => {
                        return(
                            <p key={i} onClick={() => { 
                                if(setValue) setValue(x);
                                if(setIndex) setIndex(i);
                                setView(!view); 
                            }}>{x}</p>
                        )
                    })}
                </div>
                // <div className="sel_option_box">
                //     <p>데이터가 없습니다.</p>
                // </div>
            )}
        </div>
    );
};

export default SelectBox;
