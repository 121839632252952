const exportData = {
    main: "/",
    login: "/login",
    register: "/register",
    registerTerms: "/registerTerms",
    registerPronoteTerms: "/registerPronoteTerms",

    registerEmail: "/registerEmail",
    registerChkId: "/registerChkId",
    registerInput: "/registerInput",
    registerInputSns: "/registerInputSns",
    SearchInfo: "/SearchInfo",
    searchPass: "/searchPass",
    memberSearch: "/memberSearch",
    memberList: "/memberList",
    userDetail: "/userDetail",
    userDetail02: "/userDetail02", // 고객이 캘린더에서 전문가와의 스케줄 리스트에서 스케줄을 눌렀을 경우
    userProfile: "/userProfile",
    userSchedule: "/userSchedule",
    userTraining: "/userTraining",
    userTemplateList: "/userTemplateList",
    userTemplateDetail: "/userTemplateDetail",
    userTemplateDetailEx: "/userTemplateDetailEx",
    userTemplateWeek: "/userTemplateWeek",
    userTemplateWeekDetail: "/userTemplateWeekDetail",

    userResultList: "/userResultList",
    userResultDetail: "/userResultDetail",
    userResultDetailEx: "/userResultDetailEx",
    statisticsList: "/statisticsList",

    calendar: "/calendar",
    scheduleInput: "/scheduleInput",
    mySchedule: "/mySchedule",
    mypage: "/mypage",
    profileEdit: "/profileEdit",
    profileEditCus: "/profileEditCus",
    profilePreview: "/profilePreview",
    profileEducation: "/profileEducation",
    profileCareer: "/profileCareer",
    profileCertificate: "/profileCertificate",
    profileIntroduction: "/profileIntroduction",
    profileDefaultInfo: "/profileDefaultInfo",
    membership: "/membership",
    membershipsuc: "/membershipsuc",
    membershipfail: "/membershipfail",

    // companyInfo:"/companyInfo",
    // searchList: "/searchList/:val",
    // board: "/board/:cate_id",
};

export default exportData;
