import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import "moment/locale/ko";
import { getData, postData } from "../../utils/service/APIS";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { open, close } from "../../redux/popupSlice";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Popup01 from "../popup/Popup01";

const ProfileIntroduction = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const type = state.mbData.type;
    var token = state.accessToken;
    const [data, setData] = useState(location.state.data);

    useEffect(() => { }, []);

    const headerLeftFunc = () => {
        navigate(routes.profileEdit);
    };

    const footerBtn = () => {
        console.log(data);
        const sender = {
            type: type,
            introduction: data,
        };
        postData(`/mypage.php?trace=introduction_edit&token=${token}`, sender).then((res) => {
            
            if(res.result === "success") {
                dispatch(open({
                    component: (
                        <Popup01
                            width={350}
                            text={res.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    )
                }));

            } else {

            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <div className="profile">
            <Header leftBtn={true} leftFunc={() => headerLeftFunc()} title={"소개"} />
            <div className="profile__textarea">
                <textarea
                    placeholder="자기소개를 작성해 주세요"
                    onChange={(e) => setData(e.target.value)}
                >
                    {data}
                </textarea>
            </div>
            <FooterBtn greyChk={!true} setFunc={footerBtn} btnName="저장" />
        </div>
    );
};

export default ProfileIntroduction;
