import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    numFormat,
    patternNickFunc,
    regEmail,
    regPassword,
    regNameChk,
    regPhoneChk,
    patternSpcInstar,
    regPhone,
} from "../../utils/utils";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import InputFile from "../../components/InputFile";
import InputProFileImage from "../../components/InputProFileImage";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { postData } from "../../utils/service/APIS";

export default function RegisterInput(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    // if(!state?.user_type) navigate(routes.register);

    const [pfImg, setpfImg] = useState(""); // 이미지 base64파일
    const [checkKey, setCheckKey] = useState(""); // 이미지 check key
    const [pass, setPass] = useState("");
    const [passChk, setPassChk] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [err01, setErr01] = useState("");
    const [err02, setErr02] = useState("");
    const [err03, setErr03] = useState("");
    const [err04, setErr04] = useState("");

    const [chk01, setChk01] = useState(false);
    const [chk02, setChk02] = useState(false);
    const [chk03, setChk03] = useState(false);

    const [sucChk, setSucChk] = useState(false);
    const [sucData, setSucData] = useState([]);

    useEffect(() => { }, [pfImg]);

    // console.log(state);

    const goLeftFunc = () => {
        navigate(routes.registerTerms);
    };

    const goLoginFunc = () => {
        navigate(routes.login);
    };

    useEffect(() => {
        if(state?.reg_type === 'S') {
            passChkFunc();
        } else if(state?.reg_type === 'K' || state?.reg_type === 'N' || state?.reg_type === 'G' || state?.reg_type === 'A') {
            setChk01(true);
        }
        if(state?.reg_type != 'A'){
            nameChkFunc();
            phoneChkFunc();
        } else {
            setChk02(true);
            setChk03(true);
        }
    }, [pass, passChk, name, phone]);

    const passChkFunc = () => {
        if(!pass && !passChk) {
            setChk01(false);
            return;
        }

        if(!regPassword(pass)) {
            setChk01(false);
            setErr01("비밀번호는 8~12자리 영문,숫자,특수문자 혼합");
            return;
        }
        setErr01("");

        if(!regPassword(pass)) {
            setChk01(false);
            setErr01("비밀번호는 8~12자리 영문,숫자,특수문자 혼합");
            return;
        }

        if(pass !== passChk) {
            setChk01(false);
            setErr02("비밀번호가 일치하지 않습니다.");
            return;
        }

        setChk01(true);
        setErr02("");
    };

    const nameChkFunc = () => {
        if(!name) {
            setChk02(false);
            return;
        }

        if (!regNameChk(name)) {
            setChk02(false);
            setErr03("2~10자 한글,영문으로 입력하세요.");
            return;
        }

        setChk02(true);
        setErr03("");
    };

    const phoneChkFunc = () => {
        if(!phone) {
            setChk03(false);
            return;
        }

        if (!regPhoneChk(phone)) {
            setChk03(false);
            setErr04("휴대폰 형식이 올바르지 않습니다.");
            return;
        }

        if (phone.length !== 11) {
            setChk03(false);
            setErr04("11자 숫자로 입력해주세요");
            return;
        }
        setChk03(true);
        setErr04("");
    };

    const naviFunc = () => {
        if (chk01 && chk02 && chk03) {
            // 여기에서 회원가입 데이터 전송
            let sender = {
                type: state.user_type,
                userid: state?.reg_type === 'A' ? state?.apple_sub : state.email, // "Felice00@canweb.co.kr",
                username: state?.reg_type === 'A' ? state?.applename : name,
                userpass: pass,
                userpassd: passChk,
                usertel: phone,
                check_key: checkKey, //?????????????????????
                reg_channel: state?.reg_type,
                marketing_yn: state?.marketing_yn,
                apple_sub: state?.apple_sub ? state?.apple_sub : ''
                // android_id: "247972d2fb31d25c",
                // ios_id: "B6EBC1C1-3BD2-4B5C-BBDC-F4CA2EB61EAE",
                // firebasetoken: "ej0yneHJ8w4:APA91bG_EE27MY97KMJdUVQWQ61f386V-9mzMAflBP4DVte9as661nbZymQf8DviKaOBHaAbfYRrez1I7iZVzkEZ3TSnLNE8TSDpRVscWZOFgq8gVG3r7pSPtFyxBecmQ0p5ym6vZqdj",
            };
            // console.log(sender);
            // return;
            
            // dispatch(loadingStart());

            postData(consts.authorization_sign_up, sender).then((res) => {
                console.log(res);
                // return;
                if(res.result === 'success') {
                    setSucChk(true);
                    setSucData(res);
                } else {
                    setErr04(res?.message);
                }

                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.lasy);

            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.finalLasy);
            });
        }
    };

    const regiSucFunc = () => {

        dispatch(loadingStart());

        dispatch(
            logIn({
                mbData: {
                    idx: sucData?.idx,
                    type: sucData?.type,
                    username: sucData?.username,
                    userid: sucData?.idx,
                },
                accessToken: sucData?.accessToken,
                autoLogin: moment(),
            })
        );
          
        setTimeout(() => {
            dispatch(loadingEnd());
            navigate(routes.calendar);
        }, consts.lasy);
    }

    return (
        <>
            {!sucChk ? (
                <>
                    <Header
                        leftBtn={true}
                        leftFunc={() => {
                            goLeftFunc();
                        }}
                        title={"계정 만들기"}
                        rightXBtn={true}
                        rightFunc={() => {
                            goLoginFunc();
                        }}
                    />
                    <div className="flex_container" style={{ marginBottom: "20px" }}>
                        <div className="register_box_title" style={{ marginBottom: 40 }}>
                            <p>계정에 사용할</p>
                            <p>{state?.reg_type != 'A' ? '정보를 등록해 주세요.' : '프로필을 등록해 주세요.'}</p>
                        </div>

                        <div className="register_avatar_col" style={{ marginBottom: 40 }}>
                            {state?.reg_type != 'A' &&
                                <p className="register_info_title">이메일 ID</p>
                            }
                            {state?.reg_type != 'A' &&
                                <p className="register_info_email">{state?.email}</p>
                            }

                            <InputProFileImage
                                value={checkKey}
                                setValue={setCheckKey}
                                imgvalue={pfImg}
                                setImgValue={setpfImg}
                            />
                        </div>

                        <div>
                            {state?.reg_type === 'S' &&
                                <>
                                    <div style={{ marginBottom: 10 }}>
                                        <Input
                                            type={"password"}
                                            label={"비밀번호"}
                                            placeholder={"비밀번호(8~12자리 영문,숫자,특수문자 혼합)"}
                                            value={pass}
                                            setValue={setPass}
                                            maxlength={12}
                                            error={err01}
                                        />
                                    </div>
                                    <div style={{ marginBottom: 20 }}>
                                        <Input
                                            type={"password"}
                                            value={passChk}
                                            placeholder={"비밀번호 재입력"}
                                            setValue={setPassChk}
                                            maxlength={12}
                                            error={err02}
                                        />
                                    </div>
                                </>
                            }

                            {state?.reg_type != 'A' && 
                                <div style={{ marginBottom: 20 }}>
                                    <Input
                                        label={"이름"}
                                        value={name}
                                        placeholder={"이름 입력"}
                                        setValue={setName}
                                        maxlength={10}
                                        error={err03}
                                    />
                                </div>
                            }
                            
                            {state?.reg_type != 'A' && 
                                <div>
                                    <Input
                                        label={"휴대폰번호"}
                                        value={phone}
                                        setValue={setPhone}
                                        maxlength={11}
                                        placeholder={"“-”없이 입력하세요."}
                                        error={err04}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <FooterBtn
                        greyChk={!chk01 || !chk02 || (!chk03 && true)}
                        setFunc={naviFunc}
                    />
                </>
            ) : (
                <>
                    <div className="suc_container">
                        {/* <p className="suc_title">PreNote</p> */}
                        <img
                            src={require("../../assets/images/icons/pro_note_n_icon.svg").default}
                            style={{ width: 125, marginBottom: 40 }}
                        />
                        <img
                            src={pfImg ? pfImg : require("../../assets/images/icons/avatar.svg").default}
                            style={{ width: 72, height: 72, borderRadius: '50%', objectFit: 'cover', marginBottom: 40 }}
                        />
                        <p className="suc_name">{state?.reg_type === 'A' ? state?.applename : name} 님</p>
                        <p className="suc_title02">회원가입을 환영합니다.</p>
                        <Button01
                            label={"시작하기"}
                            setFunc={() => {
                                regiSucFunc()
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
}
