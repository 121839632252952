import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserTemplateDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);
    
    const type = state?.mbData?.type;
    const token = state?.accessToken;
    const tem_id = location?.state?.id;
    const member_id = location?.state?.member_id;

    const [data, setData] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(loadingStart());

        const sender = {
            type: type,
            reg_type: "P",
            idx: tem_id,
            member_id: member_id
        };

        postData(`${consts.tem_register_view}${token}`, sender).then((res) => {
            console.log('result =>> ', res?.data[0]);
            setData(res?.data[0]);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = () => {
        navigate(routes.userTemplateDetailEx,  { state: { id: tem_id, member_id: member_id, tem_title: data?.title, tem_data: data } });
    }

    const savePopFunc = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={"해당 템플릿을 사용하시겠습니까?"}
                        buttonCancel={"아니오"}
                        button={"네"}
                        func={"setFunc"}
                        setFunc={() => { saveFunc() }}
                        noneLoading={true}
                    />
                ),
            })
        );
    }

    const saveFunc = () => {
        const sender = {
            type: type,
            reg_type: "P",
            register_idx: tem_id,
            member_id: member_id,
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_register_template_mem_save}${token}`, sender).then((res) => {
            console.log('result =>> ', res);

            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={res?.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );

            setTimeout(() => {
                dispatch(loadingEnd());
                // navigate(routes.userDetail, { state: { cus_email: member_id } }) 
                navigate(-1);
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"경과기록 템플릿"}
            />
            <div style={{ padding: 20 }}>
                <div className="tem_detail_box">
                    <div className="tem_detail_title">
                        <p>{data?.title}({data?.week}주)</p>
                    </div>
                    <div className="tem_detail_content">
                        <p className="title">대상자</p>
                        <div className="content" dangerouslySetInnerHTML={{__html: data?.target}} />
                    </div>
                    <div className="tem_detail_content" style={{ border: 'none' }}>
                        <p className="title">소개</p>
                        <div className="content" dangerouslySetInnerHTML={{__html: data?.intro}} />
                    </div>
                    {data?.file_name1 && data?.file_name1 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name1 } />
                        </div>
                    }
                    {data?.file_name2 && data?.file_name2 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name2 } />
                        </div>
                    }
                    {data?.file_name3 && data?.file_name3 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name3 } />
                        </div>
                    }
                    {data?.file_name4 && data?.file_name4 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name4 } />
                        </div>
                    }
                    <div style={{ marginTop: 8 }}>
                        <YouTube
                            videoId={data?.youtube?.replace(consts.you_url, "")}
                            opts={{ // 플레이어의 크기나 다양한 플레이어 매개 변수를 사용할 수 있음.
                                width: "100%",
                                height: "200",
                                playerVars: {
                                autoplay: 0, //자동재생 O
                                rel: 0, //관련 동영상 표시하지 않음
                                modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                                },
                            }}
                            //이벤트 리스너 
                            onEnd={(e)=>{  }}      
                        />
                    </div>
                </div>
            </div>
            <div style={{ height: 50 }}/>
            <div className="bottom_btn_box" style={{ width: isBrowser ? 390 : '100%' }}>
                <button className="temple_btn01" onClick={() => { naviFunc() }}>탬플릿보기</button>
                <button className="temple_btn02" onClick={() => { savePopFunc() }}>사용하기</button>
            </div>
        </>
    );
}
