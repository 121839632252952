import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { open, close } from "../../redux/popupSlice";

import { getData, postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";
import Popup01 from "../popup/Popup01";
import ProfileInput from "../../components/ProfileInput";
import Icon from "../../components/Icon";

const exampleData = [
    {
        certificate_nm: "MOS 자격증",
    },
    {
        certificate_nm: "운전 면허 1종 보통",
    },
    {
        certificate_nm: "정보처리 기사 2급",
    },
];

const ProfileCareer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    var token = state.accessToken;

    const [data, setData] = useState(location.state.data);
    const [dataList, setDataList] = useState([]);
    const [err01, setErr01] = useState('');

    if (!token) navigate(routes.login);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(loadingStart());

        getData(`/mypage.php?trace=certificate_view&token=${token}`).then((res) => {
            
            if (res.result === "success" && res.data) {
                setDataList(res.data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
            setDataList(exampleData);
        });
    };

    const headerLeftFunc = () => {
        navigate(routes.profileEdit);
    };

    const footerBtn = () => {
        dispatch(loadingStart());

        postData(`/mypage.php?trace=certificate_edit&token=${token}`, dataList.length ? dataList : [{}]).then((res) => {

            if(res.result === "success") {
                dispatch(open({
                    component: (
                        <Popup01
                            width={350}
                            text={res.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    )
                }));
            }
            
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        });
    };

    const addData = () => {
        
        if(!data) {
            setErr01('자격증을 입력해주세요.');
            return;
        }
        setErr01('');

        if(data?.length === 0) return;

        let dataList1 = [...dataList];
        dataList1.push({ certificate_nm: data });

        setDataList(dataList1);
        setData("");
    };

    const deleteData = (v) => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={"삭제하시겠습니까?"}
                        buttonCancel={"아니오"}
                        button={"네"}
                        func={"setFunc"}
                        setFunc={() => {
                            let dataList1 = [...dataList];
                            dataList1 = dataList1.filter((data) => data !== v);
                            setDataList(dataList1);
                        }}
                        noneLoading={true}
                    />
                ),
            })
        );
    };

    return (
        <div className="profile">
            <Header
                leftBtn={true}
                leftFunc={() => headerLeftFunc()}
                title={"자격증"}
            />
            <div className="profile_certificateContainer">
                <ProfileInput
                    value={data}
                    setValue={setData}
                    label={"자격증"}
                    placeholder={"자격증 입력"}
                    error={err01}
                >
                    <div className="profile__certificateButton" onClick={addData}>
                        <Icon
                            icon={"add-line.svg"}
                            filter={
                                "invert(19%) sepia(75%) saturate(3411%) hue-rotate(250deg) brightness(113%) contrast(104%)"
                            }
                        />
                        <div>자격증 등록</div>
                    </div>
                </ProfileInput>
                <div className="profile__certificateElements">
                    {dataList.map((v, i) => (
                        <div key={i} className="profile__certificateElement">
                            <div>{v.certificate_nm}</div>
                            <div
                                onClick={() => {
                                    deleteData(v);
                                }}
                                className="addIcon"
                            >
                                <Icon icon={"x_btn.svg"} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <FooterBtn greyChk={!true} setFunc={footerBtn} btnName="저장" />
        </div>
    );
};

export default ProfileCareer;
