import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Popup01 from "../popup/Popup01";

import Header from "../parts/Header";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import { kakaoPostData } from "../../utils/service/APIS";

// import InputSelectChk from "../../components/InputSelectChk";
// import BoardCardFeed from "../../components/BoardCardFeed";
// import BoardCardShadow from "../../components/BoardCardShadow";
// import ListLoading from "../../components/ListLoading";

export default function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const reg_type = location?.state?.reg_type;
    const sns_user_email = location?.state?.sns_user_email; // sns 로그인 이메일
    const applename = location?.state?.applename; // 애플 전용 로그인 했을때 가지고 가는 이름

    const [load, setLoad] = useState(false);
    const [moreLoad, setMoreLoad] = useState(false);
    const [mb_id, setmb_id] = useState("");
    const [mb_password, setmb_password] = useState("");
    const [autoLogin, setautoLogin] = useState(false);
    const [error1, seterror1] = useState("");
    const [error2, seterror2] = useState("");
    
    // const kakaoCode = new URL(window.location.href).searchParams.get("code"); // 카카오 로그인때 필요한 코드

    // // 카카오 로그인
    // useEffect(() => {
    //     if(kakaoCode) {
    //         console.log('카카오 로그인')
    //         getkakaoToken();
    //     }
    // }, [kakaoCode]);

    // const getkakaoToken = async () => {
    //     kakaoPostData(kakaoCode).then((res) => {
    //         console.log(res);
    //     }).catch((e) => {
    //         console.log('err ==> ', e);
    //     });
    // }

    // // 네이버 로그인 url 토큰 추출
    // const queryParams = window.location.hash.substring(1).split("&");
    // useEffect(() => {
    //     if(queryParams?.length > 1){
    //         console.log('네이버 로그인');
    //         let params = {};
    //         queryParams.forEach((param) => {
    //             const [key, value] = param.split("=");
    //             params[key] = value;
    //         });
    //         // console.log('실행 ~ ===> ', params);
    //     }
    // }, [queryParams]);



    const naviFunc = (type) => {
        // type => M=헬스케어 전문가, C=일반 사용자(고객)
        navigate(routes.registerTerms, {
            state: { user_type: type, reg_type: reg_type, sns_user_email: sns_user_email, applename: applename },
        });
    };

    const registerType = (type) => {
        // type => K=카카오, N=네이버, G=구글, A=애플
        console.log("type => ", type);
    };

    const headerRightFunc = () => {
        navigate(routes.login);
    };

    return (
        <>
            <Header
                title={"회원 유형"}
                rightTextBtn={"취소"}
                rightFunc={() => {
                    headerRightFunc();
                }}
            />
            <div className="register_box">
                <p className="register_title">회원 유형을 선택해주세요.</p>
                {/* <p>{reg_type}  // {sns_user_email}</p> */}
                <div className="register_btn_box">
                    <Button02
                        label={"헬스케어 전문가"}
                        setFunc={() => {
                            naviFunc("M");
                        }}
                    />
                    <Button02
                        label={"일반 사용자(고객)"}
                        borderType={true}
                        setFunc={() => {
                            naviFunc("C");
                        }}
                    />
                </div>
            </div>
        </>
    );
}
