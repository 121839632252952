import { Link } from "react-router-dom";

const TabBarButton = ({ name, working, href, url }) => {
    return (
        <Link to={href} className={href === working && "working"}>
            <img
                className="tabbar-img"
                src={require(`../assets/images/icons/${url}`)}
            />
            <div className="tabbar-name">{name}</div>
        </Link>
    );
};

export default TabBarButton;
