import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { BrowserView, MobileView } from 'react-device-detect';

import { getData, postData } from "../utils/service/APIS";

import routes from "../libs/routes";
import consts from "../libs/consts";

import TabBarButton from "./TabBarButton";
import { Rotate } from "hammerjs";

const TabBar = ({ working }) => {
    const location = useLocation();
    
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type; // M: 전문가, C: 유저
    const token = state.accessToken;

    const [btnChk, setBtnChk] = useState(false);

    useEffect(() => {
        loadData();
        // if(type === 'M') setBtnChk(true); // test용
    }, []);


    // 프로필 기본정보를 입력해야 이용 가능하게끔
    const loadData = () => {
        
        const sender = {
            type: type,
        };
        
        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {
            // console.log('마이페이지 ==> ', res);
            if (res.result === "success") {
                if( // 고객일 경우 확인해야하는 정보
                    res?.member_gender &&
                    res?.member_birth &&
                    res?.member_height &&
                    res?.member_weight && 
                    res?.member_address &&
                    res?.member_address_etc &&
                    type === 'C'
                ){
                    setBtnChk(true);
                }

                if(
                    res?.member_gender &&
                    res?.member_birth &&
                    res?.member_address &&
                    res?.member_address_etc &&
                    type === 'M'
                ){ // 전문가일 경우 확인해야하는 정보
                    setBtnChk(true);
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    };


    const tabBar = [
        {
            name: "스케줄",
            href: "/calendar",
            url: "calendar-todo-fill.svg",
        },
        {
            name: type === 'M' ? '고객' : '내전문가',
            href: btnChk ? routes.memberList : type === 'C' ? routes.profileEditCus : routes.profileEdit,
            url: "parent-line.svg",
        },
        {
            name: "통계",
            href: btnChk ? routes.statisticsList : type === 'C' ? routes.profileEditCus : routes.profileEdit,
            url: "bar-chart-box-line.svg",
        },
        {
            name: "마이",
            href: "/mypage",
            url: "user-3-line.svg",
        },
    ]

    return (
        <div>
            <BrowserView>
                <div className="tabbar" style={{ width: 390 }}>
                    {tabBar?.map((v, i) => (
                        <TabBarButton
                            key={i}
                            name={v.name}
                            working={location.pathname}
                            href={v.href}
                            url={v.url}
                        />
                    ))}
                </div>
            </BrowserView>

            <MobileView>
                <div className="tabbar" style={{ width: '100%' }}>
                    {tabBar?.map((v, i) => (
                        <TabBarButton
                            key={i}
                            name={v.name}
                            working={location.pathname}
                            href={v.href}
                            url={v.url}
                        />
                    ))}
                </div>
            </MobileView>
        </div>
    );
};

export default TabBar;
