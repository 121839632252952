import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import moment from "moment";

import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar';


export default function ChartBar(props) {

    const {
        Width,
        Height,
        chartData
    } = props;

    const [list, setList] = useState([]);

    useEffect(() => {
        let list = chartData?.map((x, i) => {
            return { cnt: x?.cnt + '회', tot: x?.tot }
        })
        if(list && list.length > 0 ) {
            setList(list);
        } else {
            setList([]);
        }
    }, [chartData]);

    return (
        <div className="chart_line_box" style={{ width: Width, height: Height }}>
            {list.length > 0 ?
                <ResponsiveBar
                    /**
                     * chart에 사용될 데이터
                     */
                    data={list}
                    /**
                     * chart에 보여질 데이터 key (측정되는 값)
                     */
                    keys={['tot']}
                    /**
                     * keys들을 그룹화하는 index key (분류하는 값)
                     */
                    indexBy="cnt"
                    /**
                     * chart margin
                     */
                    margin={{ top: 10, left: 40, right: 20, bottom: 30 }}
                    /**
                     * chart padding (bar간 간격)
                     */
                    padding={0.3}
                    colors={['#AA8CFF']} // 커스터하여 사용할 때
                    // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
                    /**
                     * color 적용 방식
                     */
                    colorBy="id" // 색상을 keys 요소들에 각각 적용
                    // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
                    theme={{
                        /**
                         * label style (bar에 표현되는 글씨)
                         */
                        labels: {
                            text: {
                                fontSize: 14,
                                fontWeight: 700,
                                fill: '#fff',
                            },
                        },
                        /**
                         * legend style (default로 우측 하단에 있는 색상별 key 표시)
                         */
                        legends: {
                            text: {
                                fontSize: 12,
                                fill: '#000000',
                            },
                        },
                        axis: {
                            /**
                             * axis legend style (bottom, left에 있는 글씨)
                             */
                            legend: {
                                text: {
                                    fontSize: 20,
                                    fill: '#000000',
                                },
                            },
                            /**
                             * axis ticks style (bottom, left에 있는 값)
                             */
                            ticks: {
                                text: {
                                    fontSize: 10,
                                    fill: '#000000',
                                },
                            },
                        },
                    }}
                />
                :
                <div className="not_list_box" style={{ paddingTop: 40 }}>
                    <img src={require("../assets/images/icons/notResult.svg").default}/>
                    <p>데이터가 없습니다.</p>
                </div>
            }
        </div>
    );
}
