import React, {useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RotatingPlane, Circle } from 'better-react-spinkit'

export default function Loading({}) {

    const dispatch = useDispatch();
    const { open } = useSelector(state => state.loadingReducer);

    return (
        <>
            <div className={open ? "loading show" : "loading"} >
                <Circle size={70} color={'#6D39FF'} />
            </div>
        </>
    );
}