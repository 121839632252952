import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserTemplateWeek() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);

    const token = state?.accessToken;
    const member_id = location?.state?.member_id;
    const tem_data = location?.state?.tem_data;
    const user_plus = location?.state?.user_plus; // 유저가 등록한 경과기록이면 유저가 사용 가능함
    const delete_chk = location?.state?.delete_chk;
    
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        if(!mbData?.type || !token) navigate(routes.login);
        loadData();
        // console.log('==> ', tem_data);
    }, []);

    const loadData = () => {
        let sender = {
            type: mbData?.type,
            register_idx: tem_data?.register_idx,
            register_template_idx: tem_data?.register_template_idx,
            member_id: member_id ? member_id : mbData?.userid 
        };
        
        console.log(sender);

        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_now_p_list}${token}`, sender).then((res) => {
            console.log('result 111111111 =>> ', res?.data);

            setDataList(res?.data);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const headerRightFunc = () => {
        dispatch(open({
            component: <Popup01 
                            width={380}
                            text={'삭제하시면 기록된 데이터도 삭제됩니다.\n삭제하시겠습니까?'}
                            buttonCancel={'취소'}
                            button={'확인'}
                            setFunc={deleteFunc}
                            noneLoading={true}
                        />,
        }));
    }

    const deleteFunc = () => {

        const sender = {
            type: mbData?.type, // M:전문가, C:고객
            reg_type: "P", // P:경과, E:평가
            register_idx: tem_data?.register_idx,
            register_template_idx: tem_data?.register_template_idx,
            member_id: member_id
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_template_answer_del}${token}`, sender).then((res) => {
            console.log('result =>> ', res);

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            deleteTempleFunc();

            // loadData();

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }

    const deleteTempleFunc = () => {
        const sender = {
            type: mbData?.type, // M:전문가, C:고객
            idx: tem_data?.register_template_idx,
            member_id: member_id
        };

        console.log(sender);

        postData(`${consts.tem_register_template_mem_del}${token}`, sender).then((res) => {
            // console.log('tetetetetresult =>> ', res);
            navigate(-1);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
        });
    }

    // const naviFunc = (week, cnt) => {
    //     navigate(routes.userTemplateWeekDetail, { 
    //         state: { 
    //             tem_data: tem_data,
    //             member_id: member_id,
    //             week_num: week,
    //             cnt_num: cnt,
    //             user_plus: user_plus
    //         } 
    //     });
    // }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={'기록보기'}
                rightTextBtn={
                    (mbData?.type === 'M' || user_plus === 'Y') ? '삭제' : ''
                }
                rightFunc={() => {
                    if(mbData?.type === 'M' || user_plus === 'Y') headerRightFunc();
                }}
            />

            <div style={{ padding: 20 }}>

                {dataList?.length > 0 && dataList?.map((x, i) => {
                    // console.log(x);
                    return (
                        <div key={i} className="temple_date_box" 
                            onClick={() => {
                                navigate(routes.userTemplateWeekDetail, { 
                                    state: { 
                                        tem_data: x,
                                        member_id: member_id,
                                        tem_title: tem_data?.title,
                                        user_plus: user_plus
                                    } 
                                });
                            }}
                        >
                            <div className="temple_date_box_left">
                                <img src={require("../../assets/images/icons/calendar_icon.svg").default} style={{ marginRight: 10 }}/>
                                <p>
                                    {moment(x?.register_date).format('YY.MM.DD')}
                                    {`(${consts.dayText[moment(x?.register_date).day()]})`}
                                </p>
                            </div>
                            <img src={require("../../assets/images/icons/right_btn_01.svg").default}/>
                        </div>
                    )
                })}

                {dataList?.length === 0 &&
                    <div className="not_list_box" style={{ paddingTop: 50 }}>
                        <img
                            src={require("../../assets/images/icons/notResult.svg").default}
                        />
                        <p>{'등록된 경과 기록이 없습니다.'}</p>
                    </div>
                }
                
            </div>
        </>
    );
}
