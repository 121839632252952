import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import { myScType, myPageListType } from '../../redux/mypageSlice';

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";
import Icon from "../../components/Icon";
import { type } from "@testing-library/user-event/dist/type";

export default function MySchedule() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);
    const { contentType, listType } = useSelector(s => s.mypageReducer);
    
    const token = state.accessToken;
    const scData = location?.state?.scData

    const [content, setContent] = useState(listType ? listType : 1);
    const [arrDate, setArrDate] = useState([]);
    const [cusSchedule, setCusSchedule] = useState([]);
    const [ingList, setIngList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [screenWidth, setScreenWidth] = useState(0);
    

    useEffect(() => {
        if(mbData?.type === 'M' || !mbData?.userid) navigate(-1);
        loadData();
    }, []);
    
    useEffect(() => {
        getUserScData();
    }, [content]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            // cleanup
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // 프로필 기본정보를 입력해야 이용 가능하게끔
    const loadData = () => {
        
        const sender = {
            type: mbData?.type,
        };
        
        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {
            // console.log('마이페이지 ==> ', res);

            if (res?.result === "success") {
                if(
                    !res?.member_gender || 
                    !res?.member_birth || 
                    !res?.member_height || 
                    !res?.member_weight || 
                    !res?.member_address || 
                    !res?.member_address_etc
                ){
                    if(mbData?.type === 'C') navigate(routes.profileEditCus);
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    };
    
    const getUserScData = () => {
        let sender = {};
        let apiUrl = consts.sc_my_schedule_view;

        if(content === 2 || content === 3) {
            apiUrl= consts.tem_register_template_mem_list;
            sender.type = mbData?.type;
            sender.reg_type = content === 2 ? "P" : "E" // P: 경과, E: 평가
            sender.page = "1";
            sender.num_per_page = "10";
            sender.member = mbData?.userid;
        }

        console.log(sender);

        dispatch(loadingStart());
        
        postData(`${apiUrl}${token}`, sender).then((res) => {

            console.log(res);
            
            if(content === 1) {
                let arr = res?.data?.map((x, i) => {
                    // console.log(x.sc_date);
                    return x.sc_date;
                })
                setArrDate(
                    arr?.filter((x, i) => {
                        return arr.indexOf(x) === i;
                    })
                );
                setCusSchedule(res?.data);
            } else if(content === 2) {
                setIngList(res?.data);
            } else if(content === 3) {
                setResultList(res?.data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        });

        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.finalLasy);
    }

    const naviFunc = () => {
        if (content === 2) navigate(routes.userTemplateList, { state: { member_id: mbData?.userid, reg_type: 'P' } });
        if (content === 3) navigate(routes.userResultList, { state: { member_id: mbData?.userid } });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const cusBox = (x, type) => {
        // console.log(x);
        return (
            <div 
                className="cus_sc_box" 
            >
                <div className="cus_sc_box02">
                    <img src={require("../../assets/images/icons/time-line.svg").default} style={{ marginRight: 8 }} />
                    <p className="sc_time_text" style={{ marginRight: 8 }}>{x?.sc_time}</p>
                    <p className="sc_time_text02">{x?.sc_content}</p>
                </div>
            </div>
        )
    }


    const returnFunc = (title) => {
        return (
            <div className="not_list_box">
                <img
                    src={require("../../assets/images/icons/notResult.svg").default}
                />
                <p>{title}</p>
            </div>
        )
    }


    const resultDeletePop = (val) => {
        dispatch(open({
            component: <Popup01 
                            width={380}
                            text={'평가를 삭제하면 답변 기록도 삭제됩니다\n삭제 하시겠습니까?'}
                            buttonCancel={'아니요'}
                            button={'확인'}
                            setFunc={() => { resultDelete(val) }}
                            noneLoading={true}
                        />,
        }));
    }

    const resultDelete = (val) => {
        let sender = {
            type: mbData?.type, // M:전문가, C:고객
            idx: val?.register_template_idx,
            member_id: mbData?.userid
        };

        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_register_template_mem_del}${token}`, sender).then((res) => {
            // console.log('tetetetetresult =>> ', res);

            dispatch(open({
                component: <Popup01 
                                width={380}
                                text={res?.message}
                                button={'확인'}
                                noneLoading={true}
                            />,
            }));

            getUserScData();

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
        });
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"나의 스케줄"}
            />

            <div className="user_content_box">
                <div className="user_content_list02">
                    <p
                        className={content === 1 && "active"}
                        onClick={() => {
                            setContent(1);
                            dispatch(myPageListType(1));
                        }}
                    >
                        일정
                    </p>
                    <p
                        className={content === 2 && "active"}
                        onClick={() => {
                            setContent(2);
                            dispatch(myPageListType(2));
                        }}
                    >
                        기록
                    </p>
                    <p
                        className={content === 3 && "active"}
                        onClick={() => {
                            setContent(3);
                            dispatch(myPageListType(3));
                        }}
                    >
                        평가
                    </p>
                </div>
            </div>

            {/* <div className="user_content_box">
                <div className="user_content_list02">
                    <p
                        className={content === 1 && "active"}
                        onClick={() => {
                            setContent(1);
                            dispatch(myScType(1));
                        }}
                    >
                        스케줄
                    </p>
                    <p
                        className={content === 2 && "active"}
                        onClick={() => {
                            setContent(2);
                            dispatch(myScType(2));
                        }}
                    >
                        경과기록
                    </p>
                    <p
                        className={content === 3 && "active"}
                        onClick={() => {
                            setContent(3);
                            dispatch(myScType(3));
                        }}
                    >
                        평가기록
                    </p>
                </div>
            </div> */}
            
            <div className="box-content">
                {content === 1 && 
                    <div>
                        {arrDate?.length > 0 && arrDate?.map((x, i) => {
                            return (
                                <div key={i} style={{ marginBottom: 20 }}>
                                    <div className="box-date02">
                                        <Icon icon={"calendar-check-line.svg"} />
                                        <div>{moment(x).format("M.D(dd)")}</div>
                                    </div>
                                    {content === 1 && cusSchedule?.length > 0 && cusSchedule?.filter((val, index) => val.sc_date == x)?.map((v, n) => {
                                        return(
                                            <div key={n} onClick={() => { navigate(routes.scheduleInput, { state: { scData: v } }) }}>
                                                {cusBox(v)}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        {(!cusSchedule || cusSchedule?.length === 0) &&
                            <div className="not-found-schedule" style={{ marginTop: 20 }}>
                                <Icon icon={"not-found-schedule.svg"} />
                                <div>등록된 스케줄이 없습니다.</div>
                            </div>
                        }
                    </div>
                }

                {content === 2 && 
                    <div style={{ paddingBottom: 100 }}>
                        <div className="day_line_content_list">
                            {ingList?.map((x, i) => {
                                // console.log('경과 => ', x);
                                return (
                                    <div 
                                        key={i}
                                        className="ing_record_box" 
                                        
                                    >
                                        <p className="record_title">{x?.title}({x?.week}주)</p>
                                        <p className="record_week_title">{x?.now_week ? x?.now_week : 1}주차 진행중</p>
                                        <div className="record_status_btn_box">
                                            <div 
                                                className="record_status_box"
                                                style={{ width: '50%'}}
                                                onClick={() => { 
                                                    navigate(routes.userTemplateWeek, { 
                                                        state: { 
                                                            tem_data: x, 
                                                            member_id: mbData?.userid,
                                                            user_plus: 'Y'
                                                        } 
                                                    });
                                                }}
                                            >
                                                <p>{screenWidth > 340 ? '경과기록 보기' : '기록보기'}</p>
                                            </div>
                                            <div 
                                                className="record_status_box"
                                                style={{ width: '50%'}}
                                                onClick={() => { 
                                                    navigate(routes.userTemplateWeekDetail, { 
                                                        state: { 
                                                            tem_data: x,
                                                            member_id: mbData?.userid,
                                                            user_plus: 'Y',
                                                            reg_type: 'new',
                                                        } 
                                                    });
                                                }}
                                            >
                                                <img src={require("../../assets/images/icons/plus_symbols_add.svg").default} /> 
                                                <p>{screenWidth > 340 ? '경과기록 추가' : '기록추가'}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {(!ingList || ingList?.length === 0) && returnFunc('경과기록이 없습니다.')}
                        </div>
                    </div>
                }

                {content === 3 && 
                    <div style={{ paddingBottom: 100 }}>
                        <div className="day_line_content_list">
                            {resultList?.map((x, i) => {
                                // console.log(x?.reg_date_list?.length);
                                return (
                                    <div key={i} className="ing_record_box">
                                        <div className="record_title_box">
                                            <p className="title">{x?.title}</p>
                                            <p className="del_btn"
                                                onClick={() => { 
                                                    resultDeletePop(x);
                                                }}
                                            >삭제</p>
                                        </div>

                                        {
                                            (x?.reg_date_list && x?.reg_date_list?.length > 0) && 
                                            x?.reg_date_list?.map((v, n) => {
                                                return(
                                                    <div className="record_list_box" style={{ marginBottom: 15 }}>
                                                        <p className="record_week_title02">
                                                            {x?.reg_date_list?.length-n}차 {`(${moment(v).format('YYYY.MM.DD')})`}
                                                        </p>
                                                        <p className="record_click_btn"
                                                            onClick={() => {
                                                                navigate(routes.userResultDetailEx,  { 
                                                                    state: { 
                                                                        result_data: x, 
                                                                        result_date: v, 
                                                                        member_id: mbData?.userid,
                                                                        // chkUse: true
                                                                    } 
                                                                });
                                                            }}
                                                        >
                                                            보기
                                                            {mbData?.type === 'M' && `(수정)`}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div 
                                            className="record_status_box"
                                            style={{ 
                                                width: '100%',
                                                marginTop: x?.reg_date_list?.length === 0 ? 25 : 20 
                                            }}
                                            onClick={() => { 
                                                navigate(routes.userResultDetailEx,  { 
                                                    state: { 
                                                        result_data: x, 
                                                        // result_date: moment().format('YYYY-MM-DD'), 
                                                        result_date: '2024-02-12', 
                                                        member_id: mbData?.userid,
                                                        delete_chk: 'N'
                                                    } 
                                                });
                                            }}
                                        >
                                            <img src={require("../../assets/images/icons/plus_symbols_add.svg").default} /> 
                                            <p>평가 추가</p>
                                        </div>
                                    </div>
                                )
                            })}

                            {(!resultList || resultList?.length === 0) && returnFunc('평가가 없습니다.')}
                            
                        </div>
                    </div>
                }


                {(content === 2 || content === 3) && 
                    <div className="display_flex_relative">
                        <Button02
                            iconType={"plus"}
                            label={content === 2 ? "기록 등록" : "평가 등록"}
                            setFunc={naviFunc}
                        />
                    </div>
                }
            </div>
        </>
    );
}
