import {createSlice} from '@reduxjs/toolkit';

const mypageSlice = createSlice({
  name: 'boardList',
  initialState: {
    contentType: 1,
    listType: 1
  },
  reducers: {
    myScType(state, action) {
        state.contentType = action.payload;
    },
    myPageListType(state, action) {
        state.listType = action.payload;
    }
  },
});

export const {
    myScType,
    myPageListType
} = mypageSlice.actions;
export default mypageSlice.reducer;
