import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import routes from "../libs/routes";

export default function FooterInput(props) {
    const {
        label,
        value = "",
        setValue,
        placeholder,
        maxlength,
        type,
        iref,
        disable,
        onBlur,
        bgColor,
        viewPass,
        onKeyPress,
        setFunc,
        setClose,
        placeHolderTitle,
    } = props;

    let popupRef = useRef();

    // 회색 영역 클릭시 종료되게끔
    useEffect(()=>{
        document.addEventListener('mousedown', handleClickOutside);
        return() => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    });

    const handleClickOutside = (event) => {
        if(event.target.id === 'container_part') setClose(false);
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const onKeyPressFunc = (e) => {
        if (onKeyPress && e.key === "Enter") {
            onKeyPress();
        }
    };

    return (
        <>
            <div className="footer_input_container" id="container_part">
                <div className="footer_input_box">
                    <input
                        ref={iref}
                        type={
                            type ? (type === "password" && viewPass ? "text" : type) : "text"
                        }
                        placeholder={placeholder}
                        value={value}
                        onChange={handleChange}
                        onFocus={() => {}}
                        onBlur={onBlur}
                        maxLength={maxlength ? maxlength : "255"}
                        onKeyPress={onKeyPressFunc}
                        onInput={(e) => {
                            if (type === "number") {
                            if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }
                        }}
                        disabled={disable}
                        style={{ backgroundColor: bgColor ? bgColor : "" }}
                    />
                    <p className="footer_input_box_btn" onClick={() => setFunc()}>
                        확인
                    </p>
                </div>
            </div>
        </>
    );
}
