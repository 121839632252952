import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import YouTube from 'react-youtube';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserResultDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);
    
    const token = state?.accessToken;
    const result_data = location?.state?.result_data;
    const member_id = location?.state?.member_id;
    const result_date = location?.state?.result_date;
    
    const [data, setData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(loadingStart());

        const sender = {
            type: "M",
            reg_type: "E", // P:경과 E:평가
            idx: result_data?.idx,
            member_id: member_id,
            register_date: moment(result_date).format('YYYY-MM-DD') // 평기일때 날짜 사용
        };

        postData(`${consts.tem_register_view}${token}`, sender).then((res) => {
            // console.log('평가 결과 =>> ', res?.data[0]);
            
            setData(res?.data[0]);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const naviFunc = () => {
        navigate(routes.userResultDetailEx,  { state: { result_data: data, result_date: result_date, exTem: true } });
    }

    const savePopupFunc = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={"해당 평가기록을 사용하시겠습니까?"}
                        buttonCancel={"아니오"}
                        button={"네"}
                        func={"setFunc"}
                        setFunc={() => {
                            saveFunc()
                        }}
                        noneLoading={true}
                    />
                ),
            })
        );
    }

    const saveFunc = () => {
        const sender = {
            type: mbData?.type,
            reg_type: "E",
            register_idx: result_data?.idx,
            member_id: member_id,
            register_date: moment(result_date).format('YYYY-MM-DD') // 평기일때 날짜 사용
        };
        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`${consts.tem_register_template_mem_save}${token}`, sender).then((res) => {
            console.log('result =>> ', res?.data);

            // dispatch(
            //     open({
            //         component: (
            //             <Popup01
            //                 width={350}
            //                 text={res?.message}
            //                 button={"확인"}
            //                 noneLoading={true}
            //             />
            //         ),
            //     })
            // );

            setTimeout(() => {
                dispatch(loadingEnd());
                navigate(routes.userResultDetailEx,  { 
                    state: { result_data: res?.data, result_date: res?.data?.register_date, member_id: member_id } 
                });
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    }


    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"평가기록 템플릿"}
            />
            <div style={{ padding: 20 }}>
                <div className="tem_detail_box">
                    <div className="tem_detail_title">
                        <p>{data?.title}
                        {data?.week > 0 &&
                            `(${data?.week}주)`
                        }
                        </p>
                    </div>
                    <div className="tem_detail_content">
                        <p className="title">대상자</p>
                        <div className="content" dangerouslySetInnerHTML={{__html: data?.target}} />
                    </div>
                    <div className="tem_detail_content">
                        <p className="title">소개</p>
                        <div className="content" dangerouslySetInnerHTML={{__html: data?.intro}} />
                    </div>
                    {/* <div>
                        <img src={require("../../assets/images/icons/preview-img.svg").default} />
                    </div> */}
                    {data?.file_name1 && data?.file_name1 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name1 } />
                        </div>
                    }
                    {data?.file_name2 && data?.file_name2 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name2 } />
                        </div>
                    }
                    {data?.file_name3 && data?.file_name3 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name3 } />
                        </div>
                    }
                    {data?.file_name4 && data?.file_name4 != consts.pro_img_path &&
                        <div className="tem_img_box">
                            <img src={consts.base_url + data?.file_name4 } />
                        </div>
                    }
                    <div style={{ marginTop: 8 }}>
                        <YouTube
                            videoId={data?.youtube?.replace(consts.you_url, "")}
                            //opts(옵션들): 플레이어의 크기나 다양한 플레이어 매개 변수를 사용할 수 있음.
                            opts={{
                                width: "100%",
                                height: "200",
                                playerVars: {
                                autoplay: 0, //자동재생 O
                                rel: 0, //관련 동영상 표시하지 않음
                                modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                                },
                            }}
                            //이벤트 리스너 
                            onEnd={(e)=>{  }}      
                        />
                    </div>
                </div>
            </div>
            <div style={{ height: 50 }}/>
            <div className="bottom_btn_box" style={{ width: isBrowser ? 390 : '100%' }}>
                <button className="temple_btn01" onClick={() => { naviFunc() }}>탬플릿보기</button>
                <button className="temple_btn02" onClick={() => { savePopupFunc() }}>사용하기</button>
            </div>
        </>
    );
}
