import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";

import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import Header from "../parts/Header";
import FooterBtn from "../../components/FooterBtn";

const ProfileCareer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const type = state.mbData.type;
    var token = state.accessToken;

    const [data, setData] = useState(location.state.data);

    if (!token) navigate(routes.login);

    const headerLeftFunc = () => {
        navigate(routes.profileEdit);
    };
    
    const footerBtn = () => {
        const sender = {
            type: type,
            career: data,
        };
        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(`/mypage.php?trace=career_edit&token=${token}`, sender).then((res) => {
            console.log(res);
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        }).catch((e) => {
            console.log(e);
        });
    };

    return (
        <div className="profile">
            <Header leftBtn={true} leftFunc={() => headerLeftFunc()} title={"경력"} />
            <div className="profile__textarea">
                <textarea
                    placeholder="경력사항을 상세히 작성해 주세요"
                    onChange={(e) => setData(e.target.value)}
                >
                    {data}
                </textarea>
            </div>
            <FooterBtn greyChk={!true} setFunc={footerBtn} btnName="저장" />
        </div>
    );
};

export default ProfileCareer;
