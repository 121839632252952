import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
import {
    numFormat,
    regEmail,
    regPassword,
    getYoilText,
    calcBirth,
} from "../../utils/utils";
import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TabBar from "../../components/TabBar";

import { postData } from "../../utils/service/APIS";


export default function StatisticsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });

    const { banner, boardCate, searchList, mbData, recommnedList, issueList } =
        useSelector((s) => s.usersReducer);

    const [dataList, setDataList] = useState([]);

    const type = state.mbData.type;
    const token = state.accessToken;

    useEffect(() => {
        dataFunc();
    }, []);

    const dataFunc = () => {
        
        let sender = {
            type: mbData?.type,
        };

        // dispatch(loadingStart());

        postData(`/schedule.php?trace=matching_list&token=${token}`, sender).then((res) => {
            if(res?.data?.length > 0) setDataList(res?.data?.filter((x, i) => x?.matching_yn === 'Y'));

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };


    return (
        <>
            {/* <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={""}
                backgorundColor={"#EDE7FF"}
            /> */}
            <div className="member_list_header">
                <p>통계</p>
            </div>
            <div>
                {dataList && dataList?.length > 0 && dataList?.map((x, i) => {
                    console.log(x);
                    return (
                        <div key={i} className="customer_profile">
                            <div className="customer_profile02" 
                                onClick={() => { 
                                    // navigate(routes.userDetail, { state: { cus_email: x?.member_id } });
                                    navigate(routes.userDetail, { 
                                        state: { 
                                            cus_email: mbData?.type === 'M' ? x?.member_id : '', 
                                            sp_data: mbData?.type === 'M' ? '' : { special_id: x?.special_id }, 
                                            view_type: 'chart'
                                        } 
                                    });
                                }}
                            >
                                {x?.file_name === "/upload/member/" ? 
                                    <img src={require("../../assets/images/icons/avatar.svg").default} />
                                    :
                                    <img src={"https://www.pronoteweb.net" + x?.file_name } />
                                }
                                <div className="customer_profile03">
                                    <p className="customer_name">{x?.name}</p>
                                    <div className="customer_profile04">
                                        {x?.birth && <p>{x?.birth}세</p>}
                                        {x?.gender && <p>{x?.gender}</p>}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

                {(!dataList || dataList?.length === 0) && 
                    <div className="not_list_box" style={{ paddingTop: '30%' }}>
                        <img
                            src={require("../../assets/images/icons/notResult.svg").default}
                        />
                        <p>데이터가 없습니다.</p>
                    </div>
                }
            </div>


            <TabBar />
        </>
    );
}
