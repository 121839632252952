import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import routes from '../libs/routes';

export default function Check01(props) {

    const { value, setFunc } = props;
    
    return (
        <div onClick={setFunc}>
            {value ? 
                <img src={require("../assets/images/icons/check_on.svg").default} />
                :
                <img src={require("../assets/images/icons/check_off.svg").default} />
            }
        </div>
    );

}
