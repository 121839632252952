import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
// import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TermsPopup from "../popup/TermsPopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import Check01 from "../../components/Check01";
import FooterBtn from "../../components/FooterBtn";
import { getData } from "../../utils/service/APIS";

export default function RegisterTermsStoreOnly(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const main_type = location?.state?.main_type;

    const [termsTitle, setTermsTitle] = useState('');
    const [popup, setPopup] = useState();

    useEffect(() => {
        if(main_type) {
            // if(main_type == 1) termsFunc('policy');
            if(main_type == 2) termsFunc('private');
            if(main_type == 3) termsFunc('policy');
        } else {
            termsFunc('private');
        }
    }, [main_type]);

    const termsFunc = (trace) => {
        if(trace === 'policy') setTermsTitle('서비스 이용약관');
        if(trace === 'private') setTermsTitle('개인정보 수집 및 이용 동의');
        if(trace === 'event_policy') setTermsTitle('이벤트 및 마케팅 활용 동의');
        
        getData(`/cs.php?trace=${trace}`).then((data) => {
            console.log(data);
            setPopup(data.content);
        });
    };

    return (
        <>
            <Header
                leftBtn={ main_type ? true : false}
                leftFunc={() => { navigate(-1) }}
                title={main_type == 1 ? '이용안내' : termsTitle ? termsTitle : ""}
            />
            <div style={{ height: 40 }}/>
            {main_type == 1 ? 
                <div className="terms_popup_box" style={{ width: 390 }}>
                    <p>
                        <div className="terms_popup_info_box">
                            <p className="terms_popup_info_box_index">1.</p>
                            <p>회사와 구매에 관한 계약을 체결한 회원은 아래와 같이</p>
                        </div>
                        <div className="terms_popup_info_box">
                            <p className="terms_popup_info_box_index"></p>
                            <p>결제에대한 취소 및환불을 요구할 수있습니다.</p>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">-</p>
                                <p>이용계약의 신청후 회사로부터의상담이 제공되지않은 경우,</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>결제취소가가능합니다.</p>
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">a.</p>
                                <p>정기결제회원: 환붙은 서비스를 이용한일수를 제외하고</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>일할 계산으로 진행됩니다.</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>월 기준은 30일이고 월 이용로를 30으로 나눈 금액을 말합니다.</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>결제시간으로부터 24시간 이후 ~ 15일까지는</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>남은 일 수에 대한일할 계산으로처리됩니다.</p>
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">b. </p>
                                <p>연간결제회원 :연기준은 12개윌이고 잔여이용료는</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>전체연간결제이용료를 12로 나눈 하루일에대한</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>금액이 환불됩니다.</p>
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">-</p>
                                <p>회원이상담 후상대 프로필카드를 2회이상 수령한경우,</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>잔여횟수가남은 회원에한하여 이용 금액과위약금 10%를 제외한 부분 환불이 가능합니다.</p>
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">2.</p>
                                <p>회사의 귀책사유로결제 오류가 발생한경우</p>
                            </div>
                            
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index">3.</p>
                                <p>회사의 귀책사유로서비스가 중단되는 경우</p>
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>본 조의환불 금액 기준은 연간결제 회원이라하더라도</p>
                            </div>
                            <div className="terms_popup_info_box">
                                <p className="terms_popup_info_box_index"></p>
                                <p>정기결제 금액으로 계산후 진행됩니다.</p>
                            </div>
                        </div>
                    </p>
                </div>
                :
                <TermsPopup content={popup} />
            }
        </>
    );
}
