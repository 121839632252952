import {combineReducers} from 'redux';
import usersReducer from './usersSlice';
import loadingReducer from './loadingSlice';
import popupReducer from './popupSlice';
import mypageReducer from './mypageSlice';

export default combineReducers({
    usersReducer,
    loadingReducer,
    popupReducer,
    mypageReducer
});
