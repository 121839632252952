import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import { logIn, logOut } from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Button01 from "../../components/Button01";

export default function TimePopup(props) {
    const { value, setValue, func, setFunc, chk } = props;

    const dispatch = useDispatch();

    const [check, setCheck] = useState((value?.sc_stime && value?.sc_etime) ? true : false);
    const [selectedType, setSelectedType] = useState(1);
    const [startTime, setStartTime] = useState(
        value.sc_stime
            ? setHours(
                setMinutes(
                    new Date(),
                    value.sc_stime.substr(3, 2) == "00" ? 0 : value.sc_stime.substr(3, 2)
                ),
                value.sc_stime.substr(0, 2) >= 10
                    ? value.sc_stime.substr(0, 2) * 1
                    : value.sc_stime.substr(1, 1) * 1
            )
            : setHours(setMinutes(new Date(), 0), 8)
    );
    const [startIsOpen, setStartIsOpen] = useState(false);
    const [endTime, setEndTime] = useState(
        value.sc_etime
            ? setHours(
                setMinutes(
                    new Date(),
                    value.sc_etime.substr(3, 2) == "00" ? 0 : value.sc_etime.substr(3, 2)
                ),
                value.sc_etime.substr(0, 2) >= 10
                    ? value.sc_etime.substr(0, 2) * 1
                    : value.sc_etime.substr(1, 1) * 1
            )
            : setHours(setMinutes(new Date(), 50), 8)
    );
    const [endIsOpen, setEndIsOpen] = useState(false);

    const sucFunc = () => {
        setValue( moment(startTime).format("HH:mm"), moment(endTime).format("HH:mm") );
    };

    const handleSelect = (st, et) => {
        setValue(st, et);
    };

    return (
        <>
            {check ? 
                <div className="select_time_box" style={{ width: isBrowser ? 350 : '' }}>
                    <div className="select_time_list02" style={{ marginTop: "0px" }}>
                        <div className="time_popup_x_btn">
                            <img src={require("../../assets/images/icons/x_btn.svg").default} onClick={() => { dispatch(close()); }} />
                        </div>
                        <div
                            className={"time_result_box_active"}
                            onClick={() => {
                                setCheck(false);
                            }}
                        >
                            <div>
                                <p>운동(치료)시간 변경</p>
                            </div>
                        </div>
                        <div
                            className={"time_result_box"}
                            onClick={() => {
                                setValue("", "");
                                dispatch(close());
                            }}
                        >
                            <div>
                                <p>요일 선택 해제</p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div
                    className="select_time_box"
                    style={{ width: isBrowser ? 350 : '', height: selectedType === 2 ? 652 : 487 }}
                >
                    <div className="time_popup_x_btn_flex">
                        <p className="select_time_box_title">운동(치료) 시간을 선택하세요</p>
                        <img src={require("../../assets/images/icons/x_btn.svg").default} onClick={() => { dispatch(close()); }} />
                    </div>
                    <div className="select_time_box_select_box">
                        <button
                            className={selectedType === 1 && "active"}
                            onClick={() => {
                                setSelectedType(1);
                            }}
                        >
                            직접입력
                        </button>
                        <button
                            className={selectedType === 2 && "active"}
                            onClick={() => {
                                setSelectedType(2);
                            }}
                        >
                            기본시간
                        </button>
                    </div>

                    {selectedType === 2 ? (
                        <div className="select_time_list">
                            {consts?.timeList?.map((x, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={
                                            value.sc_stime == x?.sc_stime && value.sc_etime == x?.sc_etime
                                                ? "time_result_box_active"
                                                : "time_result_box"
                                        }
                                        onClick={() => {
                                            handleSelect(x?.sc_stime, x?.sc_etime);
                                        }}
                                    >
                                        <div>
                                            <p>{x?.sc_stime}</p>
                                            <p>~</p>
                                            <p>{x?.sc_etime}</p>
                                        </div>
                                        {value.sc_stime == x?.sc_stime && value.sc_etime == x?.sc_etime && (
                                            <img src={require("../../assets/images/icons/check_v.svg").default} />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <>
                            <div>
                                <div className="time_content_container">
                                    <div className="time_content_box">
                                        <div
                                            className="handle_start_time_box"
                                            onClick={() => {
                                                setStartIsOpen(!startIsOpen);
                                                setEndIsOpen(false);
                                            }}
                                        >
                                            <p className="time_number_box">
                                                {moment(startTime).format("HH:mm").substr(0, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(startTime).format("HH:mm").substr(1, 1)}
                                            </p>
                                            <p className="time_number_box_center">
                                                {moment(startTime).format("HH:mm").substr(2, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(startTime).format("HH:mm").substr(3, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(startTime).format("HH:mm").substr(4, 1)}
                                            </p>
                                        </div>
                                        {startIsOpen && (
                                            <div className="time_open_list">
                                                <DatePicker
                                                    inline
                                                    locale={ko}
                                                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                                                    maxTime={setHours(setMinutes(new Date(), 50), 22)}
                                                    selected={startTime}
                                                    onChange={(date) => {
                                                        setStartIsOpen(!startIsOpen);
                                                        setStartTime(date);
                                                        if (endTime < startTime) {
                                                            setEndTime(date);
                                                        }
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={10}
                                                    timeCaption="Time"
                                                    dateFormat="H:mm"
                                                />
                                            </div>
                                        )}
                                        <p className="time_number_text">부터</p>
                                    </div>

                                    <div className="time_content_box" style={{ marginTop: 12 }}>
                                        <div
                                            className="handle_start_time_box"
                                            onClick={() => {
                                                setEndIsOpen(!startIsOpen);
                                                setStartIsOpen(false);
                                            }}
                                        >
                                            <p className="time_number_box">
                                                {moment(endTime).format("HH:mm").substr(0, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(endTime).format("HH:mm").substr(1, 1)}
                                            </p>
                                            <p className="time_number_box_center">
                                                {moment(endTime).format("HH:mm").substr(2, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(endTime).format("HH:mm").substr(3, 1)}
                                            </p>
                                            <p className="time_number_box">
                                                {moment(endTime).format("HH:mm").substr(4, 1)}
                                            </p>
                                        </div>
                                        {endIsOpen && (
                                            <div className="time_open_list">
                                                <DatePicker
                                                    inline
                                                    locale={ko}
                                                    minTime={
                                                        startTime
                                                            ? startTime
                                                            : setHours(setMinutes(new Date(), 0), 8)
                                                    }
                                                    maxTime={setHours(setMinutes(new Date(), 50), 22)}
                                                    selected={endTime}
                                                    onChange={(date) => {
                                                        setEndIsOpen(!endIsOpen);
                                                        setEndTime(date);
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={10}
                                                    timeCaption="Time"
                                                    dateFormat="H:mm"
                                                />
                                            </div>
                                        )}
                                        <p className="time_number_text">까지</p>
                                    </div>
                                </div>
                            </div>
                            <Button01 width={'100%'} label={"확인"} setFunc={sucFunc} />
                        </>
                    )}
                </div>
            }
        </>
    );
}
