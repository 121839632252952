import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function ScheduleInput() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { mbData } = useSelector((s) => s.usersReducer);
    const token = state.accessToken;
    const scData = location?.state?.scData

    const [calOpen, setCalOpen] = useState(false);
    const [selectDate, setSelectDate] = useState(new Date());
    const [scContent, setScContent] = useState(scData?.sc_content ? scData?.sc_content : '');
    const [weekDay, setWeekDay] = useState(scData?.week_day ? scData?.week_day : '');
    const [btnChk, setBtnChk] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    // 프로필 기본정보를 입력해야 이용 가능하게끔
    const loadData = () => {
        
        const sender = {
            type: mbData?.type,
        };
        
        postData(`${consts.my_member_basic}${token}`, sender).then((res) => {
            // console.log('마이페이지 ==> ', res);
            if (res?.result === "success") {
                if(
                    !res?.member_gender || 
                    !res?.member_birth || 
                    !res?.member_height || 
                    !res?.member_weight || 
                    !res?.member_address || 
                    !res?.member_address_etc
                ){
                    if(mbData?.type === 'C') navigate(routes.profileEditCus);
                }
            }
        }).catch((e) => {
            console.log(e);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const rightFunc = () => {
        dispatch(
            open({
                component: (
                    <Popup01
                        width={350}
                        text={'삭제하시겠습니까?'}
                        buttonCancel={'취소'}
                        button={"확인"}
                        func={'setFunc'}
                        setFunc={deleteFunc}
                        noneLoading={true}
                    />
                ),
            })
        );
    }

    const deleteFunc = () => {
        if(!scData?.idx) return;
        let sender = {
            idx: scData?.idx
        }

        postData(consts.sc_my_schedule_del+token, sender).then((res) => {
            console.log('login_result => ', res);
            // return;

            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={res?.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );

            setTimeout(() => {
                dispatch(loadingEnd());
                navigate(-1);
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        });
        
    }

    const saveFunc = () => {

        if(!scContent) {
            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={'스케줄 내용을 입력해주세요.'}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );
            return;
        }

        let sender = {
            sc_date: moment(selectDate).format("YYYY-MM-DD"),
            sc_time: moment(selectDate).format('HH:mm'),
            sc_content: scContent,
            week_day: weekDay,
        }
        
        let apiUrl = consts.sc_my_schedule_save
        if(scData?.idx) {
            apiUrl = consts.sc_my_schedule_edit;
            sender.idx = scData?.idx
        }
        // console.log(apiUrl);
        console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(apiUrl+token, sender).then((res) => {
            console.log('login_result => ', res);
            // return;

            dispatch(
                open({
                    component: (
                        <Popup01
                            width={350}
                            text={res?.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                })
            );

            setTimeout(() => {
                dispatch(loadingEnd());
                navigate(-1);
            }, consts.lasy);
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        });
        
    }

    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"스케줄"}
                rightText={scData?.idx && '삭제'}
                rightFunc={() => rightFunc()}
            />
            <div className="date_select_box">
                <div className="sc_input_box_top">
                    <div 
                        onClick={() => {
                            setCalOpen(!calOpen);
                        }}
                    >
                        <img src={require("../../assets/images/icons/calendar_icon04.svg").default} />
                    </div>
                    <div className="sc_date">
                        <p>
                            {moment(scData?.sc_date ? scData?.sc_date : selectDate).format("YYYY년 MM월 DD일")}
                            ({consts.dayText[moment(scData?.sc_date ? scData?.sc_date : selectDate).day()]})
                        </p>
                    </div>
                    <div className="sc_date">
                        <p>{moment(scData?.sc_time ? scData?.sc_date + ' ' + scData?.sc_time : selectDate).format('a hh:mm')}</p>
                    </div>
                    <div className="cal_relative_box">
                        {calOpen && (
                            <div className="cal_absolute_box02">
                                <DatePicker
                                    inline
                                    locale={ko}
                                    selected={selectDate}
                                    minDate={new Date()}
                                    // timeInputLabel="Time:"
                                    showTimeInput
                                    onChange={(date) => {
                                        setSelectDate(date);
                                        setCalOpen(!calOpen);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ marginBottom: 20 }}>
                    <p style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}>스케줄</p>
                    <Input
                        width={'100%'}
                        placeholder={"내용 입력"}
                        value={scContent}
                        setValue={setScContent}
                        maxLength={255}
                    />
                </div>

                <div>
                    <p style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}>반복등록</p>
                    <div className="sc_content_daybox">
                        {consts.dayText02.map((x, i) => {
                            return (
                                <p 
                                    className={(weekDay === i.toString()) && "active_week"}
                                    key={i} 
                                    onClick={() => { 
                                        setWeekDay(i.toString()) 
                                    }}>{x}</p>
                            )
                        })}
                    </div>
                </div>
                    
            </div>
            <FooterBtn
                btnName={'저장'}
                setFunc={saveFunc}
            />
        </>
    );
}
