import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

// import * as APIS from "../../utils/service";
import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { postData } from "../../utils/service/APIS";

import routes from "../../libs/routes";
import consts from "../../libs/consts";

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Header from "../parts/Header";
import Popup01 from "../popup/Popup01";
import TimePopup from "../popup/TimePopup";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import Button02 from "../../components/Button02";
import TextAreaEdit from "../../components/TextAreaEdit";
import FooterBtn from "../../components/FooterBtn";

export default function UserSchedule() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let state = useSelector((state) => {
        return state.usersReducer;
    });
    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const [firstChk, setFirstChk] = useState(false);
    //0: 일, 1: 월, 2: 화. 3: 수, 4: 목, 5: 금, 6: 토
    const [dateList, setDateList] = useState([
        {
            week_day: "월",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "화",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "수",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "목",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "금",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "토",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        },
        {
            week_day: "일",
            sc_stime: "",
            sc_etime: "",
            sc_date: "",
        }
    ]);
    const [selectDate, setSelectDate] = useState(new Date());
    const [calOpen, setCalOpen] = useState(false);

    const type = state.mbData.type;
    const cus_email = location.state.cus_email;
    const token = state.accessToken;

    useEffect(() => {
        changeDate(new Date());
        loadData();
    }, []);

    const loadData = () => {
        dispatch(loadingStart());

        const sender = {
            member_id: cus_email,
        };

        postData(`/schedule.php?trace=schedule_view&token=${token}`, sender).then((res) => {
            console.log('??=> ', res.data);

            if(!res?.data) {
                setFirstChk(true);
            } else {
                setFirstChk(false);
                setDateList(res.data);
            }

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);

        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });
    };

    const headerLeftFunc = () => {
        navigate(-1);
    };

    const changeDate = (dateVal) => {
        let startYoil = moment(dateVal).day();
        let arr = dateList;

        // 앞에 날짜 자동 계산
        if (startYoil === 0) {
            arr[0] = { ...arr[0], date: moment(dateVal).format("YYYY-MM-DD") };
            arr[1] = { ...arr[1], date: moment(dateVal).add(1, "d").format("YYYY-MM-DD") };
            arr[2] = { ...arr[2], date: moment(dateVal).add(2, "d").format("YYYY-MM-DD") };
            arr[3] = { ...arr[3], date: moment(dateVal).add(3, "d").format("YYYY-MM-DD") };
            arr[4] = { ...arr[4], date: moment(dateVal).add(4, "d").format("YYYY-MM-DD") };
            arr[5] = { ...arr[5], date: moment(dateVal).add(5, "d").format("YYYY-MM-DD") };
            arr[6] = { ...arr[6], date: moment(dateVal).add(6, "d").format("YYYY-MM-DD") };
        } else if (startYoil === 1) {
            arr[0] = { ...arr[0], date: moment(dateVal).add(6, "d").format("YYYY-MM-DD") };
            arr[1] = { ...arr[1], date: moment(dateVal).format("YYYY-MM-DD") };
            arr[2] = { ...arr[2], date: moment(dateVal).add(1, "d").format("YYYY-MM-DD") };
            arr[3] = { ...arr[3], date: moment(dateVal).add(2, "d").format("YYYY-MM-DD") };
            arr[4] = { ...arr[4], date: moment(dateVal).add(3, "d").format("YYYY-MM-DD") };
            arr[5] = { ...arr[5], date: moment(dateVal).add(4, "d").format("YYYY-MM-DD") };
            arr[6] = { ...arr[6], date: moment(dateVal).add(5, "d").format("YYYY-MM-DD") };
        } else if (startYoil === 2) {
            arr[0] = { ...arr[0], date: moment(dateVal).add(5, "d").format("YYYY-MM-DD") };
            arr[1] = { ...arr[1], date: moment(dateVal).add(6, "d").format("YYYY-MM-DD") };
            arr[2] = { ...arr[2], date: moment(dateVal).format("YYYY-MM-DD")};
            arr[3] = { ...arr[3], date: moment(dateVal).add(1, "d").format("YYYY-MM-DD")};
            arr[4] = { ...arr[4], date: moment(dateVal).add(2, "d").format("YYYY-MM-DD")};
            arr[5] = { ...arr[5], date: moment(dateVal).add(3, "d").format("YYYY-MM-DD")};
            arr[6] = { ...arr[6], date: moment(dateVal).add(4, "d").format("YYYY-MM-DD")};
        } else if (startYoil === 3) {
            arr[0] = {
                ...arr[0],
                date: moment(dateVal).add(4, "d").format("YYYY-MM-DD"),
            };
            arr[1] = {
                ...arr[1],
                date: moment(dateVal).add(5, "d").format("YYYY-MM-DD"),
            };
            arr[2] = {
                ...arr[2],
                date: moment(dateVal).add(6, "d").format("YYYY-MM-DD"),
            };
            arr[3] = { ...arr[3], date: moment(dateVal).format("YYYY-MM-DD") };
            arr[4] = {
                ...arr[4],
                date: moment(dateVal).add(1, "d").format("YYYY-MM-DD"),
            };
            arr[5] = {
                ...arr[5],
                date: moment(dateVal).add(2, "d").format("YYYY-MM-DD"),
            };
            arr[6] = {
                ...arr[6],
                date: moment(dateVal).add(3, "d").format("YYYY-MM-DD"),
            };
        } else if (startYoil === 4) {
            arr[0] = {
                ...arr[0],
                date: moment(dateVal).add(3, "d").format("YYYY-MM-DD"),
            };
            arr[1] = {
                ...arr[1],
                date: moment(dateVal).add(4, "d").format("YYYY-MM-DD"),
            };
            arr[2] = {
                ...arr[2],
                date: moment(dateVal).add(5, "d").format("YYYY-MM-DD"),
            };
            arr[3] = {
                ...arr[3],
                date: moment(dateVal).add(6, "d").format("YYYY-MM-DD"),
            };
            arr[4] = { ...arr[4], date: moment(dateVal).format("YYYY-MM-DD") };
            arr[5] = {
                ...arr[5],
                date: moment(dateVal).add(1, "d").format("YYYY-MM-DD"),
            };
            arr[6] = {
                ...arr[6],
                date: moment(dateVal).add(2, "d").format("YYYY-MM-DD"),
            };
        } else if (startYoil === 5) {
            arr[0] = {
                ...arr[0],
                date: moment(dateVal).add(2, "d").format("YYYY-MM-DD"),
            };
            arr[1] = {
                ...arr[1],
                date: moment(dateVal).add(3, "d").format("YYYY-MM-DD"),
            };
            arr[2] = {
                ...arr[2],
                date: moment(dateVal).add(4, "d").format("YYYY-MM-DD"),
            };
            arr[3] = {
                ...arr[3],
                date: moment(dateVal).add(5, "d").format("YYYY-MM-DD"),
            };
            arr[4] = {
                ...arr[4],
                date: moment(dateVal).add(6, "d").format("YYYY-MM-DD"),
            };
            arr[5] = { ...arr[5], date: moment(dateVal).format("YYYY-MM-DD") };
            arr[6] = {
                ...arr[6],
                date: moment(dateVal).add(1, "d").format("YYYY-MM-DD"),
            };
        } else if (startYoil === 6) {
            arr[0] = {
                ...arr[0],
                date: moment(dateVal).add(1, "d").format("YYYY-MM-DD"),
            };
            arr[1] = {
                ...arr[1],
                date: moment(dateVal).add(2, "d").format("YYYY-MM-DD"),
            };
            arr[2] = {
                ...arr[2],
                date: moment(dateVal).add(3, "d").format("YYYY-MM-DD"),
            };
            arr[3] = {
                ...arr[3],
                date: moment(dateVal).add(4, "d").format("YYYY-MM-DD"),
            };
            arr[4] = {
                ...arr[4],
                date: moment(dateVal).add(5, "d").format("YYYY-MM-DD"),
            };
            arr[5] = {
                ...arr[5],
                date: moment(dateVal).add(6, "d").format("YYYY-MM-DD"),
            };
            arr[6] = { ...arr[6], date: moment(dateVal).format("YYYY-MM-DD") };
        }

        setDateList(arr);
    };

    const selectedFunc = (i, sc_stime, sc_etime, day, idx) => {

        dispatch(open({
            component: (
                <TimePopup
                    chk={sc_stime && sc_etime}
                    value={{ sc_stime, sc_etime }}
                    setValue={(st, et) => {
                        setTimeFunc(i, st, et, day, idx);
                    }}
                />
            ),
        }));
        
    };

    const setTimeFunc = (index, st, et, selDay, idx) => {
        setDateList(
            dateList.map((v, i) => {
                if (i === index) {
                    return { ...v, sc_stime: st, sc_etime: et };
                } else {
                    return v;
                }
            })
        );

        let sender = {}

        let url = ``;
        
        if(selDay === '월') selDay = 0;
        if(selDay === '화') selDay = 1;
        if(selDay === '수') selDay = 2;
        if(selDay === '목') selDay = 3;
        if(selDay === '금') selDay = 4;
        if(selDay === '토') selDay = 5;
        if(selDay === '일') selDay = 6;

        if(!firstChk && idx) { // 수정
            sender = {
                type: type,
                member_id: cus_email,
                sc_date: moment(selectDate).format("YYYY-MM-DD"),
                sc_time: [
                    {
                        idx: idx,
                        sc_stime: st,
                        sc_etime: et,
                        week_day: selDay
                    },
                ]
            };
            url = `/schedule.php?trace=schedule_edit&token=`; // 스케줄 수정
        } else {
            sender = {
                type: type,
                member_id: cus_email,
                sc_date: moment(selectDate).format("YYYY-MM-DD"),
                sc_time: [
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 0
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 1
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 2
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 3
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 4
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 5
                    },
                    {
                        sc_stime: '',
                        sc_etime: '',
                        week_day: 6
                    }
                ]
            };

            sender.sc_time = sender?.sc_time?.map((v, i) => {
                if (v.week_day === selDay) {
                    return { ...v, sc_stime: st, sc_etime: et };
                } else {
                    return v;
                }
            });

            url = `/schedule.php?trace=schedule_save&token=`; // 스케줄 등록
        }

        // console.log(url);
        // console.log(sender);
        // return;

        dispatch(loadingStart());

        postData(url+token, sender).then((res) => {

            console.log('시간 저장 ======> ', res);

            if(res.result === "success") {

                setFirstChk(false);

                loadData();

                setTimeout(() => {
                    dispatch(loadingEnd());
                }, consts.lasy);

                dispatch(open({
                    component: (
                        <Popup01
                            width={350}
                            text={res.message}
                            button={"확인"}
                            noneLoading={true}
                        />
                    ),
                }));
            }
            
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.finalLasy);
        });

        dispatch(close());
    };

    return (
        <>
            <Header
                leftBtn={true}
                leftFunc={() => {
                    headerLeftFunc();
                }}
                title={"스케줄"}
            />
            <div>
                <div className="date_select_box">
                    <div>
                        <p className="date_select_box_title" style={{ paddingBottom: 12 }}>
                            고객의 운동(치료) 시작일을 선택해 주세요.
                        </p>
                        <div className="date_edit_box">
                            <button
                                className="date_edit_button"
                                htmlFor="dayChoice1"
                                onClick={() => {
                                    setCalOpen(!calOpen);
                                }}
                            >
                                <img src={require("../../assets/images/icons/calendar_icon.svg").default} />
                                <p>일자 선택</p>
                            </button>
                            <div className="cal_relative_box">
                                <p className="date_edit_box_date">
                                    {moment(selectDate).format("YYYY.MM.DD")}
                                </p>
                                {calOpen && (
                                    <div className="cal_absolute_box">
                                        <DatePicker
                                            inline
                                            locale={ko}
                                            selected={selectDate}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                setSelectDate(date);
                                                changeDate(date);
                                                setCalOpen(!calOpen);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <p className="date_select_box_title" style={{ paddingBottom: 12 }}>
                            고객의 운동(치료) 스케줄을 선택 및 입력하세요.
                        </p>
                        {dateList && dateList.length > 0 && dateList.map((x, i) => {
                            // console.log('idx => ', x?.idx);
                            return (
                                <div
                                    key={i}
                                    className="date_yoil_box"
                                    style={{
                                        borderColor:
                                            x?.sc_stime && x?.sc_etime ? "#6D39FF" : "#ccc",
                                    }}
                                    onClick={() => {
                                        selectedFunc(i, x?.sc_stime, x?.sc_etime, x?.week_day, x?.idx);
                                    }}
                                >
                                    <p
                                        className="date_yoil_box_yoil"
                                        style={{
                                            color: x?.sc_stime && x?.sc_etime ? "#6D39FF" : "#ccc",
                                        }}
                                    >
                                        {x?.week_day}
                                    </p>
                                    {x?.sc_stime && x?.sc_etime && (
                                        <div className="date_yoil_box_time">
                                            <p>
                                                {x?.sc_stime} ~ {x?.sc_etime}
                                            </p>
                                            <img src={require("../../assets/images/icons/check_v.svg").default} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
