import { useEffect, useState } from "react";

const options = [
    {
        name: "학력 선택",
    },
    {
        name: "고등학교 졸업",
        value: "1",
    },
    {
        name: "대학교졸업(2,3년)",
        value: "2",
    },
    {
        name: "대학교졸업(4년)",
        value: "3",
    },
    {
        name: "대학원 석사졸업",
        value: "4",
    },
    {
        name: "대학원 박사졸업",
        value: "5",
    },
];

const exampleData = [
    {
        education_type: "1",
        education_nm: "서울고등학교",
        education_major: "인문계",
        education_submajor: "",
    },
    {
        education_type: "3",
        education_nm: "서울대학교",
        education_major: "컴퓨터공학",
        education_submajor: "영문학과",
    },
];

const SelectBox = ({
    data,
    data1,
    setData1,
    educationType,
    setEducationType,
    index,
}) => {
    const [prevValue, setPrevValue] = useState();
    const [currentValue, setCurrentValue] = useState(data.education_type);
    const [currentLabel, setCurrentLabel] = useState(
        data.education_type ? options[data.education_type].name : options[0].name
    );
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        if (prevValue === currentValue) return;
        changeEducationType();
    }, [prevValue, currentValue]);

    const changeEducationType = () => {
        let type = [...educationType];
        type = type.filter((v) => v !== prevValue);
        type.push(currentValue);
        setEducationType(type);

        // let data2 = data1;
        // data2["education_type"] = currentValue;

        // console.log(data2);

        setData(currentValue, index);
    };

    const handleOnChangeSelectValue = (v) => {
        if (educationType.some((x) => x === v.value)) return;
        setPrevValue(currentValue);
        setCurrentValue(v.value);
        setCurrentLabel(v.name);
        setShowOptions(false);
    };

    const setData = (v, i) => {
        let data2 = data1;

        data2[i]["education_type"] = v;

        setData1(data2);
    };

    return (
        <div className={"selectBox " + (!currentValue && showOptions && "click")}>
            <div
                className="selectLabel"
                onClick={() => setShowOptions((show) => !show)}
            >
                <label>{currentLabel}</label>
            </div>
            <ul style={showOptions ? {} : { display: "none" }}>
                {options.map((v, i) =>
                    i === 0 ? (
                        <li className="disable" key={i} style={{ display: "none" }}>
                            {v.name}
                        </li>
                    ) : (
                        <li
                            className={educationType.some((x) => x === v.value) && "disable"}
                            key={i}
                            onClick={() => handleOnChangeSelectValue(v)}
                        >
                            {v.name}
                        </li>
                    )
                )}
            </ul>
        </div>
    );
};

export default SelectBox;
