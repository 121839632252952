import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import KakaoLogin from "react-kakao-login";
import { jwtDecode } from "jwt-decode";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// import * as APIS from "../../utils/service";
// import { numFormat, regEmail, regPassword } from "../../utils/utils";
import { regEmail, regPassword } from "../../utils/utils";

import routes from "../../libs/routes";
import consts from '../../libs/consts';

import {
    logIn,
    logOut,
    gpsUpdate,
    configData,
    setData,
    aroundData,
    menuClose,
    mainReset,
    searchClose,
    pickerClose,
} from "../../redux/usersSlice";
import { open, close } from "../../redux/popupSlice";
// import { openSub, closeSub } from '../../redux/subPopupSlice';
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Popup01 from "../popup/Popup01";

/* component */
import Input from "../../components/Input";
import Button01 from "../../components/Button01";
import { postData, kakaoPostData } from "../../utils/service/APIS";
// import { postData } from "../../api/APIS";

// import InputSelectChk from "../../components/InputSelectChk";
// import BoardCardFeed from "../../components/BoardCardFeed";
// import BoardCardShadow from "../../components/BoardCardShadow";
// import ListLoading from "../../components/ListLoading";

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const naverRef = useRef();
    const location = useLocation();

    const { banner, boardCate, searchList, mbData, recommnedList, issueList } = useSelector((s) => s.usersReducer);

    const [load, setLoad] = useState(false);
    const [moreLoad, setMoreLoad] = useState(false);

    const [mb_id, setmb_id] = useState("");
    const [mb_password, setmb_password] = useState("");
    // const [mb_id, setmb_id] = useState("apoka700@gmail.com");
    // const [mb_password, setmb_password] = useState("test1234!");
    const [autoLogin, setautoLogin] = useState(false);
    const [error1, seterror1] = useState("");
    const [error2, seterror2] = useState("");

    const [viewDevice, setViewDevice] = useState('');
    // console.log(mbData);

    dispatch(loadingEnd());

    const loginFunc = () => {

        seterror1("");
        seterror2("");

        if (!mb_id) {
            seterror1("이메일을 입력해 주세요.");
            return;
        } else {
            if(!regEmail.test(mb_id)) {
                seterror1("이메일 형식이 맞지 않습니다.");
                return;
            }
        }

        if (!mb_password) {
            seterror2("비밀번호를 입력해 주세요.");
            return;
        } else {
            if (!regPassword(mb_password)) {
                seterror2("비밀번호 형식이 맞지 않습니다.");
                return;
            }
        }

        // console.log("login");

        dispatch(loadingStart());

        // 로그인처리 API
        let sender = {
            userid: mb_id,
            userpass: mb_password,
            // type: type, //C:일반회원	M:전문가회원
            // reg_channel: "S",
        };
        // console.log(sender);

        postData(consts.authorization_login, sender).then((data) => {
            // console.log('login_result => ', data?.reg_channel);
            // return;

            if (data?.result === "success" && data?.reg_channel === 'S') { // 성공시
                dispatch(
                    logIn({
                        mbData: {
                            idx: data?.idx,
                            type: data?.type,
                            username: data?.username,
                            userid: data?.userid,
                        },
                        accessToken: data?.access_token,
                        autoLogin: autoLogin ? "" : moment(),
                    })
                );

                setTimeout(() => {
                    dispatch(loadingEnd());
                    navigate(routes.calendar);
                }, consts.lasy);
            } else {
                setmb_id('');
                setmb_password('');
                setTimeout(() => {
                    dispatch(loadingEnd());
                    dispatch(
                        open({
                            component: (
                                <Popup01
                                    width={350}
                                    text={data.message ? data.message : data?.reg_channel != 'S' ? 'SNS로 로그인해주세요.' : ''}
                                    button={"확인"}
                                    noneLoading={true}
                                />
                            ),
                        })
                    );
                }, consts.lasy);
            }
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            setLoad(false);
            setMoreLoad(false);

            setTimeout(() => {
                dispatch(loadingEnd());
            }, consts.lasy);
        });
        setTimeout(() => {
            dispatch(loadingEnd());
        }, consts.lasy);
    };

    const naviFunc = (type) => {
        // 1=아이디 찾기, 2=비밀번호 찾기
        if (type === 1) {
            navigate(routes.SearchInfo);
        } else {
            navigate(routes.searchPass);
        }
    };


    // 카카오 로그인
    const Rest_api_key = consts.kakaoRestApiKey; //REST API KEY
    const redirect_uri = `${window.location.origin}` //Redirect URI
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`
    const handleKaKaoLogin = () => { window.location.href = kakaoURL }
    const kakaoCode = new URL(window.location.href).searchParams.get("code"); // 카카오 로그인때 필요한 코드

    // 애플 토큰
    const appleIdToken = new URL(window.location.href).searchParams.get("id_token"); // 애플 로그인때 필요한 코드
    
    useEffect(() => {
        if(kakaoCode && !appleIdToken) {
            getkakaoToken();
        }
    }, [kakaoCode]);
    
    useEffect(() => {
        if(appleIdToken) {
            // console.log(appleIdToken);
            // console.log('============> ', jwtDecode(appleIdToken));
            
            appleTokenLoginFunc();

            // let appleEmail = jwtDecode(appleIdToken)?.email;
        }
    }, [appleIdToken]);
    // 000969.1e70c4fce3c54ba7887ff2aa4ffd6d74.0604
    const appleTokenLoginFunc = (appsub, applename) => {
        let appleSub = appsub ? appsub : jwtDecode(appleIdToken)?.sub;
            
        if(appleSub){
            let sender = {
                apple_sub: appleSub
            }

            // console.log('??????/ => ', sender);

            postData(consts.authorization_apple_sub, sender).then((res) => {
                // console.log('======> ', res?.data);
                // return;
                
                if(res?.result === 'success') {
                    if(res?.data === 'new') {
                        goSnsLogin('A', appleSub, applename);
                    } else {
                        dispatch(
                            logIn({
                                mbData: {
                                    idx: res?.idx,
                                    type: res?.type,
                                    username: res?.username,
                                    userid: res?.userid,
                                },
                                accessToken: res?.access_token,
                                autoLogin: autoLogin ? "" : moment(),
                            })
                        );

                        setTimeout(() => {
                            dispatch(loadingEnd());
                            navigate(routes.calendar);
                        }, consts.lasy);
                    }
                } else {

                }
            }).catch((e) => {
                
            });
        }
    }


    const getkakaoToken = async () => {
        kakaoPostData(kakaoCode).then((res) => {
            // console.log(res?.id);
            // return;
            if(res?.kakao_account?.email) goSnsLogin('K', res?.kakao_account?.email);
        }).catch((e) => {
            console.log('err ==> ', e);
        });
    }

    // 네이버 로그인
    const { naver } = window;
    useEffect(() => {
        initializeNaverLogin();
    }, []);

    const initializeNaverLogin = () => {
        // naverLogout();
        // return;
        
        const naverLogin = new naver.LoginWithNaverId({
            clientId: consts.naverApiKey, // 발급 받은 Client ID 입력
            callbackUrl: 'https://www.pronoteweb.net/', // 작성했던 Callback URL 입력 // 'http://localhost:3000',
            // callbackUrl: 'http://localhost:3000', // test
            isPopup: false, // 팝업창으로 로그인을 진행할 것인지?           
            loginButton: { color: 'green', type: 1, height: 58 }, // 버튼 타입 ( 색상, 타입, 크기 변경 가능 )
            callbackHandle: true,
        });
        naverLogin.init();
        naverLogin.getLoginStatus(async function (status) {
            // console.log(status, naverLogin?.user);
            if(status) {
                handleNaverLogin(naverLogin?.user?.email);
            }
        });
    }


    const handleNaverLogin = (email) => {
        localStorage.removeItem('com.naver.nid.access_token');
        localStorage.removeItem('com.naver.nid.oauth.state_token');
        
        if(email){ // 여기서 이메일 조회후 로그인 시키는 함수 실행
            goSnsLogin('N', email); 
        } else {
            naverRef.current.children[0].click();    
        }
	}
    
    // 구글 로그인
    const googleLoginFunc = () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ 
            login: 'google', 
        }));
        window.addEventListener("message", msgFunc, { capture: true });
        return () => window.removeEventListener("message", msgFunc);
    }

    const msgFunc = (e) => {
        if(e?.data && typeof(e?.data) != 'object') {
            // console.log(JSON.parse(e?.data));
            let result = JSON.parse(e?.data);
            if(result?.email) goSnsLogin('G', result?.email);
        }
    }

    let CLIENT_ID = 'www.pronoteweb.net';
    let REDIRECT_URL = 'https://www.pronoteweb.net';
    
    // 애플 로그인
    const loginWithApple = async () => {
        if(isBrowser) {
            // let url = `http://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&response_type=code+id_token`;
            // window.location.href = url;
            // return;

            let options = {
                clientId: CLIENT_ID,
                scope: 'email name',
                redirectURI: REDIRECT_URL,
                usePopup: true,
                // state: 'state',
                // nonce: '',
            }
    
            // appleAuthHelpers.signIn({
            //     authOptions: options,
            //     onSuccess: (res) => {
            //         console.log(res);
            //         console.log(jwtDecode(res?.authorization?.id_token));
            //     },
            //     onError: (error) => console.error(error),
            // });

            window.AppleID.auth.init(options);

            try {
                let result = await window.AppleID.auth.signIn();
                // console.log('?????? => ', jwtDecode(result?.authorization?.id_token));

                let appleEmail = jwtDecode(result?.authorization?.id_token)?.email;
                let applename = result?.user?.name?.lastName + result?.user?.name?.firstName
                if(appleEmail) {
                    // console.log(appleEmail);
                    goSnsLogin('A', appleEmail, applename);
                }

            } catch (error) {
                console.log(error);
            }
            return;
        } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ 
                login: 'apple', 
            }));
            window.addEventListener("message", appleMsgFunc, { capture: true });
            return () => window.removeEventListener("message", appleMsgFunc);
        }
    };


    const appleMsgFunc = (e) => {
        if(e?.data && typeof(e?.data) != 'object') {
            // console.log(JSON.parse(e?.data));
            let result = JSON.parse(e?.data);
            
            if(result?.apple_sub) appleTokenLoginFunc(result?.apple_sub, result?.apple_name);
        }
    }

    
    const goSnsLogin = (type, email, applename) => { // 여기에서 중복체크 후 있으면 로그인, 없으면 회원가입
        // console.log(type, email);
        let sender = {
            userid: email,
        };
        
        postData(consts.authorization_chk_id_sns, sender).then((res) => {
            console.log(res);
            // return;            
            if(res?.result === "success") {
                if(email) navigate(routes.register, { state: { reg_type: type, sns_user_email: email, applename: applename } });
            } else if(type != res?.data?.reg_channel) {
                // 이미 계정 정보가 있을때
                if(email) navigate(routes.registerChkId, { state: { email: email, reg_type: res?.data?.reg_channel } });
            } else {
                sender.type = type;
                postData(consts.authorization_login_sns, sender).then((data) => {
                    // console.log('login_result => ', data);
                    // return;

                    if (data?.result === "success") { // 성공시
                        dispatch(
                            logIn({
                                mbData: {
                                    idx: data?.idx,
                                    type: data?.type,
                                    username: data?.username,
                                    userid: data?.userid,
                                },
                                accessToken: data?.access_token,
                                autoLogin: autoLogin ? "" : moment(),
                            })
                        );

                        setTimeout(() => {
                            dispatch(loadingEnd());
                            navigate(routes.calendar);
                        }, consts.lasy);
                    } else {
                        setmb_id('');
                        setmb_password('');
                        setTimeout(() => {
                            dispatch(loadingEnd());
                            dispatch(
                                open({
                                    component: (
                                        <Popup01
                                            width={350}
                                            text={data.message}
                                            button={"확인"}
                                            noneLoading={true}
                                        />
                                    ),
                                })
                            );
                        }, consts.lasy);
                    }
                }).catch((e) => {
                    console.log(e);
                }).finally(() => {
                    setLoad(false);
                    setMoreLoad(false);

                    setTimeout(() => {
                        dispatch(loadingEnd());
                    }, consts.lasy);
                });
            }
        });
    }


    return (
        <>
            <p className="login_title">로그인</p>
            <div className="login_content">
                <div style={{ marginBottom: 10 }}>
                    <Input
                        placeholder={"아이디(E-mail)"}
                        value={mb_id}
                        setValue={setmb_id}
                        maxLength={255}
                        error={error1}
                        onKeyPress={loginFunc}
                        onFocus={() => { seterror1('') }}
                    />
                </div>

                <div style={{ marginBottom: 25 }}>
                    <Input
                        type={"password"}
                        placeholder={"비밀번호"}
                        value={mb_password}
                        setValue={setmb_password}
                        maxLength={20}
                        error={error2}
                        onKeyPress={loginFunc}
                        onFocus={() => { seterror2('') }}
                    />
                </div>

                {/* <div className="chk_div01">
                    <input type="checkbox" id="chk03" className="checkbox01"
                        onChange={(e) => { setautoLogin(e.target.checked) }}
                    />
                    <label htmlFor="chk03">로그인 상태 유지</label>
                </div> */}

                <Button01 label={"로그인"} setFunc={() => loginFunc()} />

                <div className="login_bottom">
                    <p onClick={() => naviFunc(1)}>아이디 찾기</p>
                    <div className="center_bar" />
                    <p onClick={() => naviFunc(2)}>비밀번호 찾기</p>
                </div>

                <div className="login_register">
                    <p style={{ marginBottom: 10 }}>소셜 간편 로그인/가입</p>
                    <div className="register_type_box" style={{ width: (208)  ,marginBottom: 40 }}>
                        <img
                            src={require("../../assets/images/icons/kakao.svg").default}
                            onClick={() => {
                                handleKaKaoLogin();
                            }}
                        />
                        <div id="naverIdLogin" ref={naverRef} style={{ display: 'none' }} />
                        <img
                            src={require("../../assets/images/icons/naver.svg").default}
                            onClick={() => {
                                handleNaverLogin();
                            }}
                        />
                        
                        {isBrowser ?
                            <GoogleOAuthProvider 
                                clientId={consts.googleApiKey}
                            >
                                <GoogleLogin
                                    type={'icon'}
                                    shape={'circle'}
                                    onSuccess={(res) => {
                                        // console.log(res);
                                        if(res?.credential) {
                                            let googleEmail = jwtDecode(res?.credential).email;
                                            goSnsLogin('G', googleEmail);
                                        }
                                    }}
                                    onFailure={(err) => {
                                        console.log(err);
                                    }}
                                />
                            </GoogleOAuthProvider>
                            :
                            <img
                                src={require("../../assets/images/icons/google.svg").default}
                                onClick={() => {
                                    googleLoginFunc();
                                }}
                            />
                        }
                       
                        <img
                            src={require("../../assets/images/icons/apple.svg").default}
                            onClick={() => {
                                loginWithApple();
                            }}
                        />
                        
                    </div>
                    
                    <p style={{ marginBottom: 4 }}>
                        아직 회원이 아니세요?
                    </p>

                    <Button01
                        label={"이메일 회원가입"}
                        borderType={true}
                        setFunc={() => {
                            navigate(routes.register, { state: { reg_type: 'S' } });
                        }}
                    />
                </div>
            </div>
        </>
    );
}
